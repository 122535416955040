<template>
  <div class="b-search-product">
    <div
      class="b-search__wrapper"
      @click="test"
    >
      <b-form-input
        id="productSearchInput"
        ref="searchInput"
        v-model="searchString"
        type="text"
        placeholder="Введите марку или штрихкод"
        :is-keyup="true"
        autocomplete="off"
        @keyup="search_product"
        @input="search_input"
      />
      <template v-if="search_result">
        <!--        <apollo-query-->
        <!--          :query=""-->
        <!--          :variables="{}"-->
        <!--          :skip="searchString.length < 3"-->
        <!--          :update="searchProductResult"-->
        <!--        >-->
        <template v-click-outside="clickOutside">
          <div
            v-if="isLoading"
            class="b-search-result p-2 center"
          >
            <b-spinner
              variant="primary"
              class="mt-4"
            />
          </div>
          <div
            v-else-if="error"
            class="b-search-result error apollo"
          >
            Ошибка при запросе
          </div>
          <div
            v-else-if="data"
            class="b-search-result text-left"
          >
            <template v-if="data.SpecSearchEdo?.specification?.length > 0">
              <div
                v-for="(item, index) in data.SpecSearchEdo?.specification"
                :key="index"
                v-click-outside="clickOutside"
                class="no-control item cursor"
                @click="select_product(item)"
              >
                <div class="no-control item-name">
                  {{ item.product.name }}
                </div>
                <div class="no-control item-info">
                  <span class="mr-2 no-control">
                    {{ item.product.articul ? item.product.articul : '-----' }}
                  </span>
                  <span class="no-control mr-2">
                    {{ item.product.supplier?.name ? item.product.supplier?.name : 'Нет данных' }}
                  </span>
                  <span
                    class="no-control"
                    style="float: right; margin-right: 0"
                  >
                    {{ item.product.retailPrice ? item.product.retailPrice : `0.00` }}
                    ₽
                  </span>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="center">Не найдено</div>
            </template>
          </div>
          <div
            v-else
            class="b-search-result p-3 center"
          >
            <b-spinner variant="primary" />
          </div>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { EventBus } from '@/main'
  import { ProductModel } from '@/models/product.model'

  export default {
    name: 'EdoSpecSearch',
    props: {
      document_head: String,
      document_type: {
        type: String,
        default: () => 'incoming'
      }
    },
    data() {
      return {
        searchString: '',
        search_result: false,
        isModalOpened: false,
        isLoading: true,
        data: {},
        error: false,
        timer: null,
        lastTime: new Date(),
        searchStr: ''
      }
    },
    mounted() {
      this.$root.$on('bv::modal::shown', () => {
        this.isModalOpened = true
      })
      this.$root.$on('bv::modal::hide', () => {
        this.isModalOpened = false
      })
      this.listenerKeyUp()
    },
    destroyed() {
      window.removeEventListener('keydown', this.keydown)
    },
    methods: {
      test() {
      },
      search_product() {
        if (this.searchString.length > 3) return (this.search_result = true)

        this.search_result = false
      },
      search_input(params) {
        clearTimeout(this.timer)
        this.timer = setTimeout(
          async () => {
            if (this.searchString.length > 1) await this.search(this.searchString)
          },
          params.enter ? 0 : 500
        )
      },
      async search(value) {
        this.isLoading = true
        this.error = false
        try {
          const { data } = await this.$apollo.query({
            query: require('../../gql/searchEdoSpec.graphql'),
            fetchPolicy: 'no-cache',
            variables: {
              input: {
                name: value,
                document_head: this.document_head
              }
            }
          })

          this.data = this.searchProductResult(data)
          return !!data.SpecSearchEdo?.specification
        } catch (e) {
          console.error(e)
          this.error = true
          return false
        } finally {
          this.isLoading = false
        }
      },
      searchProductResult(data = {}) {
        const result = data.SpecSearchEdo?.specification ?? []

        if (result.length === 1) {
          if (result[0].found_by_serial) {
            EventBus.$emit('notify', {
              variant: 'primary',
              title: 'Марка успешно принята',
              card: {
                message: result[0].product.name,
                info: new ProductModel(result[0].product).info
              },
              unique: 'incoming-spec',
              actions: [
                {
                  action: async () => {
                    try {
                      await this.$apollo.mutate({
                        mutation: require('../../gql/updateIncomingEdoSpecification.graphql'),
                        fetchPolicy: 'no-cache',
                        variables: {
                          input: {
                            document_head: this.document_head,
                            serial: result[0].serial.serial_number,
                            turn_on_serial: false
                          }
                        }
                      })
                      this.$noty.show('Успешно!')
                    } catch (e) {
                      this.$noty.error('Ошибка')
                    }
                    this.select_product({
                      ...result[0],
                      delta: result[0].delta - 1,
                      serial_count: result[0].serial_count - 1
                    })
                  },
                  title: 'Отклонить',
                  info: 'sdfsd'
                }
              ]
            })
            this.search_result = false
            this.searchString = ''
            this.$emit('clear-search')
            this.select_product(result[0])
            return
          }
          // this.searchString = ''
          //
          // this.$emit('clear-search')
          // this.search_result = false
        } else if (result.length === 0) {
          if ([68, 150, 26].includes(this.searchString.length)) {
            EventBus.$emit('notify', {
              unique: 'incoming-spec',
              variant: 'danger',
              title: 'Марка не найдена',
              message: 'Марка, которую вы ищите отсутсвует в этом документе '
            })
            this.search_result = false
            this.searchString = ''
            this.$emit('clear-search')
          }
        }

        return data
      },
      select_product(item) {
        this.$emit('select_product', { ...item, query: this.searchString })
        this.searchString = ''
        this.search_result = false
      },
      clickOutside() {
        this.searchString = ''
        this.search_result = false
      },
      keydown(item) {
        let time = new Date()
        if (time.getTime() - this.lastTime.getTime() > 50) this.searchStr = ''
        this.lastTime = time
        if (document.querySelector('input:focus')) return
        const key = item.key

        if (item.target.attributes.isKeyup || key === 'Alt') return false
        if (key === 'Escape' || key === 'Delete') return (this.searchString = '')

        if (key === 'Backspace')
          return (this.searchString = this.searchString.substring(0, this.searchString.length - 1))

        if (key === 'Enter' && this.searchStr.length > 3) {
          this.searchString = this.searchStr
          this.search_product()
          this.search_input({ enter: true })
          this.searchStr = ''
        }

        if (key.length === 1) {
          this.searchStr += key
        }

        // this.handler_search_str = this.handler_search_str + key
      },
      listenerKeyUp() {
        this.$root.$on('bv::modal::show', () => {
          this.isModalOpened = true
        })
        this.$root.$on('bv::modal::hide', () => {
          this.isModalOpened = false
        })
        if (this.isModalOpened) return

        window.addEventListener('keydown', this.keydown)
      }
    },
    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch'
      })
    }
  }
</script>

<style scoped></style>
<style scoped lang="scss">
  .e-alert {
    background: black;
    z-index: 10;
  }

  .b-search {
    &-product {
      width: 250px;
    }
  }

  .b-search__wrapper > div {
    position: absolute !important;
  }
</style>
