<template>
  <section id="ukd">
    <b-modal
      id="comment-modal"
      centered
    >
      <template #modal-header="{ close }">
        <h5>Введите комментарий для уточнения</h5>
        <b-button
          class="close"
          @click="close()"
        >
          <img
            src="/img/icons/modal_close.svg"
            alt=""
          />
        </b-button>
      </template>
      <template #modal-footer="{ hide }">
        <div style="display: flex; flex-direction: row">
          <b-button
            variant="success"
            @click="signDoc('correction_end')"
          >
            Отправить
          </b-button>
          <b-button
            variant="outline-success"
            @click="
              correction_comment = ''
              hide()
            "
          >
            Отмена
          </b-button>
        </div>
      </template>
      <b-form-textarea v-model="correction_comment" />
    </b-modal>
    <b-modal
      id="ukd-modal"
      hide-footer
      centered
      size="xl"
      @show="getUkd"
    >
      <template #modal-header="{ hide }">
        <div style="display: flex; flex-direction: row; align-items: center">
          <div
            style="display: flex; flex-direction: row; align-items: center; cursor: pointer"
            class="mr-3"
            @click="hide"
          >
            <img
              class="mr-2"
              src="/img/icons/arrow_pagination.svg"
              alt=""
              width="4.5"
              height="8"
            />
            <span style="font-size: 12px">Назад </span>
          </div>
          <b-dropdown
            no-caret
            variant="none"
            right
            toggle-class="white-backgr"
          >
            <template #button-content>
              <div style="display: flex; flex-direction: row; align-items: center">
                <h5 class="mb-0">
                  {{ selected_doc?.name ?? ' ' }}
                  <span
                    style="color: #888888"
                    class="mr-2 ml-2"
                    >|</span
                  >
                  <span
                    :style="{
                      color: edo_status[selected_doc.edo.status].color
                    }"
                    style="font-size: 16px"
                    >Статус: {{ edo_status[selected_doc.edo.status].text }}</span
                  >
                </h5>
                <img
                  class="ml-3"
                  style="transform: rotate(180deg)"
                  src="/img/icons/arrow_up.svg"
                  alt=""
                />
              </div>
            </template>
            <b-dropdown-item
              v-for="document of documents"
              :key="document.name"
              @click="selected_doc = document"
            >
              {{ document.name ?? ' ' }} | Статус:
              <span
                :style="{
                  color: edo_status[document.edo.status].color
                }"
                >{{ edo_status[document.edo.status].text }}</span
              >
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div style="display: flex; flex-direction: row">
          <b-button
            variant="gray"
            @click="printUkd"
          >
            Печать
          </b-button>
          <span
            class="ml-2"
            style="border: 1px solid #e5b509 !important; border-radius: 4px"
          >
            <b-button
              variant="outline-warning"
              style="height: 30px !important"
              @click="signDoc('correction')"
              >Уточнить
            </b-button> </span
          ><b-button
            class="ml-2"
            variant="success"
            @click="signDoc('confirm')"
          >
            Подтвердить
          </b-button>
        </div>
      </template>
      <b-card
        no-body
        class="p-0"
        style="height: 55vh; overflow: hidden"
      >
        <b-table
          class="ukd-table"
          :fields="fields"
          table-class="border-0"
          sticky-header
          no-border-collapse
          :items="selected_doc?.specifications ?? []"
        >
          <template #cell(product)="{ item }">
            {{ item.product?.name ?? '-' }}
          </template>

          <template #cell(measurement)="{ item }">
            {{ item.product?.measurement?.name ?? '-' }}
          </template>

          <template #cell(delta)="{ item }">
            {{ item.delta }}
            <span
              v-if="item.delta_change"
              :class="{
                error: item.delta_change < 0,
                success: item.delta_change >= 0
              }"
              >({{ item.delta_change > 0 ? '+' : '' }}{{ +item.delta_change.toFixed(3) }})</span
            >
          </template>

          <template #cell(price)="{ item }">
            {{ formatPrice(+item.price?.toFixed(2)) }}
            <span
              v-if="item.price_change"
              :class="{
                error: item.price_change < 0,
                success: item.price_change >= 0
              }"
              >({{ item.price_change > 0 ? '+' : '' }}{{ +item.price_change.toFixed(3) }})</span
            >
          </template>

          <template #cell(sum)="{ item }">
            {{ formatPrice(+item.sum?.toFixed(2)) }}
            <span
              v-if="item.sum_change"
              :class="{
                error: item.sum_change < 0,
                success: item.sum_change >= 0
              }"
              >({{ item.sum_change > 0 ? '+' : '' }}{{ +item.sum_change.toFixed(3) }})</span
            >
          </template>

          <template #cell(nds)="{ item }">
            {{ item.nds?.name ?? '-' }}
          </template>

          <template #cell(nds_sum)="{ item }">
            {{ formatPrice(item.sum * (item.taxRate?.value ?? 0)) }}
          </template>
        </b-table>
      </b-card>
    </b-modal>
  </section>
</template>

<script>
  //Todo Зделал scoped но несмог найти эту модалку для проверки

  import { http } from '@/http/http'
  import { createDetached, getCert, getNewToken, owner_info } from '@/utils/crptech/crptech_token'
  import { IncomingHeadModel } from '@/views/operational-processes/models/incoming-head.model'
  import { print_html } from '@/utils/print'

  export default {
    name: 'UkdModal',
    props: {
      document: {
        type: IncomingHeadModel,
        default: () => new IncomingHeadModel()
      }
    },
    data() {
      return {
        fields: [
          { key: 'ext_number', label: '№' },
          { key: 'product', label: 'Наименование', thStyle: 'min-width:250px' },
          { key: 'measurement', label: 'Ед.изм' },
          { key: 'delta', label: 'Кол-во', thStyle: 'min-width:110px' },
          {
            key: 'price',
            label: 'Цена за ед. без НДС, ₽',
            thStyle: 'min-width:120px'
          },
          { key: 'sum', label: 'Стоимость без НДС, ₽' },
          { key: 'nds', label: 'Налоговая ставка' },
          { key: 'nds_sum', label: 'Сумма НДС, ₽' },
          { key: 'serial', label: 'Сведения о маркировке' }
        ],
        documents: [],
        selected_doc: null,
        correction_comment: '',
        edo_status: {
          awaiting: {
            background: '#FFFAE7',
            color: '#D4A600',
            text: 'Отправлен'
          },
          clarified: {
            background: '#EBF9F5',
            color: '#00CB91',
            text: 'Уточнён'
          },
          awaiting_clarification: {
            background: '#FFEBEE',
            color: '#E53835',
            text: 'Требуется уточнение'
          },
          signed: {
            background: '#EBF9F5',
            color: '#00CB91',
            text: 'Подписан'
          },
          rejected: {
            background: '#EBF9F5',
            color: '#00CB91',
            text: 'Отменён'
          }
        }
      }
    },
    methods: {
      async getUkd() {
        const { data } = await this.$apollo.query({
          query: require('../../gql/getUKD.graphql'),
          fetchPolicy: 'no-cache',
          variables: {
            input: {
              head: this.$route.params.id,
              operation: 'correction'
            }
          }
        })
        this.documents = data?.SystemHeads ?? []
        if (this.documents.length) {
          this.selected_doc = this.documents[0]
        }
      },
      async printUkd() {
        try {
          const { data } = await http.get('templates/edo/ukd?document=' + this.selected_doc.id)
          if (data) {
            var mywindow = window.open()
            mywindow.title = 'Документ УКД №' + this.selected_doc.ext_number
            print_html(data)
          } else {
            this.$noty.show('Ошибка печати')
          }
        } catch (e) {
          console.error(e)
          this.$noty.show('Ошибка печати')
        }
      },
      formatPrice(price) {
        return new Intl.NumberFormat('ru-RU', {
          maximumFractionDigits: 2,
          minimumFractionDigits: 0
        }).format(price)
      },
      async signDoc(type) {
        if (type === 'correction') {
          this.$bvModal.show('comment-modal')
          return
        } else {
          try {
            await getNewToken(this.selected_doc.branch.id)
            let cert = await getCert(this.document.edo.thumbprint)

            const owner = await owner_info(cert)

            const { data } = await this.$apollo.query({
              query: require('../../gql/crptech/SendEdoEvent.graphql'),
              variables: {
                input: {
                  id: this.selected_doc.id,
                  inn: owner.inn,
                  position: owner.position,
                  comment: this.correction_comment ?? null,
                  action: type === 'correction_end' ? 'make_used_with_diff' : 'make_used',
                  full_name: owner.full_name,
                  type: type === 'correction_end' ? 'correction' : type,
                  token: JSON.parse(sessionStorage.getItem(this.selected_doc.branch.id))[this.document.edo.thumbprint]
                }
              }
            })

            if (data?.SendEdoEvent?.data) {
              let utf_string = data.SendEdoEvent.data
              const iconv = require('iconv-lite')

              const buff = iconv.encode(utf_string, 'win1251')

              const sgn = await createDetached(cert, buff)
              await this.$apollo.mutate({
                mutation: require('../../gql/crptech/SignXMLDoc.graphql'),
                variables: {
                  input: {
                    id: this.selected_doc.id,
                    data: data.SendEdoEvent.id,
                    doc_signature: sgn,
                    type: type === 'correction_end' ? 'correction' : type,
                    token: JSON.parse(sessionStorage.getItem(this.selected_doc.branch.id))[this.document.edo.thumbprint]
                  }
                }
              })
            }
            this.$noty.show('Отправлено')
            this.$bvModal.hide('comment-modal')
            this.correction_comment = ''
          } catch (e) {
            this.$noty.error('Ошибка отправки')
          }
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  ::v-deep() {
    .ukd-table thead th {
      border-bottom: 1px solid #e1e1e1 !important;
      border-right: 1px solid #e1e1e1;
      border-top: 1px solid #e1e1e1 !important;
    }

    .ukd-table tbody tr td {
      border-bottom: 1px solid #e1e1e1 !important;
    }

    .border-0 {
      border-top: 0 !important;
    }
    .dropdown .dropdown-menu {
      z-index: 10 !important;
    }

    #ukd-modal {
      .modal-xl {
        max-width: 78vw;
      }
    }

    #ukd {
      .dropdown:hover {
        background: #ffffff !important;
      }
    }
  }

  .success {
    color: #00cb91;
  }

  .ukd-table {
    min-height: 55vh;
    height: 55vh;
    margin-bottom: 0;
  }

  .ukd-table thead th {
    border-bottom: 1px solid #e1e1e1 !important;
    border-right: 1px solid #e1e1e1;
    border-top: 1px solid #e1e1e1 !important;
  }

  .ukd-table tbody tr td {
    border-bottom: 1px solid #e1e1e1 !important;
  }

  .border-0 {
    border-top: 0 !important;
  }
  .dropdown .dropdown-menu {
    z-index: 10 !important;
  }

  #ukd-modal {
    .dropdown .dropdown-toggle:hover {
      background: #ffffff !important;
    }

    .dropdown:hover {
      background: #ffffff !important;
    }

    .dropdown .dropdown-toggle {
      background: #ffffff !important;
    }

    .btn .dropdown-toggle .btn-secondary:hover {
      background: #ffffff !important;
    }

    .rotation {
      transform: rotate(90deg) !important;
    }
  }

  #ukd {
    .dropdown .dropdown-toggle:hover {
      background: #ffffff !important;
    }

    .dropdown:hover {
      background: #ffffff !important;
    }

    .dropdown .dropdown-toggle {
      background: #ffffff !important;
    }

    .btn .dropdown-toggle .btn-secondary:hover {
      background: #ffffff !important;
    }

    .rotation {
      transform: rotate(90deg) !important;
    }

    .white-backgr * {
      background: none !important;
    }

    .white-backgr:hover * {
      background: none !important;
    }
  }
</style>
