var render = function render(){
  var _vm$data$SpecSearchEd, _vm$data$SpecSearchEd2, _vm$data$SpecSearchEd3;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "b-search-product"
  }, [_c('div', {
    staticClass: "b-search__wrapper",
    on: {
      "click": _vm.test
    }
  }, [_c('b-form-input', {
    ref: "searchInput",
    attrs: {
      "id": "productSearchInput",
      "type": "text",
      "placeholder": "Введите марку или штрихкод",
      "is-keyup": true,
      "autocomplete": "off"
    },
    on: {
      "keyup": _vm.search_product,
      "input": _vm.search_input
    },
    model: {
      value: _vm.searchString,
      callback: function ($$v) {
        _vm.searchString = $$v;
      },
      expression: "searchString"
    }
  }), _vm.search_result ? [[_vm.isLoading ? _c('div', {
    staticClass: "b-search-result p-2 center"
  }, [_c('b-spinner', {
    staticClass: "mt-4",
    attrs: {
      "variant": "primary"
    }
  })], 1) : _vm.error ? _c('div', {
    staticClass: "b-search-result error apollo"
  }, [_vm._v(" Ошибка при запросе ")]) : _vm.data ? _c('div', {
    staticClass: "b-search-result text-left"
  }, [((_vm$data$SpecSearchEd = _vm.data.SpecSearchEdo) === null || _vm$data$SpecSearchEd === void 0 ? void 0 : (_vm$data$SpecSearchEd2 = _vm$data$SpecSearchEd.specification) === null || _vm$data$SpecSearchEd2 === void 0 ? void 0 : _vm$data$SpecSearchEd2.length) > 0 ? _vm._l((_vm$data$SpecSearchEd3 = _vm.data.SpecSearchEdo) === null || _vm$data$SpecSearchEd3 === void 0 ? void 0 : _vm$data$SpecSearchEd3.specification, function (item, index) {
    var _item$product$supplie, _item$product$supplie2;

    return _c('div', {
      directives: [{
        name: "click-outside",
        rawName: "v-click-outside",
        value: _vm.clickOutside,
        expression: "clickOutside"
      }],
      key: index,
      staticClass: "no-control item cursor",
      on: {
        "click": function ($event) {
          return _vm.select_product(item);
        }
      }
    }, [_c('div', {
      staticClass: "no-control item-name"
    }, [_vm._v(" " + _vm._s(item.product.name) + " ")]), _c('div', {
      staticClass: "no-control item-info"
    }, [_c('span', {
      staticClass: "mr-2 no-control"
    }, [_vm._v(" " + _vm._s(item.product.articul ? item.product.articul : '-----') + " ")]), _c('span', {
      staticClass: "no-control mr-2"
    }, [_vm._v(" " + _vm._s((_item$product$supplie = item.product.supplier) !== null && _item$product$supplie !== void 0 && _item$product$supplie.name ? (_item$product$supplie2 = item.product.supplier) === null || _item$product$supplie2 === void 0 ? void 0 : _item$product$supplie2.name : 'Нет данных') + " ")]), _c('span', {
      staticClass: "no-control",
      staticStyle: {
        "float": "right",
        "margin-right": "0"
      }
    }, [_vm._v(" " + _vm._s(item.product.retailPrice ? item.product.retailPrice : `0.00`) + " ₽ ")])])]);
  }) : [_c('div', {
    staticClass: "center"
  }, [_vm._v("Не найдено")])]], 2) : _c('div', {
    staticClass: "b-search-result p-3 center"
  }, [_c('b-spinner', {
    attrs: {
      "variant": "primary"
    }
  })], 1)]] : _vm._e()], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }