<template>
  <div>
    <div class="b-table__filter d-flex justify-content-between">
      <div class="b-toggle-button">
        <b-button
          class="btn-filters cursor"
          @click="changeVisibleParams"
        >
          Параметры документа
          <img
            :class="{ rotate: visibleParams }"
            class="ml-3"
            src="/img/icons/arrow_up.svg"
            alt=""
          />
        </b-button>
        <b-button
          v-if="
            document.edo?.type === 'crptech' && ['awaiting_clarification', 'clarified'].includes(document.edo?.status)
          "
          v-b-modal.ukd-modal
          class="ukd-button ml-3"
        >
          <img
            src="/img/integrations/crptech-white.svg"
            alt=""
          />
          <span class="ml-2">УКД</span>
        </b-button>
      </div>
      <div class="d-flex">
        <e-button
          v-show="!document.fixed"
          size="m"
          variant="outline-primary"
          @click="back"
        >
          Сохранить черновик
        </e-button>
        <e-button
          size="m"
          variant="primary"
          class="ml-3"
          :style="
            ['mercury', 'crptech', '-sbis', 'egais'].includes(document.edo?.type) &&
            document.edo.action !== 'make_used' &&
            !document.fixed
              ? { background: '#D4A600', border: 'none' }
              : {}
          "
          :disabled="document?.edo?.action === 'incorrect' || isLoading || is_request_update"
          :loading="isLoading || is_request_update"
          @click="updateDocument(document.fixed)"
        >
          <template
            v-if="
              (['mercury', 'crptech', '-sbis', 'egais'].includes(document.edo?.type) &&
                document?.edo?.action === 'make_used' &&
                !document?.fixed) ||
              (!['mercury', 'crptech', '-sbis', 'egais'].includes(document.edo?.type) && !document.fixed)
            "
          >
            Провести
          </template>
          <template
            v-else-if="
              (document?.edo?.action === 'make_used_with_diff' || 'incorrect') &&
              !document?.fixed &&
              ['mercury', 'crptech', '-sbis', 'egais'].includes(document.edo?.type)
            "
          >
            Провести с расхождением
          </template>
          <template v-else-if="document.fixed">Редактировать</template>
        </e-button>
      </div>
    </div>
    <e-collapse
      v-model="visibleParams"
      :show-button="false"
    >
      <template #content>
        <filters-incoming
          ref="header_filters_incoming"
          :fixed="document?.fixed"
          :entity_list="entity_list"
          :filter_params="document"
          :is_supplier_error="isSupplierError"
          :is_storage_error="isStorageError"
          :is_entity_error="isEntityError"
          @change="change"
          @updateData="updateData"
        />
      </template>
    </e-collapse>
  </div>
</template>

<script>
  import FiltersIncoming from '@/views/operational-processes/components/overhead/FiltersIncoming'
  import { IncomingHeadModel } from '@/views/operational-processes/models/incoming-head.model'
  import { EntityModel } from '@/models/entity.model'

  export default {
    name: 'IncomingHead',
    components: {
      FiltersIncoming
    },
    props: {
      document: {
        type: IncomingHeadModel
      },
      entity_list: [EntityModel]
    },
    data() {
      return {
        visibleParams: false,
        isSupplierError: false,
        isStorageError: false,
        isEntityError: false,
        is_request_update: false,
        isLoading: false
      }
    },
    destroyed() {
      localStorage.setItem('newDocHeader', 'false')
    },
    beforeMount() {
      this.visibleParams =
        localStorage.getItem('newDocHeader') === 'true' || localStorage.getItem('docHeader') === 'true'
    },
    methods: {
      changeVisibleParams() {
        this.visibleParams = !this.visibleParams
        if (this.visibleParams) {
          localStorage.setItem('docHeader', 'true')
        } else {
          localStorage.setItem('docHeader', 'false')
        }
      },
      updateDocument(status) {
        this.isLoading = true
        if (!status && !this.document.supplier?.id) {
          this.isSupplierError = true
          this.isLoading = false
          return this.$noty.error('Выберите поставщика')
        }
        if (!status && !this.document.storage?.id) {
          this.isStorageError = true
          this.isLoading = false
          return this.$noty.error('Выберите место хранения')
        }
        if (!status && !this.document.entity?.id) {
          this.isEntityError = true
          this.isLoading = false
          return this.$noty.error('Выберите организацию')
        }
        this.$emit('makeUsed', !status, () => {
          this.isLoading = false
          this.$emit('update-data')
        })
      },
      makeFixed(status) {
        this.$emit('makeUsed', status)
      },
      updateData() {
        this.$emit('update-data')
      },
      back() {
        this.$emit('onChange')
        this.$router.back()
      },
      change(isUpdate) {
        if (this.document.supplier?.id) this.isSupplierError = false
        if (this.document.storage?.id) this.isStorageError = false
        if (this.document.entity?.id) this.isEntityError = false
        this.$emit('onChange', isUpdate)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .ukd-button {
    background: #e5b509 !important;
    color: white !important;

    img {
      transform: none !important;
    }
  }

  .b-table__filter {
    .b-toggle-button {
      button {
        background: #e2e2e2;
        color: #313131;

        img {
          margin-right: 0;
          transition: all 0.2s;
        }

        &.not-collapsed {
          img {
            transform: rotate(0);
          }
        }
      }
    }
  }

  .table-filter {
    button {
      background: #efefef;
      color: #313131;

      &:hover {
        background: #efefef;
        color: #313131;
      }
    }
  }
  ::v-deep .elm-calendar input {
    height: 31px;
  }
  .rotate {
    transform: rotate(360deg) !important;
  }
</style>
