<template>
  <b-modal
    id="disperancy-modal"
    centered
    size="lg"
  >
    <template #modal-header>
      <h5>Обнаружено расхождение</h5>
    </template>
    В документе обнаружено расхождение между заявленным и фактическим количеством
    <b-form-textarea
      placeholder="Введите комменатрий"
      class="mt-3"
      :value="document.comment"
      @input="document.setComment"
    />
    <template #modal-footer>
      <div style="display: flex">
        <b-button
          variant="primary"
          class="text-nowrap w-auto"
          :disabled="document.comment.length <= 0"
          @click="makeUsed"
        >
          Провести с расхождением
        </b-button>
        <b-button
          variant="outline-primary"
          @click="hideModal"
        >
          Назад
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
  import { IncomingHeadModel } from '@/views/operational-processes/models/incoming-head.model'

  export default {
    name: 'DiscrepancyModal',
    props: {
      count: {
        type: Number,
        default: () => 0
      },
      document: {
        type: IncomingHeadModel,
        default: () => new IncomingHeadModel()
      }
    },
    computed: {
      positions() {
        if ((this.count % 100) % 10 === 1) return 'позиции'
        else return 'позиций'
      }
    },
    methods: {
      hideModal() {
        this.$bvModal.hide('disperancy-modal')
      },
      makeUsed() {
        this.$emit('makeUsed', true)
        this.$bvModal.hide('disperancy-modal')
      }
    }
  }
</script>

<style scoped></style>
