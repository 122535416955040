<template>
  <div
    class="params-body"
    style="padding-top: 16px"
  >
    <div class="card pb-0 pr-0 mb-0">
      <b-form class="form pr-0">
        <b-row class="w-100 align-items-end">
          <b-col
            xl="2"
            lg="4"
            md="4"
            sm="6"
            class="pr-0"
          >
            <b-form-group
              label="Дата"
              label-for="work_period"
            >
              <template v-if="fixed">
                {{ convertDate(filter_params.date) }}
              </template>
              <template v-else>
                <date-picker
                  id="work_period"
                  :disabled="filter_params.edo?.type === 'crptech'"
                  :clearable="false"
                  class="elm-calendar"
                  placeholder="Выберите дату"
                  :value="filter_params.date"
                  format="dd.MM.yyyy"
                  transfer
                  @on-change="setDate"
                />
              </template>
            </b-form-group>
          </b-col>
          <b-col
            xl="2"
            lg="4"
            md="4"
            sm="6"
            class="pr-0"
          >
            <b-form-group
              label="Операции по документу"
              label-for="doc_operations"
            >
              <template v-if="fixed">
                {{ operation_list.find((el) => el?.id === filter_params?.operation?.name)?.name }}
              </template>
              <template v-else>
                <i-select
                  id="doc_operations"
                  :value="filter_params.operation.type"
                  :disabled="fixed"
                  transfer
                  @on-select="setOperation"
                >
                  <i-option
                    v-for="item in operation_list"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </i-option>
                </i-select>
              </template>
            </b-form-group>
          </b-col>
          <b-col
            xl="2"
            lg="4"
            md="4"
            sm="6"
            class="pr-0"
          >
            <b-form-group
              label="Поставщик"
              label-for="supplier"
              :class="{ error: is_supplier_error }"
              :disabled="disabled"
            >
              <b-tooltip
                v-if="fixed"
                target="supplier__text"
                placement="top"
                variant="dark"
              >
                <div class="supplier_tooltip">
                  <p class="supplier_tooltip__name">{{ filter_params.supplier?.name }}</p>
                  <div class="supplier_tooltip__inn-kpp">
                    <p class="supplier_tooltip__inn-kpp__inn">ИНН: {{ filter_params.supplier?.entity?.inn ?? '--' }}</p>
                    <p class="supplier_tooltip__inn-kpp__kpp">КПП :{{ filter_params.supplier?.entity?.kpp ?? '--' }}</p>
                  </div>
                </div>
              </b-tooltip>
              <div
                v-if="fixed"
                id="supplier__text"
                class="supplier_text"
              >
                {{ filter_params.supplier.name }}
              </div>
              <template v-else>
                <i-select
                  id="supplier"
                  :value="filter_params.supplier.id"
                  filterable
                  transfer
                  :disabled="disabled"
                  @on-change="setSupplier"
                >
                  <i-option
                    v-for="item in supplier_list"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </i-option>
                </i-select>
              </template>
            </b-form-group>
          </b-col>
          <b-col
            xl="2"
            lg="4"
            md="4"
            sm="6"
            class="pr-0"
          >
            <b-form-group
              label="№ Документа поставщика"
              label-for="supplier_doc_number"
            >
              <template v-if="fixed">
                {{ filter_params.supplierDocument.id ?? '--' }}
              </template>
              <template v-else>
                <b-form-input
                  id="supplier_doc_number"
                  type="text"
                  :value="filter_params.supplierDocument.id"
                  :disabled="disabled"
                  :is-keyup="true"
                  @input="setSupplierDocumentId"
                />
              </template>
            </b-form-group>
          </b-col>
          <b-col
            xl="2"
            lg="4"
            md="4"
            sm="6"
            class="pr-0"
          >
            <b-form-group
              label="Дата поставщика"
              label-for="supplier_data"
            >
              <template v-if="fixed">
                {{ convertDate(filter_params.supplierDocument.date) ?? '--' }}
              </template>
              <template v-else>
                <date-picker
                  id="supplier_data"
                  class="elm-calendar"
                  :clearable="false"
                  placeholder="Выберите дату"
                  :value="filter_params.supplierDocument.date"
                  format="dd.MM.yyyy"
                  :is-keyup="true"
                  transfer
                  :disabled="disabled"
                  @on-change="setSupplierDate"
                />
              </template>
            </b-form-group>
          </b-col>
          <b-col
            xl="2"
            lg="4"
            md="4"
            sm="6"
            class="pr-0"
          >
            <b-form-group
              label="Место хранения"
              label-for="storage"
              :class="{ error: is_storage_error }"
            >
              <template v-if="fixed">
                {{ filter_params.storage.name }}
              </template>
              <template v-else>
                <i-select
                  id="storage"
                  :value="filter_params.storage.id"
                  filterable
                  transfer
                  @on-change="changeStorage"
                >
                  <i-option
                    v-for="item in storage_list"
                    :key="item.id"
                    :value="item.id"
                    :label="item.name"
                  >
                    <div>{{ item.name }}</div>
                    <div
                      v-if="item.branch?.name"
                      class="branch-info"
                    >
                      {{ item.branch?.name }}
                    </div>
                  </i-option>
                </i-select>
              </template>
            </b-form-group>
          </b-col>
          <b-col
            xl="2"
            lg="4"
            md="4"
            sm="6"
            class="pr-0"
          >
            <b-form-group
              label="Организация"
              label-for="entity"
              :class="{ error: is_entity_error }"
            >
              <template v-if="fixed">
                {{ filter_params.entity.name }}
              </template>
              <e-select
                v-else
                v-model="active_entity"
                :error="is_entity_error"
                selected_type="text"
                :options="entity_list"
                select_name="entity_select"
              >
                <template #custom_name="{ item }">
                  <div class="entity">
                    <p class="name">{{ item.name }}</p>
                    <div class="inn_kpp">
                      <p class="inn">ИНН: {{ item.inn ? item.inn : '-' }}</p>
                      <p class="kpp">КПП: {{ item.kpp ? item.kpp : '-' }}</p>
                    </div>
                  </div>
                </template>
              </e-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="w-100">
          <b-col>
            <div
              v-b-toggle.collapse-extraparams
              class="btn-extra-param cursor mb-3"
            >
              <span class="isHide">Показать</span>
              <span class="isShow">Скрыть</span>
              дополнительные параметры
            </div>
            <b-collapse
              id="collapse-extraparams"
              class="row mb-3"
            >
              <b-col
                col
                xl="4"
                lg="4"
                md="12"
                class="pr-0"
              >
                <b-form-group
                  label="Комментарий"
                  label-for="comment"
                >
                  <b-form-textarea
                    id="comment"
                    :value="filter_params.comment"
                    rows="3"
                    max-rows="6"
                    :is-keyup="true"
                    :disabled="fixed"
                    @input="setComment"
                  />
                </b-form-group>
              </b-col>
            </b-collapse>
          </b-col>
        </b-row>
      </b-form>
    </div>
  </div>
</template>

<script>
  import formatDate from '@/utils/formatDate'
  // import { bus } from '@/main'
  import { mapGetters } from 'vuex'
  import { IncomingHeadModel } from '@/views/operational-processes/models/incoming-head.model'
  import { EntityModel } from '@/models/entity.model'

  export default {
    props: {
      menu_list: {
        type: Array,
        default: () => []
      },

      filter_params: {
        type: IncomingHeadModel,
        default: null
      },

      fixed: {
        type: Boolean,
        default: false
      },

      is_entity_error: {
        type: Boolean,
        default: false
      },

      is_supplier_error: {
        type: Boolean,
        default: false
      },
      is_storage_error: {
        type: Boolean,
        default: false
      },
      entity_list: [EntityModel]
    },

    apollo: {
      Suppliers: {
        query: require('../../gql/getSuppliers.gql'),
        fetchPolicy: 'no-cache',
        result({ data }) {
          this.supplier_list = data.Suppliers || []
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      },
      Branches: {
        query: require('../../gql/Branches.gql'),
        skip() {
          return this.filter_params.edo?.can_change_branch !== true
        },
        result({ data }) {
          this.storage_list =
            data.Branches.flatMap((el) => {
              return el.storage.map((obj) => {
                return {
                  ...obj,
                  branch: {
                    id: el.id,
                    name: el.name
                  }
                }
              })
            }) || []
        }
      },
      Storages: {
        query: require('../../gql/getStorages.gql'),
        fetchPolicy: 'no-cache',
        skip() {
          return !this.filter_params.id || this.filter_params.edo?.can_change_branch === true
        },
        variables() {
          return {
            branch: this.filter_params.branch?.id || this.currentBranch?.id
          }
        },
        result({ data }) {
          this.storage_list = data.Storages || []
          if (!this.first) {
            if (this.storage_list.length === 1) {
              this.filter_params.setStorage(data.Storages?.[0])
              this.$emit('change')
            }
          }
          this.first = false
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      }
    },

    data: () => ({
      active_entity: [],
      first: true,
      operation_list: [
        {
          name: 'Покупка',
          id: 'income',
          type: 'income'
        },
        {
          name: 'Бонус',
          id: 'bonus',
          type: 'bonus'
        },
        {
          name: 'Комиссия',
          id: 'commission',
          type: 'commission'
        }
      ],
      supplier_list: [],
      storage_list: [],
      timer: null
    }),

    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch',
        getIncomingDocument: 'operprocess/getIncomingDocument'
      }),
      disabled() {
        return ['mercury', 'crptech', 'egais', 'order', 'sbis'].includes(this.filter_params.edo?.type) || this.fixed
      }
    },

    watch: {
      filter_params() {
        this.$apollo.queries.Storages.refresh()
        if (this.filter_params?.entity?.id) {
          this.active_entity = [this.filter_params.entity]
        }
      },
      active_entity(newVal) {
        this.filter_params.setEntity(newVal[0])
        this.$emit('change')
      }
    },

    mounted() {
      if (this.filter_params?.entity?.id) {
        this.active_entity = [this.filter_params.entity]
      }
    },

    methods: {
      changeStorage(id) {
        const item = this.storage_list.find((item) => item.id === id)
        if (!item) return
        if (this.filter_params.edo?.can_change_branch) {
          this.$bvModal
            .msgBoxConfirm(
              'Розничные цены будут изменены на значения магазина, которому принадлежит выбранное место хранения',
              {
                title: 'Внимание!',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'primary',
                cancelVariant: 'outline-primary',
                okTitle: 'Продолжить',
                footerClass: 'flex-row-reverse p-3',
                cancelTitle: 'Отмена',
                centered: true
              }
            )
            .then(async (value) => {
              if (value) {
                this.filter_params.setStorage(item)
                if (item.branch) this.filter_params.setBranch(item.branch)
                this.$nextTick(() => {
                  this.$emit('change', true)
                })
              }
            })
        } else {
          this.filter_params.setStorage(item)
          this.$nextTick(() => this.$emit('change'))
        }
      },
      setOperation({ value }) {
        this.filter_params.setOperation(this.operation_list.find((el) => el.id === value))
        this.$emit('change')
      },
      setSupplier(val) {
        const supplier = this.supplier_list.find((el) => el.id === val)
        this.filter_params.setSupplier(supplier)
        this.$emit('change')
      },
      setSupplierDocumentId(val) {
        this.filter_params.setSupplierDocumentId(val)
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          this.$emit('change')
        }, 500)
      },
      setComment(val) {
        this.filter_params.setComment(val)
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          this.$emit('change')
        }, 500)
      },
      setDate(val) {
        this.filter_params.setDate(this.toNormalDate(val))
        this.$emit('change')
      },
      setSupplierDate(val) {
        this.filter_params.setSupplierDocumentDate(this.toNormalDate(val))
        this.$emit('change')
      },
      toNormalDate(val) {
        const parts = val.split('.')
        return new Date(parseInt(parts[2], 10), parseInt(parts[1], 10) - 1, parseInt(parts[0], 10))
      },

      convertDate(date) {
        if (date) return formatDate(new Date(date))
      }
    }
  }
</script>

<style scoped lang="scss">
  .branch-info {
    overflow: hidden;
    color: var(--text-secondary-gray-400, #888);
    text-overflow: ellipsis;
    whitespace: nowrap;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    margin-top: 5px;
  }
  ::v-deep() {
    #comment {
      height: 82px !important;
    }

    .params-body {
      .form {
        .col-lg-4 {
          margin-bottom: 16px !important;
        }

        // @media (max-width: 1200px) {}
      }

      .btn-extra-param {
        border-bottom: 1px solid #313131;
        display: inline-block;

        .isShow {
          display: none;
        }

        &.not-collapsed {
          .isHide {
            display: none;
          }

          .isShow {
            display: inline;
          }
        }
      }

      textarea {
        overflow: hidden;
        resize: none;
        height: 86px !important;
      }

      @media screen and (max-width: 1290px) and (min-width: 1200px) {
        .col-xl-2 {
          flex: 0 0 33.33333333%;
          max-width: 33.33333333%;
        }
      }
    }

    .form-control {
      height: 32px !important;
    }

    .elm-calendar input {
      height: 32px;
    }
  }

  .ms__dropdown-item.active {
    .name {
      color: #00a3ff;
    }
  }

  .entity {
    display: flex;
    flex-direction: column;
    white-space: nowrap;

    p {
      margin-bottom: 0;
    }

    .name {
      display: flex;
      float: left;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #313131;
    }

    .inn_kpp {
      display: flex;

      .inn {
        margin-right: 5px;
      }

      p {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;
        color: #888888;
      }
    }
  }

  .params-body {
    .form {
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
      gap: 0;
      max-width: 1440px;

      ::v-deep .form-group {
        min-width: 150px;
        margin-bottom: 0;
      }

      .col-lg-4 {
        margin-bottom: 16px !important;
      }

      // @media (max-width: 1200px) {}
    }

    .btn-extra-param {
      border-bottom: 1px solid #313131;
      display: inline-block;

      .isShow {
        display: none;
      }

      &.not-collapsed {
        .isHide {
          display: none;
        }

        .isShow {
          display: inline;
        }
      }
    }

    @media screen and (max-width: 1290px) and (min-width: 1200px) {
      .col-xl-2 {
        flex: 0 0 33.33333333%;
        max-width: 33.33333333%;
      }
    }
  }

  // .form-control {
  //   height: 32px !important;
  // }
  .elm-calendar input {
    height: 32px;
  }

  .supplier_text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .supplier_tooltip {
    &__name {
      margin: 0;
      display: flex;
      text-align: initial !important;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #ffffff;
    }

    &__inn-kpp {
      display: flex;

      p {
        margin: 0;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;
        color: #bcbcbc;
      }

      &__inn {
        margin-right: 12px !important;
      }
    }
  }
</style>
