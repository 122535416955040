var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "edo-tab-list"
  }, _vm._l(_vm.tabs, function (tab, index) {
    return _c('div', {
      key: index,
      staticClass: "edo-tab",
      class: {
        selected: _vm.tab_source === tab.source
      },
      on: {
        "click": function ($event) {
          return _vm.setTabSource(tab.source);
        }
      }
    }, [_c('ext-system-logo', {
      staticClass: "system-logo",
      attrs: {
        "ext_system": {
          type: tab.source
        },
        "fill": _vm.tab_source !== tab.source ? '#CACACA' : '#FFFFFF'
      }
    }), _vm._v(" " + _vm._s(_vm.tab_mapping[tab.source]) + " "), tab.ext_head ? _c('div', {
      staticClass: "ext-head"
    }, [_c('div', {
      staticClass: "delimiter"
    }), _vm._v(" " + _vm._s(tab.ext_head) + " ")]) : _vm._e(), tab.count ? _c('div', {
      staticClass: "counter"
    }, [_vm._v(" " + _vm._s(tab.count) + " ")]) : _vm._e()], 1);
  }), 0);

}
var staticRenderFns = []

export { render, staticRenderFns }