<template>
  <b-tr class="b-handler-product">
    <b-td colspan="2" />
    <!-- Имя товара -->
    <b-td class="text-left">
      <div class="b-supplier">
        {{ created_spec?.product?.name }}
      </div>
      <div class="b-name article">
        {{ created_spec?.product?.articul ?? '------' }}
      </div>
    </b-td>
    <!-- Общее количество -->
    <b-td>
      <div class="count">
        <b-form-input
          id="total_amount"
          ref="delta"
          v-model="created_spec.delta"
          :is-keyup="true"
          type="number"
          class="text-center"
          @keyup.enter="handlerTotalAmount"
        />
      </div>
    </b-td>
    <!-- Еденица измерения -->
    <b-td class="center">
      {{ created_spec?.product?.measurement?.name ?? '–' }}
    </b-td>
    <!-- Закупочная цена -->
    <b-td>
      <div class="count">
        <b-form-input
          id="purchase_price"
          ref="purchase"
          v-model="created_spec.price"
          :is-keyup="true"
          type="number"
          class="text-center"
          @keyup.enter="handlerPurchasePrice"
        />
      </div>
    </b-td>
    <!-- Сумма по позиции -->
    <b-td>
      <div class="count">
        <b-form-input
          id="summ_position"
          ref="summPosition"
          v-model="created_spec.sum"
          :is-keyup="true"
          type="number"
          class="text-center"
          @keyup.enter="handlerSummPossition"
          @focus="$event.target.select()"
        />
      </div>
    </b-td>
    <!-- Наценка % -->
    <b-td>
      <div class="count">
        <b-form-input
          id="markup"
          ref="markup"
          v-model="markupInput"
          :is-keyup="true"
          type="number"
          class="text-center"
          @keyup.enter="handlerMarkup"
        />
      </div>
    </b-td>
    <!-- Розница новая -->
    <b-td>
      <div class="count">
        <b-form-input
          id="retail_price_new"
          ref="retailPriceNew"
          v-model="created_spec.retailPrice"
          :is-keyup="true"
          type="number"
          class="text-center"
          @keyup.enter="handlerRetailPriceNew"
        />
      </div>
    </b-td>
    <!-- Розница текущая -->
    <b-td>{{ formatPrice(created_spec.product?.retailPrice) }} ₽</b-td>
    <!-- Ставка НДС -->
    <b-td>
      <i-select v-model="created_spec.product.taxRate.id">
        <i-option
          v-for="item in taxRateList"
          :key="item.id"
          :value="item.id"
        >
          {{ item.name }}
        </i-option>
      </i-select>
    </b-td>
    <!-- Сумма НДС (₽) -->
    <b-td>{{ created_spec.taxRateSum }} ₽</b-td>
    <!-- Остаток -->
    <b-td>
      <div class="d-flex align-items-center justify-content-center">
        {{ calcRemainders(created_spec.product.remainders) }}
      </div>
    </b-td>
    <b-td />
  </b-tr>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { IncomingSpecificationModel } from '@/models/incoming-spec.model'
  import { TaxRateModel } from '@/models/tax-rate.model'

  export default {
    apollo: {
      TaxRates: {
        query: require('../../gql/getTaxRates.gql'),
        result({ data }) {
          this.taxRateList = data.TaxRates?.map((el) => new TaxRateModel(el)) ?? []
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      }
    },

    props: {
      spec: {
        type: IncomingSpecificationModel,
        default: () => new IncomingSpecificationModel()
      }
    },

    data: function () {
      return {
        created_spec: new IncomingSpecificationModel(),
        taxRateList: [],
        markupInput: 0
      }
    },

    mounted() {
      document.addEventListener('keydown', (event) => {
        if (event.target.localName === 'input') return
        if ((event.ctrlKey && event.key === 'Enter') || (event.metaKey && event.key === 'Enter')) {
          if (this.created_spec.delta && this.created_spec.price && this.created_spec.retailPrice) this.addItem()
        }
        if (
          (event.ctrlKey || event.metaKey) &&
          (event.key === 'z' || event.key === 'Z' || event.key === 'я' || event.key === 'Я' || event.keyCode === 90)
        ) {
          this.$emit('remove_prod')
          return
        }
      })
      this.created_spec = this.spec
      this.markupInput = this.created_spec.product.markup

      this.$nextTick(() => this.$refs.delta.select())
    },

    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch'
      })
    },

    watch: {},

    methods: {
      formatPrice(summ = 0) {
        const val = summ === null ? 0 : summ
        const formatter = new Intl.NumberFormat('ru-RU', {
          style: 'decimal',
          currency: 'RUB',
          maximumFractionDigits: 2,
          minimumFractionDigits: 2
          // These options are needed to round to whole numbers if that's what you want.
          //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
          // maximumFractionDigits: 0 // (causes 2500.99 to be printed as $2,501)
        })

        return formatter.format(val)
      },

      handlerTotalAmount(e) {
        if (e.ctrlKey || e.metaKey) return
        this.created_spec.setDelta(this.created_spec.delta)
        if (this.created_spec.product.purchasePrice) {
          this.created_spec.setPrice(this.created_spec.product.purchasePrice)
          if (this.markupInput) this.created_spec.setMarkup(+this.markupInput)
          else if (!this.markupInput && this.created_spec.product.retailPrice) {
            this.created_spec.setRetailPrice(this.created_spec.product.retailPrice)
            this.markupInput = this.created_spec.markup.toFixed(2)
          }
        }
        this.addItem()
        // if (
        //   this.created_spec.delta &&
        //   this.created_spec.price &&
        //   this.created_spec.retailPrice
        // )
        //
        // else
        //   this.$nextTick(() => {
        //     this.$refs.purchase.select()
        //     this.$emit('on-focus', {
        //       offset:
        //         this.$refs.purchase.$el.parentNode.offsetLeft +
        //         this.$refs.purchase.$el.parentNode.clientWidth / 2
        //     })
        //   })
      },

      handlerSummPossition(e) {
        if (e.ctrlKey || e.metaKey) return
        this.created_spec.setSum(this.created_spec.sum)
        this.markupInput = this.created_spec.markup

        this.$nextTick(() => {
          this.$refs.markup.select()
          this.$emit('on-focus', {
            offset: this.$refs.markup.$el.parentNode.offsetLeft + this.$refs.markup.$el.parentNode.clientWidth / 2
          })
        })
      },

      handlerPurchasePrice(e) {
        if (e.ctrlKey || e.metaKey) return
        this.created_spec.setPrice(this.created_spec.price)
        if (this.markupInput) this.created_spec.setMarkup(+this.markupInput)
        else if (!this.markupInput && this.created_spec.product.retailPrice) {
          this.created_spec.setRetailPrice(this.created_spec.product.retailPrice)
          this.markupInput = this.created_spec.markup.toFixed(2)
        }

        this.$nextTick(() => {
          this.$refs.summPosition.select()
          this.$emit('on-focus', {
            offset:
              this.$refs.summPosition.$el.parentNode.offsetLeft + this.$refs.summPosition.$el.parentNode.clientWidth / 2
          })
        })
      },

      handlerMarkup(e) {
        if (e.ctrlKey || e.metaKey) return
        this.created_spec.setMarkup(this.markupInput, 'rubles')

        this.$nextTick(() => {
          this.$refs.retailPriceNew.select()
          this.$emit('on-focus', {
            offset:
              this.$refs.retailPriceNew.$el.parentNode.offsetLeft +
              this.$refs.retailPriceNew.$el.parentNode.clientWidth / 2
          })
        })
      },

      handlerRetailPriceNew(e) {
        if (e.ctrlKey || e.metaKey) return
        this.created_spec.setRetailPrice(this.created_spec.retailPrice)

        this.$nextTick(() => this.addItem())
      },

      calcRemainders(item) {
        return (
          item?.filter((el) => el?.storage?.id === this.current_storage_id)?.reduce((sum, el) => sum + el.value, 0) || 0
        )
      },

      addItem() {
        this.$nextTick(() => this.$emit('add_product', this.created_spec.input))
      },

      removeItem() {
        this.$emit('remove_prod')
      },

      listenerClick() {
        document.getElementById('overhead-document').addEventListener('click', (e) => {
          const target = e.target.classList.value

          if (
            target === 'cursor' ||
            target === 'count' ||
            target === 'ivu-select-placeholder' ||
            target === 'ivu-icon ivu-icon-ios-arrow-down ivu-select-arrow' ||
            target === 'item-info' ||
            target === 'form-control'
          )
            return true
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  ::v-deep() {
    .b-handler-product {
      .ivu-select-selection {
        border: none;
      }

      .btns-action {
        padding: 12px;
        background: #ffffff;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        //position: absolute;
        //top: 4px;
        //right: 0px;
        margin-top: 4px;
        button {
          padding: 10px;
          background: #efefef;
          border-radius: 2px;

          img {
            margin: 0;
          }

          &:last-child {
            margin-left: 12px;
          }
        }
      }

      input {
        padding-right: 35px;
        background: #fff url('/img/icons/icon_enter.svg') calc(100% - 5px) center no-repeat;

        &:focus {
          & ~ .b-tooltip {
            display: block;
          }
        }
      }
    }
  }

  #overhead-document {
    .b-handler-product {
      background: #e6f0ff;
      min-height: 80px;

      .b-name {
        &.article {
          color: #888888;
        }
      }

      // .ivu-select-selection {
      //   border: none;
      // }

      .btns-action {
        padding: 12px;
        background: #ffffff;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        //position: absolute;
        //top: 4px;
        //right: 0px;
        margin-top: 4px;
        button {
          padding: 10px;
          background: #efefef;
          border-radius: 2px;

          img {
            margin: 0;
          }

          &:last-child {
            margin-left: 12px;
          }
        }
      }

      &:hover {
        background: #e6f0ff !important;
      }

      input {
        padding-right: 35px;
        background: #fff url('/img/icons/icon_enter.svg') calc(100% - 5px) center no-repeat;

        &:focus {
          & ~ .b-tooltip {
            display: block;
          }
        }
      }
    }
  }
</style>
