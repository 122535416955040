var render = function render(){
  var _vm$filter_params$edo, _vm$operation_list$fi, _vm$filter_params$sup, _vm$filter_params$sup2, _vm$filter_params$sup3, _vm$filter_params$sup4, _vm$filter_params$sup5, _vm$filter_params$sup6, _vm$filter_params$sup7, _vm$filter_params$sup8, _vm$convertDate;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "params-body",
    staticStyle: {
      "padding-top": "16px"
    }
  }, [_c('div', {
    staticClass: "card pb-0 pr-0 mb-0"
  }, [_c('b-form', {
    staticClass: "form pr-0"
  }, [_c('b-row', {
    staticClass: "w-100 align-items-end"
  }, [_c('b-col', {
    staticClass: "pr-0",
    attrs: {
      "xl": "2",
      "lg": "4",
      "md": "4",
      "sm": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Дата",
      "label-for": "work_period"
    }
  }, [_vm.fixed ? [_vm._v(" " + _vm._s(_vm.convertDate(_vm.filter_params.date)) + " ")] : [_c('date-picker', {
    staticClass: "elm-calendar",
    attrs: {
      "id": "work_period",
      "disabled": ((_vm$filter_params$edo = _vm.filter_params.edo) === null || _vm$filter_params$edo === void 0 ? void 0 : _vm$filter_params$edo.type) === 'crptech',
      "clearable": false,
      "placeholder": "Выберите дату",
      "value": _vm.filter_params.date,
      "format": "dd.MM.yyyy",
      "transfer": ""
    },
    on: {
      "on-change": _vm.setDate
    }
  })]], 2)], 1), _c('b-col', {
    staticClass: "pr-0",
    attrs: {
      "xl": "2",
      "lg": "4",
      "md": "4",
      "sm": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Операции по документу",
      "label-for": "doc_operations"
    }
  }, [_vm.fixed ? [_vm._v(" " + _vm._s((_vm$operation_list$fi = _vm.operation_list.find(function (el) {
    var _vm$filter_params, _vm$filter_params$ope;

    return (el === null || el === void 0 ? void 0 : el.id) === ((_vm$filter_params = _vm.filter_params) === null || _vm$filter_params === void 0 ? void 0 : (_vm$filter_params$ope = _vm$filter_params.operation) === null || _vm$filter_params$ope === void 0 ? void 0 : _vm$filter_params$ope.name);
  })) === null || _vm$operation_list$fi === void 0 ? void 0 : _vm$operation_list$fi.name) + " ")] : [_c('i-select', {
    attrs: {
      "id": "doc_operations",
      "value": _vm.filter_params.operation.type,
      "disabled": _vm.fixed,
      "transfer": ""
    },
    on: {
      "on-select": _vm.setOperation
    }
  }, _vm._l(_vm.operation_list, function (item) {
    return _c('i-option', {
      key: item.id,
      attrs: {
        "value": item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)]], 2)], 1), _c('b-col', {
    staticClass: "pr-0",
    attrs: {
      "xl": "2",
      "lg": "4",
      "md": "4",
      "sm": "6"
    }
  }, [_c('b-form-group', {
    class: {
      error: _vm.is_supplier_error
    },
    attrs: {
      "label": "Поставщик",
      "label-for": "supplier",
      "disabled": _vm.disabled
    }
  }, [_vm.fixed ? _c('b-tooltip', {
    attrs: {
      "target": "supplier__text",
      "placement": "top",
      "variant": "dark"
    }
  }, [_c('div', {
    staticClass: "supplier_tooltip"
  }, [_c('p', {
    staticClass: "supplier_tooltip__name"
  }, [_vm._v(_vm._s((_vm$filter_params$sup = _vm.filter_params.supplier) === null || _vm$filter_params$sup === void 0 ? void 0 : _vm$filter_params$sup.name))]), _c('div', {
    staticClass: "supplier_tooltip__inn-kpp"
  }, [_c('p', {
    staticClass: "supplier_tooltip__inn-kpp__inn"
  }, [_vm._v("ИНН: " + _vm._s((_vm$filter_params$sup2 = (_vm$filter_params$sup3 = _vm.filter_params.supplier) === null || _vm$filter_params$sup3 === void 0 ? void 0 : (_vm$filter_params$sup4 = _vm$filter_params$sup3.entity) === null || _vm$filter_params$sup4 === void 0 ? void 0 : _vm$filter_params$sup4.inn) !== null && _vm$filter_params$sup2 !== void 0 ? _vm$filter_params$sup2 : '--'))]), _c('p', {
    staticClass: "supplier_tooltip__inn-kpp__kpp"
  }, [_vm._v("КПП :" + _vm._s((_vm$filter_params$sup5 = (_vm$filter_params$sup6 = _vm.filter_params.supplier) === null || _vm$filter_params$sup6 === void 0 ? void 0 : (_vm$filter_params$sup7 = _vm$filter_params$sup6.entity) === null || _vm$filter_params$sup7 === void 0 ? void 0 : _vm$filter_params$sup7.kpp) !== null && _vm$filter_params$sup5 !== void 0 ? _vm$filter_params$sup5 : '--'))])])])]) : _vm._e(), _vm.fixed ? _c('div', {
    staticClass: "supplier_text",
    attrs: {
      "id": "supplier__text"
    }
  }, [_vm._v(" " + _vm._s(_vm.filter_params.supplier.name) + " ")]) : [_c('i-select', {
    attrs: {
      "id": "supplier",
      "value": _vm.filter_params.supplier.id,
      "filterable": "",
      "transfer": "",
      "disabled": _vm.disabled
    },
    on: {
      "on-change": _vm.setSupplier
    }
  }, _vm._l(_vm.supplier_list, function (item) {
    return _c('i-option', {
      key: item.id,
      attrs: {
        "value": item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)]], 2)], 1), _c('b-col', {
    staticClass: "pr-0",
    attrs: {
      "xl": "2",
      "lg": "4",
      "md": "4",
      "sm": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "№ Документа поставщика",
      "label-for": "supplier_doc_number"
    }
  }, [_vm.fixed ? [_vm._v(" " + _vm._s((_vm$filter_params$sup8 = _vm.filter_params.supplierDocument.id) !== null && _vm$filter_params$sup8 !== void 0 ? _vm$filter_params$sup8 : '--') + " ")] : [_c('b-form-input', {
    attrs: {
      "id": "supplier_doc_number",
      "type": "text",
      "value": _vm.filter_params.supplierDocument.id,
      "disabled": _vm.disabled,
      "is-keyup": true
    },
    on: {
      "input": _vm.setSupplierDocumentId
    }
  })]], 2)], 1), _c('b-col', {
    staticClass: "pr-0",
    attrs: {
      "xl": "2",
      "lg": "4",
      "md": "4",
      "sm": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Дата поставщика",
      "label-for": "supplier_data"
    }
  }, [_vm.fixed ? [_vm._v(" " + _vm._s((_vm$convertDate = _vm.convertDate(_vm.filter_params.supplierDocument.date)) !== null && _vm$convertDate !== void 0 ? _vm$convertDate : '--') + " ")] : [_c('date-picker', {
    staticClass: "elm-calendar",
    attrs: {
      "id": "supplier_data",
      "clearable": false,
      "placeholder": "Выберите дату",
      "value": _vm.filter_params.supplierDocument.date,
      "format": "dd.MM.yyyy",
      "is-keyup": true,
      "transfer": "",
      "disabled": _vm.disabled
    },
    on: {
      "on-change": _vm.setSupplierDate
    }
  })]], 2)], 1), _c('b-col', {
    staticClass: "pr-0",
    attrs: {
      "xl": "2",
      "lg": "4",
      "md": "4",
      "sm": "6"
    }
  }, [_c('b-form-group', {
    class: {
      error: _vm.is_storage_error
    },
    attrs: {
      "label": "Место хранения",
      "label-for": "storage"
    }
  }, [_vm.fixed ? [_vm._v(" " + _vm._s(_vm.filter_params.storage.name) + " ")] : [_c('i-select', {
    attrs: {
      "id": "storage",
      "value": _vm.filter_params.storage.id,
      "filterable": "",
      "transfer": ""
    },
    on: {
      "on-change": _vm.changeStorage
    }
  }, _vm._l(_vm.storage_list, function (item) {
    var _item$branch, _item$branch2;

    return _c('i-option', {
      key: item.id,
      attrs: {
        "value": item.id,
        "label": item.name
      }
    }, [_c('div', [_vm._v(_vm._s(item.name))]), (_item$branch = item.branch) !== null && _item$branch !== void 0 && _item$branch.name ? _c('div', {
      staticClass: "branch-info"
    }, [_vm._v(" " + _vm._s((_item$branch2 = item.branch) === null || _item$branch2 === void 0 ? void 0 : _item$branch2.name) + " ")]) : _vm._e()]);
  }), 1)]], 2)], 1), _c('b-col', {
    staticClass: "pr-0",
    attrs: {
      "xl": "2",
      "lg": "4",
      "md": "4",
      "sm": "6"
    }
  }, [_c('b-form-group', {
    class: {
      error: _vm.is_entity_error
    },
    attrs: {
      "label": "Организация",
      "label-for": "entity"
    }
  }, [_vm.fixed ? [_vm._v(" " + _vm._s(_vm.filter_params.entity.name) + " ")] : _c('e-select', {
    attrs: {
      "error": _vm.is_entity_error,
      "selected_type": "text",
      "options": _vm.entity_list,
      "select_name": "entity_select"
    },
    scopedSlots: _vm._u([{
      key: "custom_name",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('div', {
          staticClass: "entity"
        }, [_c('p', {
          staticClass: "name"
        }, [_vm._v(_vm._s(item.name))]), _c('div', {
          staticClass: "inn_kpp"
        }, [_c('p', {
          staticClass: "inn"
        }, [_vm._v("ИНН: " + _vm._s(item.inn ? item.inn : '-'))]), _c('p', {
          staticClass: "kpp"
        }, [_vm._v("КПП: " + _vm._s(item.kpp ? item.kpp : '-'))])])])];
      }
    }]),
    model: {
      value: _vm.active_entity,
      callback: function ($$v) {
        _vm.active_entity = $$v;
      },
      expression: "active_entity"
    }
  })], 2)], 1)], 1), _c('b-row', {
    staticClass: "w-100"
  }, [_c('b-col', [_c('div', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.collapse-extraparams",
      modifiers: {
        "collapse-extraparams": true
      }
    }],
    staticClass: "btn-extra-param cursor mb-3"
  }, [_c('span', {
    staticClass: "isHide"
  }, [_vm._v("Показать")]), _c('span', {
    staticClass: "isShow"
  }, [_vm._v("Скрыть")]), _vm._v(" дополнительные параметры ")]), _c('b-collapse', {
    staticClass: "row mb-3",
    attrs: {
      "id": "collapse-extraparams"
    }
  }, [_c('b-col', {
    staticClass: "pr-0",
    attrs: {
      "col": "",
      "xl": "4",
      "lg": "4",
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Комментарий",
      "label-for": "comment"
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "id": "comment",
      "value": _vm.filter_params.comment,
      "rows": "3",
      "max-rows": "6",
      "is-keyup": true,
      "disabled": _vm.fixed
    },
    on: {
      "input": _vm.setComment
    }
  })], 1)], 1)], 1)], 1)], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }