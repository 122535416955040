<template>
  <section id="incoming-document-spec">
    <!-- <breadcrumbs :extra="incomingDocument" /> -->
    <discrepancy-modal
      :document="incomingDocument"
      @makeUsed="makeUsed"
    />
    <incoming-head
      :document="incomingDocument"
      :entity_list="entity_list"
      @refetch="updatePrice"
      @update-data="updateData"
      @onChange="updateHead"
      @makeUsed="makeUsed"
    />
    <template v-if="['mercury', 'crptech', 'egais', 'order', 'sbis', 'manual'].includes(incomingDocument.edo?.type)">
      <edo-spec-table
        :document="incomingDocument"
        :items="items"
        :busy="tableIsBusy"
        :tab_source="tab_source"
        @scroll-down="scrollDown"
        @scroll-up="scrollUp"
        @scroll-change="scrollChange"
        @get-last="getLast"
        @update-data="updateData"
        @refetch-total="refetchTotal"
        @printPrice="printPrice"
        @sort_change="sortTable"
        @update-total="updateTotal"
        @search="search"
        @setTabSource="setTabSource"
      />
    </template>
    <template v-else>
      <incoming-spec-table
        :document="incomingDocument"
        :items="items"
        :busy="tableIsBusy"
        @sort_change="sortTable"
        @refetch="() => this.$apollo.queries.IncomingHead.refetch()"
        @scroll-down="scrollDown"
        @scroll-up="scrollUp"
        @scroll-change="scrollChange"
        @get-last="getLast"
        @update-data="updateData"
        @update-total="updateTotal"
        @printPrice="printPrice"
        @search="search"
      />
    </template>

    <modal-print-price-tags
      modal_id="global_modal"
      :products="printProducts"
    />
  </section>
</template>

<script>
  import IncomingHead from '@/views/operational-processes/components/incoming/IncomingHead'
  import IncomingSpecTable from '@/views/operational-processes/components/incoming/IncomingSpecTable'
  import { IncomingHeadModel } from '@/views/operational-processes/models/incoming-head.model'
  import ModalPrintPriceTags from '@/views/core/modal/ModalPrintPriceTags'
  import { ProductModel } from '@/models/product.model'
  import EdoSpecTable from '@/views/operational-processes/components/incoming/EdoSpecTable'
  import DiscrepancyModal from '@/views/operational-processes/components/incoming/DiscrepancyModal'
  import { getCert, owner_info, createDetached, getNewToken } from '@/utils/crptech/crptech_token'
  import { mapActions, mapGetters } from 'vuex'
  import { EventBus } from '@/main'

  export default {
    name: 'Specifications',
    components: {
      EdoSpecTable,
      IncomingSpecTable,
      IncomingHead,
      DiscrepancyModal,
      ModalPrintPriceTags
    },
    beforeRouteEnter(to, from, next) {
      next((vm) => {
        vm.prevRoute = from?.name
      })
    },
    apollo: {
      Branch: {
        query: require('../../gql/Branch.gql'),
        fetchPolicy: 'no-cache',
        variables() {
          return {
            id: this.current_branch.id
          }
        },
        result({ data }) {
          this.entity_list = data.Branch?.entities || []
          if (this.entity_list?.length === 1 && !this.incomingDocument.entity?.id) {
            this.incomingDocument.setEntity(this.entity_list[0])
          }
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      }
    },
    data() {
      return {
        forwardDirection: false,
        first: true,
        loading: false,
        printProducts: [],
        entity_list: [],
        modalOpened: false,
        timer: null,
        timer_update: null,
        prevRoute: null,
        notLoad: false,
        tableIsBusy: false,
        tab_source: 'main',
        old_head: {}
      }
    },
    watch: {
      first() {
        this.$nextTick(() => {
          const table = document.querySelector('.table-docs')

          if (table.scrollHeight === table.clientHeight) {
            this.scrollDown()
          }
        })
      },
      '$route.query.source'() {
        this.setTabSource(this.$route.query.source)
      }
    },

    created() {
      EventBus.$on('refetchIncomingHead', () => {
        this.refetchTotal()
      })
    },

    beforeMount() {
      this.setBreadcrumbs({ is_go_back: true })
    },
    async mounted() {
      if (this.incomingDocument.id) {
        await this.setBreadcrumbs({ ...this.incomingDocument, is_go_back: true })
      }
      if (this.prevRoute !== 'products.edit') {
        await this.setSpecs([])
        await this.setDocument(new IncomingHeadModel())
        await this.setPagination({
          prev_cursor: '',
          next_cursor: '',
          search: '',
          order: [{ key: 'order', value: 'ASC' }]
        })
        await this.setCursor({ next: '', prev: '', height: 0 })
        this.tableIsBusy = true
        this.first = true
        await this.fetch()
        this.showNotify(this.incomingDocument?.treaty)
        this.old_head = this.incomingDocument.input
      } else {
        this.first = false
        this.$nextTick(() => {
          document.querySelector('.table-docs').scrollTo({ top: this.cursor.height })
        })
      }
    },
    beforeDestroy() {
      this.setBreadcrumbs({})
    },
    destroyed() {
      this.setBreadcrumbs({})
      EventBus.$off('refetchIncomingHead', () => this.refetchTotal())
    },
    methods: {
      ...mapActions({
        setBreadcrumbs: 'breadcrumbs/set_current',
        setPagination: 'operprocess/setIncomingPagination',
        setDocument: 'operprocess/setIncomingDocument',
        setSpecs: 'operprocess/setIncomingSpecifications',
        setCursor: 'operprocess/setIncomingCursor'
      }),
      showNotify(treaty) {
        if (
          this.incomingDocument?.operation?.type === 'commission' &&
          this.incomingDocument?.supplier?.id &&
          !treaty?.id
        ) {
          EventBus.$emit('notify', {
            variant: 'warning',
            title: 'Создайте договор комиcсии',
            message: 'Система будет автоматически подставлять правила и не допускать ошибки в учете',
            card: {
              message: 'Контрагент: ' + this.incomingDocument.supplier?.name
            },

            unique: 'incoming-spec',
            actions: [
              {
                action: async () => {
                  this.$router.push({
                    name: 'contractos.edit',
                    params: { id: this.incomingDocument?.supplier?.id },
                    query: { source: 'contract' }
                  })
                },
                title: 'Создать',
                info: 'sdfsd'
              }
            ]
          })
        }
      },
      async fetch(source) {
        console.log('fetch', source)
        this.loading = true
        if (!this.tableIsBusy && source !== 'updateData')
          await this.setSpecs(this.up ? [{ id: 'loading' }, ...this.items] : [...this.items, { id: 'loading' }])
        if (this.tab_source === 'main') {
          const { data } = await this.$apollo.query({
            query: require('../../gql/getIncomingHead.gql'),
            fetchPolicy: 'no-cache',
            variables: {
              id: this.$route.query.id ? this.$route.query.id : this.$route.params.id,
              pagination: this.pagination
            }
          })
          await this.getHead(data)
        } else {
          const { data } = await this.$apollo.query({
            query: require('../../gql/IncTabSpecs.graphql'),
            fetchPolicy: 'no-cache',
            variables: {
              input: {
                id: this.incomingDocument.id,
                branch: this.current_branch.id,
                documentPagination: {
                  ...this.pagination,
                  source: this.tab_source
                }
              }
            }
          })
          await this.getHead({
            IncomingHead: {
              ...this.incomingDocument,
              paginationSpecifications: data.IncTabSpecs
            }
          })
        }
        await this.setSpecs(this.items.filter((el) => el.id !== 'loading'))
      },
      async refetchTotal() {
        this.loading = true
        const { data } = await this.$apollo.query({
          query: require('../../gql/getIncomingHead.gql'),
          fetchPolicy: 'no-cache',
          notifyOnNetworkStatusChange: true,
          variables: {
            id: this.$route.query.id ? this.$route.query.id : this.$route.params.id,
            pagination: this.pagination
          }
        })
        this.incomingDocument.paginationSpecifications.setTotal({
          avgMarkup: data.IncomingHead.paginationSpecifications?.avgMarkup,
          factTotal: data.IncomingHead.paginationSpecifications?.factTotal,
          requestTotal: data.IncomingHead.paginationSpecifications?.requestTotal,
          sumTotal: data.IncomingHead.paginationSpecifications?.sumTotal,
          taxRateSum: data.IncomingHead.paginationSpecifications?.taxRateSum,
          total: data.IncomingHead.paginationSpecifications?.total
        })
        this.incomingDocument.retail_sum = data?.IncomingHead?.retail_sum || 0
        this.loading = false
      },
      scrollChange(value) {
        this.setCursor({ height: value })
      },
      async updatePrice() {},
      async sortTable(val) {
        this.tableIsBusy = true
        this.first = true
        this.loading = true
        await this.setPagination({
          order: [{ key: val.sortBy, value: val.sortDesc ? 'DESC' : 'ASC' }],
          next_cursor: '',
          prev_cursor: ''
        })
        this.fetch('sortTable')
        // await this.$apollo.queries.IncomingHead.refetch()
      },
      async getHead(data) {
        if (!data) return
        this.setBreadcrumbs({ ...data?.IncomingHead, is_go_back: true })
        this.setDocument(new IncomingHeadModel(data?.IncomingHead))
        if (this.incomingDocument?.entity?.id) {
          this.incomingDocument.setEntity(this.incomingDocument?.entity)
        } else if (this.entity_list.length === 1) {
          this.incomingDocument.setEntity(this.entity_list[0])
        }
        if (this.first) {
          await this.setSpecs(this.incomingDocument.paginationSpecifications.specification || [])
          await this.setCursor({
            prev: data.IncomingHead.paginationSpecifications.prev_cursor,
            next: data.IncomingHead.paginationSpecifications.next_cursor
          })
        } else {
          if (this.up) {
            const initialHeight = document.querySelector('.table-docs').scrollHeight
            await this.setSpecs([
              ...(this.incomingDocument.paginationSpecifications.specification || []),
              ...this.items
            ])
            await this.setCursor({
              prev: data.IncomingHead.paginationSpecifications.prev_cursor
            })
            this.$nextTick(() => {
              document.querySelector('.table-docs').scrollTop =
                document.querySelector('.table-docs').scrollHeight - initialHeight - 70
            })
          } else {
            await this.setSpecs([...this.items, ...this.incomingDocument.paginationSpecifications.specification])
            await this.setCursor({
              next: data.IncomingHead.paginationSpecifications.next_cursor
            })
          }
        }
        this.first = false
        this.loading = false
        this.tableIsBusy = false
      },
      async printPrice(value) {
        const { data } = await this.$apollo.query({
          query: require('../../gql/ReadPriceTagsFromDocument.graphql'),
          fetchPolicy: 'no-cache',
          variables: {
            input: value
              ? {
                  document_head: value.document_head,
                  branch: value.branch,
                  product: value.product,
                  isPrintAll: value.isPrintAll
                }
              : {
                  document_head: this.incomingDocument.id,
                  branch: this.incomingDocument.branch.id,
                  isPrintAll: true,
                  fix: true
                }
          }
        })
        this.printProducts = data?.ReadPriceTagsFromDocument?.map(
          (el) =>
            new ProductModel({
              ...el.Product,
              retailPrice: el?.value ?? el?.Product?.retailPrice,
              printDelta: value?.delta?.find((obj) => obj.id === el.Product.id)?.delta || 1
            })
        )
        if (this.printProducts?.length > 0) this.$bvModal.show('modal-print-tags')
      },
      updateHead(isUpdate) {
        if (!this.incomingDocument.input?.id || this.incomingDocument.fixed === true) return
        clearTimeout(this.timer_update)

        this.timer_update = setTimeout(async () => {
          const { data } = await this.$apollo.mutate({
            mutation: require('../../gql/UpdateIncomingDocument.gql'),
            variables: this.incomingDocument.input
          })
          if (
            this.old_head.supplier !== this.incomingDocument?.input?.supplier ||
            this.old_head.operation !== this.incomingDocument?.input?.operation
          )
            this.showNotify(data?.UpdateIncomingDocument?.treaty)
          this.old_head = this.incomingDocument.input
          if (isUpdate) this.updateData()
        }, 300)
      },
      async updateTotal() {
        const { data } = await this.$apollo.query({
          query: require('../../gql/getIncomingHeadTotal.gql'),
          fetchPolicy: 'no-cache',
          variables: {
            id: this.incomingDocument.id
          }
        })
        this.incomingDocument.retail_sum = data?.IncomingHead?.retail_sum || 0
        this.incomingDocument.edo = data?.IncomingHead?.edo
        this.incomingDocument.paginationSpecifications.setTotal(data?.IncomingHead?.paginationSpecifications)
      },
      async updateData() {
        this.first = true
        await this.setCursor({ next: '', prev: '' })
        await this.setPagination({
          next_cursor: '',
          prev_cursor: ''
        })
        this.loading = true
        await this.fetch('updateData')
      },
      async scrollDown() {
        if (!this.cursor.next || this.loading) return
        this.up = false
        this.loading = true
        await this.setPagination({ next_cursor: this.cursor.next })
        await this.fetch('scrollDown')
      },
      async scrollUp() {
        if (!this.cursor.prev || this.loading) return
        this.up = true
        this.loading = true
        await this.setPagination({
          prev_cursor: this.cursor.prev,
          next_cursor: ''
        })
        await this.fetch('scrollUp')
      },
      async getLast(cb, cursor) {
        this.first = true
        this.loading = true
        await this.setPagination({
          prev_cursor: '',
          next_cursor: cursor,
          order: [{ key: 'order', value: 'ASC' }]
        })
        await this.fetch('getLast')
        if (cb) cb()
      },
      async fetchHead(pagination) {
        const { data } = await this.$apollo.query({
          query: require('../../gql/getIncomingHead.gql'),
          fetchPolicy: 'no-cache',
          variables: {
            id: this.$route.query.id ? this.$route.query.id : this.$route.params.id,
            pagination: pagination
          }
        })
        this.getHead(data)
      },
      async makeUsed(status, cb) {
        if (
          ['crptech', '-sbis', 'egais'].includes(this.incomingDocument.edo?.type) &&
          this.incomingDocument.edo.action !== 'make_used' &&
          !this.incomingDocument.fixed &&
          !this.modalOpened
        ) {
          this.$bvModal.show('disperancy-modal')
          this.modalOpened = true
        } else {
          try {
            await this.$apollo.mutate({
              mutation: require('../../gql/ChangeIncomingStatus.gql'),
              variables: {
                id: this.incomingDocument.id,
                fixed: status
              }
            })
            this.incomingDocument.fixed = status
            if (status) await this.printPrice()
            this.modalOpened = false
            if (
              (this.incomingDocument.edo?.type === 'crptech' || this.incomingDocument.edo?.type === '-sbis') &&
              !this.incomingDocument.fixed
            ) {
              await getNewToken(this.current_branch.id)
              let cert = await getCert(this.incomingDocument.edo?.thumbprint)

              const owner = await owner_info(cert)

              const { data } = await this.$apollo.query({
                query: require('../../gql/crptech/SendEdoEvent.graphql'),
                variables: {
                  input: {
                    id: this.incomingDocument.id,
                    inn: owner.inn,
                    position: owner.position,
                    comment: this.incomingDocument.comment ?? null,
                    full_name: owner.full_name,
                    token: JSON.parse(sessionStorage.getItem(this.incomingDocument.branch.id))[
                      this.incomingDocument.edo.thumbprint
                    ]
                  }
                }
              })

              if (data?.SendEdoEvent?.data) {
                let utf_string = data.SendEdoEvent.data
                const iconv = require('iconv-lite')

                const buff = iconv.encode(utf_string, 'win1251')

                const sgn = await createDetached(cert, buff)
                await this.$apollo.mutate({
                  mutation: require('../../gql/crptech/SignXMLDoc.graphql'),
                  fetchPolicy: 'no-cache',
                  variables: {
                    input: {
                      id: this.incomingDocument.id,
                      data: data.SendEdoEvent.id,
                      doc_signature: sgn,
                      token: JSON.parse(sessionStorage.getItem(this.incomingDocument.branch.id))[
                        this.incomingDocument.edo.thumbprint
                      ]
                    }
                  }
                })
              }
            } else if (['mercury'].includes(this.incomingDocument.edo?.type)) {
              await this.$apollo.mutate({
                mutation: require('../../gql/crptech/SignXMLDoc.graphql'),
                variables: {
                  input: {
                    id: this.incomingDocument.id
                  }
                }
              })
            }

            if (cb) {
              setTimeout(() => cb(), 500)
            }
          } catch (e) {
            console.error(e)
            if (cb) cb()
          }
        }
      },
      async search(value) {
        await this.setPagination({ ...this.pagination, search: value })
        clearTimeout(this.timer)
        this.timer = setTimeout(() => this.updateData(), 500)
      },
      async setTabSource(source) {
        this.tab_source = source
        this.first = true
        this.tableIsBusy = true
        this.loading = true
        await this.setPagination({
          prev_cursor: '',
          next_cursor: ''
        })
        await this.fetch()
      }
    },
    computed: {
      ...mapGetters({
        current_branch: 'settings/getCurrentBranch',
        pagination: 'operprocess/getIncomingPagination',
        incomingDocument: 'operprocess/getIncomingDocument',
        items: 'operprocess/getIncomingSpecs',
        cursor: 'operprocess/getIncomingCursor'
      })
    }
  }
</script>

<style scoped lang="scss">
  ::v-deep() {
    #incoming-document-spec {
      display: flex;
      flex-direction: column;
      height: 100%;
      flex: 1;
      min-height: 1px;
    }

    .b-action-buttons {
      button {
        min-width: 87px;
        justify-content: center;
      }
    }

    .table-b-table-default + .white-bg {
      background-color: white !important;
      border-bottom: 1px solid #e1e1e1 !important;
    }

    table {
      tr {
        th {
          border-bottom: 1px solid #e1e1e1 !important;
          border-top: none;
        }
        td {
          border-bottom: 1px solid #e1e1e1 !important;
          border-left: none;
          border-right: none;
          border-bottom: 1px solid #e1e1e1 !important;
          overflow: visible !important;
        }
      }
    }

    .b-table {
      &__wrapper {
        flex: 1;
        display: flex;
        flex-direction: column;
        max-height: 100%;
        min-height: 1px;
      }

      &__content {
        flex-grow: 1;
        min-height: 1px;
        margin-top: 0;
        display: flex;
        flex-direction: column;
      }
    }

    .table-docs {
      margin-bottom: -1px;
      height: 100%;
      overflow-x: scroll;

      thead {
        position: sticky;
        top: -1px;
        background: #fff;
        z-index: 9;

        th {
          border-bottom: 1px solid #e1e1e1;
          border-right: 1px solid #e1e1e1;
          border-top: none;
        }
      }

      tr {
        th.b-checkbox {
          padding: 10px;

          .custom-checkbox {
            padding-left: 0;
            width: 16px;
            margin: 0 auto;

            .custom-control-label {
              &:before,
              &:after {
                left: -8px;
                box-shadow: none !important;
              }
            }
          }
        }
      }

      &.table-responsive {
        overflow-x: scroll;
      }

      &.b-table-sticky-header {
        max-height: none;
      }

      @media (max-height: 960px) {
        tr {
          td {
            &.td-name {
              padding: 5px 16px;

              .b-name.article {
                line-height: 17px;
                margin-top: 3px;
              }
            }
          }
        }
      }
    }

    .b-table__filter {
      .b-toggle-button {
        button {
          background: #e2e2e2;
          color: #313131;

          img {
            margin-right: 0;
            transition: all 0.2s;
            transform: rotate(180deg);
          }

          &.not-collapsed {
            img {
              transform: rotate(0);
            }
          }
        }
      }
    }

    .table-filter {
      button {
        background: #efefef;
        color: #313131;

        &:hover {
          background: #efefef;
          color: #313131;
        }
      }
    }

    .table-inner {
      max-width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      min-height: 1px;
      overflow-x: auto;

      .table-products {
        height: calc(100% - 58px);
        overflow-y: auto;
        overflow-x: hidden;
      }

      table {
        tr {
          td {
            padding: 10px 16px;
            vertical-align: middle;
            text-align: center;
          }

          th {
            padding: 8px 20px 8px !important;
          }
        }

        caption {
          padding: 0;

          td {
            border-top: none;
          }
        }
      }

      .b-table__navbar {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 60px;
      }
    }

    .b-search {
      &__wrapper {
        width: 100%;
        z-index: 10;
      }

      &-result {
        position: absolute;
        bottom: 45px;
        left: 0px;
        width: 330px;
        background: #ffffff;
        border: 1px solid #e1e1e1;
        box-shadow: 0px 0px 40px 7px rgba(0, 0, 0, 0.1);
        border-radius: 6px;
        padding: 10px 0;
        max-height: 460px;
        overflow-y: auto;
        z-index: 10000;

        .item {
          padding: 8px 14px;

          &-name {
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: #313131;
          }

          &-info {
            font-size: 12px;
            line-height: 24px;
            color: #888888;
            height: 24px;
            overflow: hidden;

            span {
              margin-right: 12px;
            }
          }

          &:hover {
            background: #efefef;
          }
        }
      }
    }

    .b-table__navbar {
      margin: -85px 15px 0;
    }

    .count {
      position: relative;
      display: inline-block;
      padding: 6px;

      .cursor {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          margin-left: 10px;
          opacity: 0;
        }
      }

      &:hover {
        img {
          opacity: 1;
        }
      }

      img {
        &.enter {
          position: absolute;
          top: 11px;
          right: 11px;
        }
      }
    }
  }

  #incoming-document-spec {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
    min-height: 1px;

    .table-docs {
      &.table-responsive {
        overflow-x: scroll;
      }

      &.b-table-sticky-header {
        max-height: none;
      }
    }

    .table-filter {
      button {
        &:hover {
          background: #efefef;
          color: #313131;
        }
      }
    }

    .b-name {
      &.article {
        font-size: 12px;
        line-height: 24px;
        color: #888888;
        margin-top: 5px;
      }
    }

    .b-search {
      &-result {
        .item {
          &:hover {
            background: #efefef;
          }
        }
      }
    }

    ::-webkit-scrollbar {
      -webkit-appearance: none;
      height: 5px;
      width: 5px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: rgba(0, 0, 0, 0.5);
      -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }
  }
</style>
