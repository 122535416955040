var render = function render(){
  var _vm$incomingDocument$,
      _this = this;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    attrs: {
      "id": "incoming-document-spec"
    }
  }, [_c('discrepancy-modal', {
    attrs: {
      "document": _vm.incomingDocument
    },
    on: {
      "makeUsed": _vm.makeUsed
    }
  }), _c('incoming-head', {
    attrs: {
      "document": _vm.incomingDocument,
      "entity_list": _vm.entity_list
    },
    on: {
      "refetch": _vm.updatePrice,
      "update-data": _vm.updateData,
      "onChange": _vm.updateHead,
      "makeUsed": _vm.makeUsed
    }
  }), ['mercury', 'crptech', 'egais', 'order', 'sbis', 'manual'].includes((_vm$incomingDocument$ = _vm.incomingDocument.edo) === null || _vm$incomingDocument$ === void 0 ? void 0 : _vm$incomingDocument$.type) ? [_c('edo-spec-table', {
    attrs: {
      "document": _vm.incomingDocument,
      "items": _vm.items,
      "busy": _vm.tableIsBusy,
      "tab_source": _vm.tab_source
    },
    on: {
      "scroll-down": _vm.scrollDown,
      "scroll-up": _vm.scrollUp,
      "scroll-change": _vm.scrollChange,
      "get-last": _vm.getLast,
      "update-data": _vm.updateData,
      "refetch-total": _vm.refetchTotal,
      "printPrice": _vm.printPrice,
      "sort_change": _vm.sortTable,
      "update-total": _vm.updateTotal,
      "search": _vm.search,
      "setTabSource": _vm.setTabSource
    }
  })] : [_c('incoming-spec-table', {
    attrs: {
      "document": _vm.incomingDocument,
      "items": _vm.items,
      "busy": _vm.tableIsBusy
    },
    on: {
      "sort_change": _vm.sortTable,
      "refetch": function () {
        return _this.$apollo.queries.IncomingHead.refetch();
      },
      "scroll-down": _vm.scrollDown,
      "scroll-up": _vm.scrollUp,
      "scroll-change": _vm.scrollChange,
      "get-last": _vm.getLast,
      "update-data": _vm.updateData,
      "update-total": _vm.updateTotal,
      "printPrice": _vm.printPrice,
      "search": _vm.search
    }
  })], _c('modal-print-price-tags', {
    attrs: {
      "modal_id": "global_modal",
      "products": _vm.printProducts
    }
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }