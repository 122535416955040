<template>
  <b-modal
    id="modal-spec-move"
    centered
  >
    <template #modal-header>
      <h5>Изменение порядка позиции</h5>
      <b-button
        class="close"
        @click="hideModal"
      >
        <img
          src="/img/icons/modal_close.svg"
          alt=""
        />
      </b-button>
    </template>
    <b-form @submit.stop.prevent="submitPriceUp">
      <div>
        <div class="item-row">
          <div>Переместить на позицию</div>
          <b-form-input
            id="spec-move"
            v-model="value"
            type="number"
          />
        </div>
      </div>
    </b-form>
    <template #modal-footer>
      <div class="d-flex">
        <b-button
          variant="primary"
          @click="submitPriceUp"
        >
          Сохранить
        </b-button>
        <b-button
          variant="outline-primary"
          @click="hideModal"
        >
          Отмена
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
  export default {
    data: () => ({
      value: 0,
      roundList: [
        {
          name: 'До рубля',
          id: 'rubles'
        },
        {
          name: 'До 50 коп.',
          id: 'half'
        },
        {
          name: 'Не округлять',
          id: 'none'
        }
      ]
    }),

    methods: {
      submitPriceUp() {
        this.$emit('spec_move', +this.value)
      },

      hideModal() {
        this.value = 0
        this.$bvModal.hide('modal-spec-move')
      }
    }
  }
</script>
<style scoped>
  .item-row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  #spec-move {
    margin-left: 8px;
    width: 48px;
  }
</style>
