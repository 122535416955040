var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "disperancy-modal",
      "centered": "",
      "size": "lg"
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function () {
        return [_c('h5', [_vm._v("Обнаружено расхождение")])];
      },
      proxy: true
    }, {
      key: "modal-footer",
      fn: function () {
        return [_c('div', {
          staticStyle: {
            "display": "flex"
          }
        }, [_c('b-button', {
          staticClass: "text-nowrap w-auto",
          attrs: {
            "variant": "primary",
            "disabled": _vm.document.comment.length <= 0
          },
          on: {
            "click": _vm.makeUsed
          }
        }, [_vm._v(" Провести с расхождением ")]), _c('b-button', {
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": _vm.hideModal
          }
        }, [_vm._v(" Назад ")])], 1)];
      },
      proxy: true
    }])
  }, [_vm._v(" В документе обнаружено расхождение между заявленным и фактическим количеством "), _c('b-form-textarea', {
    staticClass: "mt-3",
    attrs: {
      "placeholder": "Введите комменатрий",
      "value": _vm.document.comment
    },
    on: {
      "input": _vm.document.setComment
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }