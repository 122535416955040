<template>
  <div
    id="inc-table"
    class="b-table__wrapper"
  >
    <add-document-in-contract-modal
      :document="document"
      :selected="selected"
      :all_selected="isAllSelected"
    />
    <modal-costs-add @costs_add="totalUpdateCosts" />
    <modal-price-up
      :operation="document?.operation?.type"
      @price_up="totalUpdateMarkup"
    />
    <modal-spec-move @spec_move="specMove" />
    <ukd-modal :document="document" />
    <package-modal
      :ean="ean"
      @add_package="addPackage"
    />
    <marking-modal
      :document_head="document.id"
      :spec="selectedProduct"
      @update_fact="updateData"
    />
    <product-movement-modal :product="getProduct" />
    <div class="b-table__content mt-3">
      <div class="table-filter">
        <b-form-input
          type="text"
          class="filter-search"
          placeholder="Поиск"
          :is-keyup="true"
          @input="search"
        />
        <b-dropdown
          no-caret
          variant="none"
          class="ml-3 mr-3"
        >
          <template #button-content>
            <div class="btn-more">
              <img
                src="/img/icons/settings.svg"
                alt=""
              />
            </div>
          </template>
          <b-dropdown-item @click="openSortModal"> Изменить столбцы </b-dropdown-item>
        </b-dropdown>

        <edo-tabs :tabs="tabs" />
      </div>
      <div class="table-inner">
        <b-table-simple
          ref="incoming_table"
          :items="items"
          hover
          class="table-docs table-fixed"
          fixed
          sticky-header
          no-border-collapse
          table-class="incoming-table w-100"
        >
          <b-thead>
            <b-tr>
              <b-th
                v-for="(td, index) in fields.filter((el) => el.checked && el.key !== 'requestDelta')"
                :key="index"
                :class="td.class + ' field-column unselectable text-center'"
                :style="{
                  'min-width': td.width + 'px',
                  width: td.width + 'px',
                  ...getIcons(td.key)
                }"
                @click="sortChange(td.key)"
              >
                {{ td.label }}
                <img
                  v-if="td.key === 'hide'"
                  src="/img/icons/connection.svg"
                />
                <b-form-checkbox
                  v-if="td.key === 'id'"
                  v-model="isAllSelected"
                />

                <div
                  class="resize-field"
                  @mousedown="(el) => mouseDown(el, td)"
                >
                  &nbsp;
                </div>
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody class="incoming-table-body">
            <template v-if="busy">
              <b-tr>
                <td :colspan="fields.length">
                  <div class="text-center">
                    <b-spinner
                      variant="primary"
                      label="Загрузка..."
                    />
                    <div class="mt-2"><strong>Загрузка...</strong></div>
                  </div>
                </td>
              </b-tr>
            </template>
            <template v-else>
              <b-tr
                v-for="(tr, tr_index) in items"
                :key="tr_index"
              >
                <template v-if="tr.id !== 'loading'">
                  <b-td
                    v-for="(field, index) of fields.filter((el) => el.checked)"
                    :key="index"
                    style="overflow: hidden"
                    :style="{ position: 'relative' }"
                  >
                    <template v-if="field.key === 'id'">
                      <div
                        class="b-status"
                        :class="{ success: !tr.product.hide }"
                      ></div>
                      <b-form-checkbox
                        v-model="selected"
                        class="cursor"
                        :value="tr.id"
                      />
                    </template>

                    <template v-if="field.key === 'order'">
                      {{ tr.order }}
                    </template>
                    <template v-if="field.key === 'hide'">
                      <binding-chain-btn
                        :spec="tr"
                        :original_product="tr?.original_product"
                        :product="tr?.product"
                        :supplier_name="document.supplier.name"
                        @click="showBindingModal(tr)"
                      />
                    </template>
                    <template v-if="field.key === 'product'">
                      <product-spec-info :product="tr.product" />
                    </template>

                    <template v-if="field.key === 'delta'">
                      <div style="display: flex; flex-direction: row; align-self: center; justify-content: center">
                        <table-row-input
                          id="tooltip-target-1"
                          :ref="tr.id + 'delta'"
                          :value="tr.delta"
                          :digits="3"
                          :second_value="tr.requestDelta"
                          :fixed="document.fixed"
                          :error="tr.delta !== tr.serial_count && tr.serial_count !== -1"
                          :input_id="tr.id + 'delta'"
                          @handle-input="(val) => setDelta(val, tr, true)"
                          @input="(val) => setDelta(val, tr)"
                          @on-focus="centeredScroll"
                        />
                        <template v-if="tr.serial_count !== -1">
                          <img
                            v-if="tr.delta !== tr.serial_count && tr.serial_count !== -1"
                            v-b-tooltip.hover.top
                            src="/img/icons/docs/marking-error.svg"
                            alt=""
                            class="ml-3 cursor"
                            title="Некорректный статус марки, нажмите, чтобы узнать больше"
                            @click="showMarkingModal(tr)"
                          />
                          <img
                            v-else
                            src="/img/icons/docs/marking.svg"
                            alt=""
                            class="ml-3 cursor"
                            @click="showMarkingModal(tr)"
                          />
                        </template>
                      </div>
                    </template>

                    <template v-if="field.key === 'measurement'">
                      <span v-if="tr.packages_list?.length < 1">{{ tr.product.measurement.name || '–' }}</span>
                      <span v-else>
                        <table-select-input
                          :product="tr?.product"
                          :tr="tr"
                          :option_list="tr.packages_list"
                          :value="tr.packages.id"
                          :fixed="document.fixed"
                          add_button
                          :input_id="tr.id + 'mes'"
                          @handle-input="(val) => setMes(val, tr)"
                          @on-focus="centeredScroll"
                          @click-bottom="clickAddMess"
                        />
                      </span>
                    </template>

                    <template v-if="field.key === 'price'">
                      <table-row-input
                        :value="tr.price"
                        :fixed="document.fixed"
                        symbol="₽"
                        :input_id="tr.id + 'price'"
                        @handle-input="(val) => setPrice(val, tr, true)"
                        @input="(val) => setPrice(val, tr)"
                        @on-focus="centeredScroll"
                      />
                    </template>

                    <template v-if="field.key === 'sum'">
                      <table-row-input
                        :value="tr.sum"
                        :fixed="document.fixed"
                        symbol="₽"
                        :input_id="tr.id + 'sum'"
                        @handle-input="(val) => setSum(val, tr, true)"
                        @input="(val) => setSum(val, tr)"
                        @on-focus="centeredScroll"
                      />
                    </template>

                    <template v-if="field.key === 'markup'">
                      <table-row-input
                        :value="tr.markup"
                        :fixed="document.fixed || !document.branch?.id"
                        symbol="%"
                        :input_id="tr.id + 'markup'"
                        @handle-input="(val) => setMarkup(val, tr, true)"
                        @input="(val) => setMarkup(val, tr)"
                        @on-focus="centeredScroll"
                      />
                    </template>

                    <template v-if="field.key === 'retailPrice'">
                      <table-row-input
                        :value="tr.retailPrice"
                        :fixed="document.fixed || !document.branch?.id"
                        symbol="₽"
                        :input_id="tr.id + 'retailPrice'"
                        @handle-input="(val) => setRetailPrice(val, tr, true)"
                        @input="(val) => setRetailPrice(val, tr)"
                        @on-focus="centeredScroll"
                      />
                    </template>

                    <template v-if="field.key === 'currentPrice'">
                      {{ formatPrice(tr.product?.retailPrice ?? 0) }} ₽
                    </template>

                    <template v-if="field.key === 'nds'">
                      <table-select-input
                        :option_list="taxRateList"
                        :value="tr.nds.id"
                        :fixed="document.fixed"
                        symbol="₽"
                        :input_id="tr.id + 'nds'"
                        @handle-input="(val) => setNds(val, tr)"
                        @on-focus="centeredScroll"
                      />
                    </template>

                    <template v-if="field.key === 'taxRateSum'"> {{ tr.taxRateSum }} ₽</template>

                    <template v-if="field.key === 'remainders'">
                      {{ getRemainder(tr?.product?.remainders) }}
                    </template>

                    <template v-if="field.key === 'cost'">
                      {{ +tr.cost?.toFixed(2) || 0 }}
                    </template>

                    <template v-if="field.key === 'additional_info' && tr.product?.productExternalId?.length">
                      Вид: {{ tr?.product?.productExternalId?.[0]?.egaisParams?.type ?? '--' }}, крепкость:
                      {{ tr?.product?.productExternalId?.[0]?.egaisParams?.strength ?? '--' }}, объем:
                      {{ tr?.product?.productExternalId?.[0]?.egaisParams?.volume ?? '--' }}
                    </template>
                  </b-td>
                </template>
                <template v-else>
                  <b-td
                    v-for="(field, index) of fields.filter((el) => el.checked)"
                    :key="index"
                    style="overflow: hidden"
                  >
                    <div
                      style="height: 45px"
                      class="skeleton-box"
                    >
                      <vue-skeleton-loader
                        class="skeleton"
                        type="rect"
                        :height="15"
                        animation="fade"
                        rounded
                        radius="2"
                      />
                    </div>
                  </b-td>
                </template>
              </b-tr>
            </template>
            <b-tr v-if="items?.length === 0 && !busy">
              <td :colspan="fields.length">
                <p class="center mt-3">Нет товаров</p>
              </td>
            </b-tr>
            <add-incoming-spec
              v-if="handler_product"
              :spec="specToCreate"
              @add_product="createSpec"
              @remove_prod="removeProduct"
              @on-focus="centeredScroll"
            />

            <b-tr
              v-if="!document.fixed && !scrollExist"
              style="padding: 16px; border-bottom: 1px solid #bbbbbb"
            >
              <td
                :colspan="fields.length"
                style="width: 100%; margin-left: 16px"
              >
                <edo-spec-search
                  v-if="!document.fixed && !scrollExist"
                  style="position: relative; width: 250px"
                  :document_head="document.id"
                  @select_product="select_product"
                  @clear-search="clearSearch"
                />
              </td>
            </b-tr>
            <incoming-navbar
              style="position: absolute; bottom: 0; right: 0; left: 0"
              :items="isAllSelected ? document?.paginationSpecifications?.total : selected?.length ?? 0"
              :clear_selected="clearSelected"
              :edo="!!document.edo.type"
              :operation="document?.operation?.type"
              @edit_product="edit_product"
              @remove_items="removeSpecs"
              @print_price_tags="printPriceTags"
              @open_add_product_in_contract_modal="openAddProductInContractModal"
            />
          </b-tbody>
        </b-table-simple>
      </div>

      <div class="table-footer incoming-table-footer align-middle d-flex align-items-center p-3">
        <div class="mr-auto">
          <edo-spec-search
            v-if="!document.fixed && scrollExist"
            style="width: 250px; position: relative"
            class="ml-2"
            :document_head="document.id"
            @edit_product="edit_product"
            @select_product="select_product"
          />
        </div>

        <div class="footer-data">
          Итого:
          {{ document.paginationSpecifications?.total }}
        </div>

        <div class="footer-data">
          Количество:
          <span>
            {{ +document.paginationSpecifications?.factTotal?.toFixed(2) || 0 }}
          </span>
        </div>

        <div class="footer-data">
          Сумма:
          <span>
            {{ formatPrice(document.paginationSpecifications?.sumTotal) }}
            ₽
          </span>
        </div>

        <div class="footer-data">
          Сумма в рознице:
          <span>
            {{ formatPrice(document.retail_sum) }}
            ₽
          </span>
        </div>

        <div class="footer-data">
          Наценка:
          <span>
            {{ +(document.paginationSpecifications?.avgMarkup * 100).toFixed(2) || 0 }}
            %
          </span>
        </div>

        <div class="footer-data">
          Сумма НДС:
          {{ formatPrice(document.paginationSpecifications?.taxRateSum) }}
          ₽
        </div>
      </div>
    </div>
    <sort-table-modal
      :fields="copyFields"
      :default_fields="default_fields"
      @change="changeFields"
      @set-default="setDefault"
    />
  </div>
</template>

<script>
  import AddDocumentInContractModal from './modal/AddDocumentInContractModal.vue'
  import ModalPriceUp from '@/views/operational-processes/components/overhead/ModalPriceUp'
  import IncomingNavbar from '@/views/operational-processes/components/incoming/IncomingNavbar'
  // import { bus } from '@/main'
  import { mapActions, mapGetters } from 'vuex'
  import ModalCostsAdd from '@/views/operational-processes/components/overhead/ModalCostsAdd'
  import TableRowInput from '@/views/operational-processes/components/TableRowInput'
  import { TaxRateModel } from '@/models/tax-rate.model'
  import TableSelectInput from '@/views/operational-processes/components/TableSelectInput'
  import SortTableModal from '@/views/operational-processes/components/SortTableModal'
  import { IncomingSpecificationModel } from '@/models/incoming-spec.model'
  import AddIncomingSpec from '@/views/operational-processes/components/incoming/AddIncomingSpec'
  import { IncomingHeadModel } from '@/views/operational-processes/models/incoming-head.model'
  import MarkingModal from '@/views/operational-processes/components/incoming/MarkingModal'
  import ProductBindingModal from '@/views/operational-processes/components/incoming/BindingModal'
  import { ProductModel } from '@/models/product.model'
  import EdoSpecSearch from '@/views/operational-processes/components/incoming/EdoSpecSearch'
  import UkdModal from '@/views/operational-processes/components/incoming/UkdModal'
  import ProductMovementModal from '@/views/products/modal/ProductMovementModal.vue'
  import ModalSpecMove from '@/views/operational-processes/components/overhead/ModalSpecMove.vue'
  import ProductSpecInfo from '@/views/operational-processes/components/ProductSpecInfo.vue'
  import BindingChainBtn from '@/views/operational-processes/components/incoming/binding/BindingChainBtn.vue'
  import PackageModal from '@/views/products/modal/PackageModal'
  import ExtSystemLogo from '@/views/operational-processes/components/incoming/ExtSystemLogo.vue'
  import EdoTabs from '@/views/operational-processes/components/incoming/EdoTabs.vue'

  export default {
    components: {
      AddDocumentInContractModal,
      EdoTabs,
      ExtSystemLogo,
      PackageModal,
      BindingChainBtn,
      ProductSpecInfo,
      ModalSpecMove,
      ProductMovementModal,
      UkdModal,
      EdoSpecSearch,
      MarkingModal,
      AddIncomingSpec,
      SortTableModal,
      TableSelectInput,
      TableRowInput,
      ModalPriceUp,
      ModalCostsAdd,
      IncomingNavbar,
      ProductBindingModal
    },
    props: {
      document: {
        type: IncomingHeadModel
      },
      busy: {
        type: Boolean
      },
      items: {
        type: [IncomingSpecificationModel]
      },
      tab_source: {
        type: String
      }
    },
    data() {
      return {
        ean: 'piece',
        product_id: null,
        copyItems: [],
        isUpdateFilters: false,
        searchString: '',
        search_result: false,
        fields: [],
        printProducts: [],
        specToCreate: new IncomingSpecificationModel(),
        handler_product: false,
        default_fields: [
          {
            key: 'id',
            label: '',
            thStyle: 'min-width: 45px',
            checked: true,
            width: 45
          },
          {
            key: 'order',
            label: '№',
            checked: true,
            sortable: true,
            width: 54
          },
          {
            key: 'hide',
            label: '',
            checked: true,
            sortable: true,
            width: 70
          },
          {
            key: 'product',
            label: 'Наименование товара',
            thStyle: 'width: 250px ;min-width: 250px;',
            checked: true,
            sortable: true,
            width: 250
          },
          // {
          //   key: 'requestDelta',
          //   label: 'Количество заявл',
          //   thStyle: 'min-width: 220px;width: 220px',
          //   checked: true,
          //   width: 120
          // },
          {
            key: 'delta',
            label: 'Количество',
            thStyle: 'min-width: 220px;width: 220px',
            checked: true,
            sortable: true,
            width: 120
          },
          {
            key: 'measurement',
            label: 'Единица измерения',
            thStyle: 'min-width: 54px;width: 54px',
            checked: true,
            width: 120,
            formatter: (value, key, item) => item.product?.measurement?.name ?? '-'
          },
          {
            key: 'price',
            label: 'Закупочная цена',
            thStyle: 'min-width: 150px;width: 150px',
            checked: true,
            sortable: true,
            width: 150
          },
          {
            key: 'sum',
            label: 'Сумма по позиции',
            thStyle: 'min-width: 150px;width: 150px',
            checked: true,
            sortable: true,
            width: 150
          },
          {
            key: 'markup',
            label: 'Наценка',
            thStyle: 'min-width: 120px;width: 120px',
            checked: true,
            sortable: true,
            width: 120
          },
          {
            key: 'retailPrice',
            label: 'Розница новая',
            thStyle: 'min-width: 120px;width: 120px',
            checked: true,
            sortable: true,
            width: 150
          },
          {
            key: 'currentPrice',
            label: 'Розница текущая',
            thStyle: 'min-width: 120px;width: 120px',
            checked: true,
            width: 140,
            formatter: (value, key, item) => item.product?.retailPrice ?? 0
          },
          {
            key: 'nds',
            label: 'Ставка НДС',
            thStyle: 'min-width: 150px;width: 150px',
            width: 150,
            checked: true
          },
          {
            key: 'taxRateSum',
            label: 'Сумма НДС',
            thStyle: 'min-width: 120px;width: 120px',
            checked: true,
            width: 120
          },
          {
            key: 'remainders',
            label: 'Остаток',
            formatter: (value, key, item) => this.getRemainder(item.product.remainders),
            thStyle: 'min-width: 100px;width: 100px',
            checked: true,
            width: 100
          },
          {
            key: 'cost',
            label: 'Издержки',
            thStyle: 'min-width: 100px;width: 100px',
            checked: true,
            width: 100
          },
          {
            key: 'production_date',
            label: 'Дата производства',
            thStyle: 'min-width: 100px;width: 100px',
            checked: true,
            width: 100
          },
          {
            key: 'additional_info',
            label: 'Доп. информация',
            thStyle: 'min-width: 100px;width: 100px',
            checked: true,
            width: 100
          }
        ],
        selected: [],
        isAllSelected: false,
        taxRateList: [],
        thElm: 0,
        startOffset: 0,
        field: null,
        scrollExist: true,
        isModalOpened: false,
        selectedProduct: new IncomingSpecificationModel(),
        selectedBindingProduct: new ProductModel(),
        sort: {
          sortBy: '',
          sortDesc: null
        },
        tabs: []
      }
    },

    apollo: {
      TaxRates: {
        query: require('../../gql/getTaxRates.gql'),
        result({ data }) {
          this.taxRateList = data?.TaxRates?.map((el) => new TaxRateModel(el)) ?? []
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      },
      IncomeTabs: {
        query: require('../../gql/IncomeTabs.graphql'),
        fetchPolicy: 'no-cache',
        variables() {
          return {
            id: this.document.id
          }
        },
        result({ data }) {
          this.tabs = data.IncomeTabs?.length
            ? [{ source: 'main', count: this.document.paginationSpecifications.total }, ...data.IncomeTabs]
            : []
        }
      }
    },

    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch',
        getIncomingSpecs: 'operprocess/getIncomingSpecs'
      }),
      copyFields() {
        return JSON.parse(JSON.stringify(this.fields))
      },
      getProduct() {
        return this.items?.find((el) => el.id === this.selected[0])?.product
      }
    },

    watch: {
      items() {
        this.copyItems = this.items
      },
      isAllSelected(newVal) {
        if (newVal) {
          this.selected = this.items?.map((el) => el.id) ?? []
        } else this.selected = []
      }
    },

    mounted() {
      this.copyItems = this.items
      // this.$root.$on('bv::modal::hide',
      //   this.bind()
      //   this.isModalOpened = true
      // })
      this.$nextTick(() => this.tableResize())
      const srtFields = localStorage.getItem('edo_incoming_fields')
      this.fields = srtFields ? JSON.parse(srtFields) : this.default_fields
      document.addEventListener(
        'click',
        (e) => {
          this.listenerClick(e)
        },
        false
      )

      this.loadFields()
      this.$nextTick(() => this.scrollListener())
    },

    beforeDestroy() {
      document.removeEventListener('click', () => console.info('removeListener'))
      document.querySelector('.table-docs').removeEventListener('scroll', this.scrollDown)
    },

    methods: {
      ...mapActions({
        setIncomingSpecs: 'operprocess/setIncomingSpecifications',
        setSpecById: 'operprocess/setIncomingSpecificationById',
        setSpecByParam: 'operprocess/setSpecByParam',
        setBindingModal: 'core/setBindingModal'
      }),
      openAddProductInContractModal() {
        this.$bvModal.show('add-document-in-contract-modal')
      },
      setTabSource(source) {
        this.$emit('setTabSource', source)
      },
      search(value) {
        this.$emit('search', value)
      },
      clickAddMess({ product, tr }) {
        this.product_id = product.id
        this.tr = tr
        this.ean = product?.measurement?.type
        this.$bvModal.show('package-modal')
      },
      async addPackage(el) {
        try {
          let { data } = await this.$apollo.mutate({
            mutation: require('../../../products/gql/CreatePackage.gql'),
            variables: {
              product: this.product_id,
              barcode: el?.barcode ?? null,
              value: +el.value,
              length: +el.length,
              width: +el.width,
              height: +el.height
            }
          })
          if (data?.CreatePackage) {
            this.$bvModal.hide('package-modal')
            const find = this.items.find((obj) => obj.id === this.tr.id)
            find.setPackage({
              ...el,
              id: data?.CreatePackage?.id
            })
            this.setSpecByParam({ spec_id: find.id, key: 'package', value: data.CreatePackage })
            await this.updateSpec(find)
            this.$noty.show('Упаковка создана')
          }
          this.product_id = null
          this.ean = 'piece'
          this.tr = {}
          setTimeout(async () => await this.$emit('update-data'), 200)
        } catch (e) {
          this.$noty.error('При создании упаковки произошла ошибка')
          this.product_id = null
          this.tr = {}
        }
      },
      bind(product) {
        product.hide = false
        this.copyItems = this.items.map((obj) => {
          if (obj.product.id === product.id) {
            obj.product = product
          }
          return obj
        })
      },
      edit_product() {
        this.$router.push({
          name: 'products.edit',
          params: { name: this.getProduct.name, id: this.getProduct.id }
        })
      },
      scrollDown(event) {
        this.$emit('scroll-change', event.target.scrollTop)
        let bottomOfWindow = event.target.scrollTop + 100 + event.target.clientHeight >= event.target.scrollHeight
        if (event.target.scrollTop === 0) {
          this.$emit('scroll-up', bottomOfWindow)
        } else if (bottomOfWindow) {
          this.$emit('scroll-down')
        }
      },
      updateData() {
        this.$emit('update-data')
      },
      clearSearch(ev) {
        this.searchString = ''
        if (ev === 'add') this.$noty.show('Добавлено')
        else if (ev === 'exist') this.$noty.error('Марка уже находится в спецификации')
      },
      async loadFields() {
        const { data } = await this.$apollo.query({
          fetchPolicy: 'no-cache',
          query: require('../../gql/ReadUserTable.graphql')
        })
        const table = data?.ReadUserTable?.tables?.find((el) => el.table_name === 'edo_incoming')
        if (table?.fields) this.fields = table.fields
      },
      scrollListener() {
        const resizeObserver = new ResizeObserver(() => {
          const table = document.querySelector('.table-docs')

          this.scrollExist =
            table.offsetHeight <= document.querySelector('.incoming-table')?.clientHeight ||
            (table.offsetHeight <= document.querySelector('.incoming-table')?.clientHeight + 155 && this.scrollExist)
        })

        resizeObserver.observe(document.querySelector('.incoming-table'), {
          childList: true,
          subtree: true
        })
        document.querySelector('.table-docs').addEventListener('scroll', this.scrollDown)
        // window.removeEventListener('scroll', this.handleScroll)
      },
      centeredScroll({ offset }) {
        const clientWidth = document.querySelector('.table-docs').clientWidth
        document.querySelector('.table-docs').scrollTo({ left: offset - clientWidth / 2, behavior: 'smooth' })
      },
      getIcons(id) {
        if (!this.default_fields.find((el) => el.key === id)?.sortable) return
        let icon = '/img/icons/table/sort_up.svg'
        if (this.sort.sortBy !== id) icon = '/img/icons/table/sort_none.svg'
        else if (!this.sort.sortDesc) icon = '/img/icons/table/sort_down.svg'
        return {
          background: `white url(${icon}) no-repeat calc(100% - 7px) 50% !important`,
          cursor: 'pointer'
        }
      },
      sortChange(key) {
        if (!this.default_fields.find((el) => el.key === key)?.sortable) return
        this.sort.sortBy = key
        this.sort.sortDesc = !this.sort.sortDesc
        this.$emit('sort_change', {
          sortBy: key,
          sortDesc: this.sort.sortDesc
        })
      },
      mouseDown(e, field) {
        this.thElm = e.target.parentNode
        this.startOffset = this.thElm.offsetWidth - e.pageX
        this.field = field
      },
      tableResize() {
        document.addEventListener('mousemove', (e) => {
          if (this.thElm && this.startOffset + e.pageX > 0) {
            this.thElm.style.minWidth = this.startOffset + e.pageX + 'px'
            this.thElm.style.width = this.startOffset + e.pageX + 'px'
            this.field.width = this.startOffset + e.pageX
          }
        })

        document.addEventListener('mouseup', () => {
          if (!this.thElm) return
          this.field.thStyle = this.thElm.style.cssText
          this.saveFields()
          this.startOffset = 0
          this.thElm = undefined
        })
      },
      setDefault() {
        this.fields = this.default_fields
        this.saveFields()
      },
      async specMove(number) {
        await this.$apollo.query({
          query: require('../../gql/IncMoveSpec.graphql'),
          fetchPolicy: 'no-cache',
          notifyOnNetworkStatusChange: true,
          variables: {
            input: {
              head: this.$route.query.id ? this.$route.query.id : this.$route.params.id,
              position: number,
              spec: this.selected[0]
            }
          }
        })
        this.selected = []
        this.$emit('update-data')
        this.$bvModal.hide('modal-spec-move')
      },
      saveFields() {
        localStorage.setItem('edo_incoming_fields', JSON.stringify(this.fields))
        this.$apollo.mutate({
          mutation: require('../../gql/saveFields.graphql'),
          variables: {
            input: {
              table_name: 'edo_incoming',
              fields: this.fields.map((el) => {
                return {
                  key: el.key,
                  checked: el.checked,
                  width: el.width,
                  label: el.label
                }
              })
            }
          }
        })
      },
      openSortModal() {
        this.$bvModal.show('sort-table-modal')
      },
      getRemainder(item) {
        const storage_id = this.document.storage.id

        if (Array.isArray(item)) {
          const num = item.filter((el) => el?.storage?.id === storage_id).reduce((sum, el) => sum + el.value, 0) || 0
          return num % 1 === 0 ? num : num?.toFixed(3)
        }

        return 0
      },

      async setDelta(val, item, isEnter) {
        const old_val = item.delta
        item.setDelta(val)
        this.setSpecByParam({ spec_id: item.id, key: 'delta', value: val })
        const status = await this.updateSpec(item)
        if (status) {
          if (isEnter) document.getElementById(item.id + 'price')?.click()
        } else {
          item.setDelta(old_val)
        }
      },

      setMes(val, item) {
        item.setPackage(val)
        this.setSpecByParam({ spec_id: item.id, key: 'package', value: val })
        this.updateSpec(item)
      },

      setPrice(val, item, isEnter) {
        item.setPrice(val)
        this.setSpecByParam({ spec_id: item.id, key: 'price', value: val })
        if (isEnter) document.getElementById(item.id + 'sum')?.click()
        this.updateSpec(item)
      },

      setSum(val, item, isEnter) {
        item.setSum(val)
        this.setSpecByParam({ spec_id: item.id, key: 'sum', value: val })
        if (isEnter) document.getElementById(item.id + 'markup')?.click()
        this.updateSpec(item)
      },

      setMarkup(val, item, isEnter) {
        item.setMarkup(val)
        this.setSpecByParam({ spec_id: item.id, key: 'markup', value: val })
        if (isEnter) this.$nextTick(() => document.getElementById(item.id + 'retailPrice')?.click())

        this.updateSpec(item)
      },

      setRetailPrice(val, item, isEnter) {
        item.setRetailPrice(val)
        this.setSpecByParam({ spec_id: item.id, key: 'retail_price', value: val })

        if (isEnter) {
          document.querySelector('.table-docs').scrollTo({ left: 0, behavior: 'smooth' })
        }
        this.updateSpec(item)
      },

      setNds(val, item) {
        item.setNds(val)
        this.setSpecByParam({ spec_id: item.id, key: 'nds', value: val })

        this.updateSpec(item)
      },

      formatPrice(summ = 0) {
        const val = summ === null ? 0 : summ

        const formatter = new Intl.NumberFormat('ru-RU', {
          style: 'decimal',
          currency: 'RUB',
          maximumFractionDigits: 2,
          minimumFractionDigits: 0
          // These options are needed to round to whole numbers if that's what you want.
          //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
          // maximumFractionDigits: 0 // (causes 2500.99 to be printed as $2,501)
        })

        return formatter.format(val)
      },

      searchProductResult(data) {
        const result = data.ProductSearch?.products ?? []

        if (result.length === 1) {
          this.search_result = false
          this.select_product(result[0])
        }

        return data
      },

      async select_product(item) {
        this.search_result = false
        if (item.found_by_serial) {
          this.setSpecById({ id: item.id, delta: item.delta, serial_count: item.serial_count })
        }
        if (item.id) {
          if (this.$refs[item.id + 'delta'] && this.$refs[item.id + 'delta'][0]) {
            console.log('scroll_to')
            this.scroll_to(item)
          } else {
            this.scroll_to(this.items[this.items.length - 1], false)
            this.$emit('get-last', () => this.$nextTick(() => this.scroll_to(item)), item.cursor)
          }
        }
        this.clearSearch()
      },
      scroll_to(item, show_input = true) {
        if (!item.found_by_serial) {
          setTimeout(() => {
            if (show_input) this.$refs[item.id + 'delta'][0].showInput()
            this.$nextTick(() => {
              this.$refs[item.id + 'delta'][0].$el.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'end'
              })
            })
          }, 100)
        } else {
          this.$nextTick(() => {
            this.$refs[item.id + 'delta'][0].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
              inline: 'end'
            })
          })
        }

        // document.querySelector('.table-docs').scrollTo({
        //   top: this.$refs[item.id + 'delta'][0].$el.parentElement.parentElement.offsetTop - 80
        // })
      },

      // async select_product(item) {
      //   if (item?.specification?.id && item.found_by_serial) {
      //     await this.$apollo.mutate({
      //       mutation: require('../../gql/updateIncomingEdoSpecification.graphql'),
      //       variables: {
      //         input: {
      //           document_head: this.document.id,
      //           serial: item.query,
      //           turn_on_serial: true
      //         }
      //       }
      //     })
      //     this.$emit('update-data')
      //   }
      //
      // },
      async createSpec(spec) {
        const { data } = await this.$apollo.mutate({
          mutation: require(`../../gql/CreateIncomingSpecification.gql`),
          variables: {
            productChange: [spec],
            document_head: this.document.id
          }
        })

        const specIndex = this.items.findIndex((el) => el.id === data.CreateIncomingSpecification[0].id)
        this.document.setSpec(specIndex, data.CreateIncomingSpecification[0])
        this.$noty.show('Добавлено')
        this.$emit('update_table')

        this.removeProduct()
        document.querySelector('.table-docs').scrollTo({ left: 0, behavior: 'smooth' })
        setTimeout(() => document.getElementById('productSearchInput').focus(), 700)
      },

      async updateSpec(spec) {
        let flag = true
        try {
          const { data } = await this.$apollo.mutate({
            mutation: require(`../../gql/updateIncomingEdoSpecification.graphql`),
            variables: {
              input: { ...spec.edo_input, document_head: this.document.id }
            }
          })
          const updatedSpec = data?.UpdateIncomingEdoSpecification
          const specs = this.getIncomingSpecs.map((obj) => {
            if (obj.id === updatedSpec.id) {
              return { ...obj, ...updatedSpec, product: obj.product }
            }
            return obj
          })
          this.setIncomingSpecs(specs)
        } catch (e) {
          flag = false
          console.log(e, 'error')
          this.$noty.error(e.graphQLErrors?.[0]?.extensions?.ru ?? 'Ошибка')
        }
        this.$emit('update-total')
        return flag
      },

      async removeSpecs() {
        const { data } = await this.$apollo.mutate({
          mutation: require('../../gql/RemoveIncomingSpecification.gql'),
          variables: {
            document_head: this.document.id,
            ids: this.selected,
            isAllSelected: this.isAllSelected
          }
        })
        if (data?.RemoveIncomingSpecification?.status) {
          this.isAllSelected = false
          this.selected = []
          this.$emit('update-data')
        }
      },

      removeProduct() {
        this.specToCreate = {}
        this.handler_product = false
      },

      setSupplierError(status = false) {
        this.isSupplierError = status
      },
      counterSeconds() {
        this.time = this.time + 0.1
      },

      resetTimer() {
        this.time = 0
      },

      clearSelected() {
        this.isAllSelected = false
        this.selected = []
      },

      // Action's
      back() {
        this.filter_params = this.filtersData()
        this.$nextTick(() => this.updateDocument())
      },
      listenerClick(e) {
        const target = e.target.classList.value

        if (
          target === 'cursor' ||
          target === 'span-count' ||
          target === 'span-count error' ||
          target === 'edit' ||
          target === 'ivu-select-placeholder' ||
          target === 'ivu-icon ivu-icon-ios-arrow-down ivu-select-arrow' ||
          target === 'form-control'
        )
          return true
      },

      async printPriceTags() {
        const delta = []
        let products = []
        for (const prod of this.selected) {
          const found = this.items.find((el) => el.id === prod)
          if (found) {
            products.push(found.product.id)
            delta.push({ id: found.product.id, delta: found.delta })
          }
        }
        this.$emit('printPrice', {
          document_head: this.document.id,
          branch: this.document.branch.id,
          product: products,
          delta,
          isPrintAll: this.isAllSelected
        })
        // const { data } = await this.$apollo.query({
        //   query: require('../../gql/ReadPriceTagsFromDocument.graphql'),
        //   fetchPolicy: 'no-cache',
        //   variables: {
        //     input: {
        //       document_head: this.document.id,
        //       branch: this.document.branch.id,
        //       product: products,
        //       isPrintAll: this.isAllSelected
        //     }
        //   }
        // })
        // this.printProducts = data?.ReadPriceTagsFromDocument?.map(
        //   (el) => new ProductModel(el.Product)
        // )
        // this.$bvModal.show('modal-print-tags')
      },
      changeFields(val) {
        this.fields = val
        this.saveFields()
      },

      showPriceUp() {
        this.$bvModal.show('modal-price-up')
      },

      async totalUpdateMarkup(priceup) {
        const { data } = await this.$apollo.mutate({
          mutation: require('../../gql/AddMarkup.graphql'),
          variables: {
            input: {
              document_head: this.document.id,
              ids: this.selected,
              isAllSelected: this.isAllSelected,
              markup: +priceup.value,
              roundTo: priceup.priceup
            }
          }
        })
        if (data?.AddMarkup?.status) {
          this.$bvModal.hide('modal-price-up')
          this.isAllSelected = false
          this.selected = []
          this.$emit('update-data')
        }
      },
      async totalUpdateCosts(costsadd) {
        const { data } = await this.$apollo.mutate({
          mutation: require('../../gql/AddCosts.graphql'),
          variables: {
            input: {
              document_head: this.document.id,
              ids: this.selected,
              isAllSelected: this.isAllSelected,
              costs: +costsadd.value,
              addParams: costsadd.addParams
            }
          }
        })
        if (data?.AddCosts?.status) {
          this.$bvModal.hide('modal-costs-add')
          this.isAllSelected = false
          this.selected = []
          this.$emit('update-data')
        }
      },
      showMarkingModal(item) {
        this.selectedProduct = item
        this.$bvModal.show('marking-modal')
      },
      showBindingModal(item) {
        this.selectedBindingProduct = item.product
        this.setBindingModal({ spec_id: item.id })
        this.$bvModal.show('core-binding-modal')
      },
      addProduct(item) {
        this.$router.push({
          name: 'products.edit',
          params: { name: item.product.name, id: item.product.id }
        })
      }
    }
  }
</script>
<style scoped lang="scss">
  ::v-deep() {
    .table-hover tbody tr:hover th {
      background-color: white !important;
    }

    .table-hover tbody tr:hover th {
      background-color: #f8f8f9 !important;
      transition: 1000ms ease;

      & .count {
        background-color: transparent !important;
        transition: none ease-in;
      }
    }

    .table-docs thead th {
      border-bottom: 1px solid #e1e1e1;
      border-right: 1px solid #e1e1e1;
      border-top: 1px solid #e1e1e1 !important;
    }

    .table-footer th {
      vertical-align: middle !important;
    }

    .row-error .row-error > td:first-child {
      border-left: 1px solid #ef9a9a !important;
      border-top: 1px solid #ef9a9a !important;
      border-bottom: 1px solid #ef9a9a !important;
    }

    .row-error > td {
      border-top: 1px solid #ef9a9a !important;
      border-bottom: 1px solid #ef9a9a !important;
    }

    .row-error > td:last-child {
      border-right: 1px solid #ef9a9a !important;
      border-top: 1px solid #ef9a9a !important;
      border-bottom: 1px solid #ef9a9a !important;
    }
    .skeleton.animation--fade {
      width: 100% !important;
    }
    .skeleton-box {
      height: 45px;
      display: flex;
      align-items: center;
    }
  }

  .table-hover tbody tr:hover td,
  .table-hover tbody tr:hover td,
  .table-hover tbody tr:hover th {
    & .count {
      background-color: transparent !important;
      transition: none ease-in;
    }
  }

  .resize-field {
    top: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    cursor: col-resize;
  }

  .field-column {
    position: relative;
  }

  .incoming-table {
    table-layout: fixed;
    width: 100% !important;
  }

  table.fixed td {
    overflow: hidden;
  }

  .unselectable {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
    -khtml-user-select: none; /* Konqueror */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                 not supported by any browser */
  }

  .incoming-table {
    border: none !important;
  }

  .footer-data {
    color: #313131;
    margin-left: 32px;
  }

  .incoming-table-footer {
    border-top: 1px solid #e1e1e1 !important;
  }

  // TODO perekrasit`
  .row-error * {
    background-color: #fffcfc !important;
  }

  .row-error:hover * {
    background-color: transparent !important;
  }

  .row-error:hover {
    background-color: #f8f8f9;
  }

  td {
    //position: relative;
    .b-status {
      width: 2px;
      height: 48px;
      position: absolute;
      left: 0px;
      top: 50%;
      background: #e53835;
      border-radius: 0px 1px 1px 0px;
      transform: translate(0%, -50%);

      &.success {
        background: #00cb91;
      }
    }
  }
</style>
