var render = function render(){
  var _vm$selected_doc$spec, _vm$selected_doc2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    attrs: {
      "id": "ukd"
    }
  }, [_c('b-modal', {
    attrs: {
      "id": "comment-modal",
      "centered": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function (_ref) {
        var close = _ref.close;
        return [_c('h5', [_vm._v("Введите комментарий для уточнения")]), _c('b-button', {
          staticClass: "close",
          on: {
            "click": function ($event) {
              return close();
            }
          }
        }, [_c('img', {
          attrs: {
            "src": "/img/icons/modal_close.svg",
            "alt": ""
          }
        })])];
      }
    }, {
      key: "modal-footer",
      fn: function (_ref2) {
        var hide = _ref2.hide;
        return [_c('div', {
          staticStyle: {
            "display": "flex",
            "flex-direction": "row"
          }
        }, [_c('b-button', {
          attrs: {
            "variant": "success"
          },
          on: {
            "click": function ($event) {
              return _vm.signDoc('correction_end');
            }
          }
        }, [_vm._v(" Отправить ")]), _c('b-button', {
          attrs: {
            "variant": "outline-success"
          },
          on: {
            "click": function ($event) {
              _vm.correction_comment = '';
              hide();
            }
          }
        }, [_vm._v(" Отмена ")])], 1)];
      }
    }])
  }, [_c('b-form-textarea', {
    model: {
      value: _vm.correction_comment,
      callback: function ($$v) {
        _vm.correction_comment = $$v;
      },
      expression: "correction_comment"
    }
  })], 1), _c('b-modal', {
    attrs: {
      "id": "ukd-modal",
      "hide-footer": "",
      "centered": "",
      "size": "xl"
    },
    on: {
      "show": _vm.getUkd
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function (_ref3) {
        var hide = _ref3.hide;
        return [_c('div', {
          staticStyle: {
            "display": "flex",
            "flex-direction": "row",
            "align-items": "center"
          }
        }, [_c('div', {
          staticClass: "mr-3",
          staticStyle: {
            "display": "flex",
            "flex-direction": "row",
            "align-items": "center",
            "cursor": "pointer"
          },
          on: {
            "click": hide
          }
        }, [_c('img', {
          staticClass: "mr-2",
          attrs: {
            "src": "/img/icons/arrow_pagination.svg",
            "alt": "",
            "width": "4.5",
            "height": "8"
          }
        }), _c('span', {
          staticStyle: {
            "font-size": "12px"
          }
        }, [_vm._v("Назад ")])]), _c('b-dropdown', {
          attrs: {
            "no-caret": "",
            "variant": "none",
            "right": "",
            "toggle-class": "white-backgr"
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function () {
              var _vm$selected_doc$name, _vm$selected_doc;

              return [_c('div', {
                staticStyle: {
                  "display": "flex",
                  "flex-direction": "row",
                  "align-items": "center"
                }
              }, [_c('h5', {
                staticClass: "mb-0"
              }, [_vm._v(" " + _vm._s((_vm$selected_doc$name = (_vm$selected_doc = _vm.selected_doc) === null || _vm$selected_doc === void 0 ? void 0 : _vm$selected_doc.name) !== null && _vm$selected_doc$name !== void 0 ? _vm$selected_doc$name : ' ') + " "), _c('span', {
                staticClass: "mr-2 ml-2",
                staticStyle: {
                  "color": "#888888"
                }
              }, [_vm._v("|")]), _c('span', {
                staticStyle: {
                  "font-size": "16px"
                },
                style: {
                  color: _vm.edo_status[_vm.selected_doc.edo.status].color
                }
              }, [_vm._v("Статус: " + _vm._s(_vm.edo_status[_vm.selected_doc.edo.status].text))])]), _c('img', {
                staticClass: "ml-3",
                staticStyle: {
                  "transform": "rotate(180deg)"
                },
                attrs: {
                  "src": "/img/icons/arrow_up.svg",
                  "alt": ""
                }
              })])];
            },
            proxy: true
          }], null, true)
        }, _vm._l(_vm.documents, function (document) {
          var _document$name;

          return _c('b-dropdown-item', {
            key: document.name,
            on: {
              "click": function ($event) {
                _vm.selected_doc = document;
              }
            }
          }, [_vm._v(" " + _vm._s((_document$name = document.name) !== null && _document$name !== void 0 ? _document$name : ' ') + " | Статус: "), _c('span', {
            style: {
              color: _vm.edo_status[document.edo.status].color
            }
          }, [_vm._v(_vm._s(_vm.edo_status[document.edo.status].text))])]);
        }), 1)], 1), _c('div', {
          staticStyle: {
            "display": "flex",
            "flex-direction": "row"
          }
        }, [_c('b-button', {
          attrs: {
            "variant": "gray"
          },
          on: {
            "click": _vm.printUkd
          }
        }, [_vm._v(" Печать ")]), _c('span', {
          staticClass: "ml-2",
          staticStyle: {
            "border": "1px solid #e5b509 !important",
            "border-radius": "4px"
          }
        }, [_c('b-button', {
          staticStyle: {
            "height": "30px !important"
          },
          attrs: {
            "variant": "outline-warning"
          },
          on: {
            "click": function ($event) {
              return _vm.signDoc('correction');
            }
          }
        }, [_vm._v("Уточнить ")])], 1), _c('b-button', {
          staticClass: "ml-2",
          attrs: {
            "variant": "success"
          },
          on: {
            "click": function ($event) {
              return _vm.signDoc('confirm');
            }
          }
        }, [_vm._v(" Подтвердить ")])], 1)];
      }
    }])
  }, [_c('b-card', {
    staticClass: "p-0",
    staticStyle: {
      "height": "55vh",
      "overflow": "hidden"
    },
    attrs: {
      "no-body": ""
    }
  }, [_c('b-table', {
    staticClass: "ukd-table",
    attrs: {
      "fields": _vm.fields,
      "table-class": "border-0",
      "sticky-header": "",
      "no-border-collapse": "",
      "items": (_vm$selected_doc$spec = (_vm$selected_doc2 = _vm.selected_doc) === null || _vm$selected_doc2 === void 0 ? void 0 : _vm$selected_doc2.specifications) !== null && _vm$selected_doc$spec !== void 0 ? _vm$selected_doc$spec : []
    },
    scopedSlots: _vm._u([{
      key: "cell(product)",
      fn: function (_ref4) {
        var _item$product$name, _item$product;

        var item = _ref4.item;
        return [_vm._v(" " + _vm._s((_item$product$name = (_item$product = item.product) === null || _item$product === void 0 ? void 0 : _item$product.name) !== null && _item$product$name !== void 0 ? _item$product$name : '-') + " ")];
      }
    }, {
      key: "cell(measurement)",
      fn: function (_ref5) {
        var _item$product$measure, _item$product2, _item$product2$measur;

        var item = _ref5.item;
        return [_vm._v(" " + _vm._s((_item$product$measure = (_item$product2 = item.product) === null || _item$product2 === void 0 ? void 0 : (_item$product2$measur = _item$product2.measurement) === null || _item$product2$measur === void 0 ? void 0 : _item$product2$measur.name) !== null && _item$product$measure !== void 0 ? _item$product$measure : '-') + " ")];
      }
    }, {
      key: "cell(delta)",
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_vm._v(" " + _vm._s(item.delta) + " "), item.delta_change ? _c('span', {
          class: {
            error: item.delta_change < 0,
            success: item.delta_change >= 0
          }
        }, [_vm._v("(" + _vm._s(item.delta_change > 0 ? '+' : '') + _vm._s(+item.delta_change.toFixed(3)) + ")")]) : _vm._e()];
      }
    }, {
      key: "cell(price)",
      fn: function (_ref7) {
        var _item$price;

        var item = _ref7.item;
        return [_vm._v(" " + _vm._s(_vm.formatPrice(+((_item$price = item.price) === null || _item$price === void 0 ? void 0 : _item$price.toFixed(2)))) + " "), item.price_change ? _c('span', {
          class: {
            error: item.price_change < 0,
            success: item.price_change >= 0
          }
        }, [_vm._v("(" + _vm._s(item.price_change > 0 ? '+' : '') + _vm._s(+item.price_change.toFixed(3)) + ")")]) : _vm._e()];
      }
    }, {
      key: "cell(sum)",
      fn: function (_ref8) {
        var _item$sum;

        var item = _ref8.item;
        return [_vm._v(" " + _vm._s(_vm.formatPrice(+((_item$sum = item.sum) === null || _item$sum === void 0 ? void 0 : _item$sum.toFixed(2)))) + " "), item.sum_change ? _c('span', {
          class: {
            error: item.sum_change < 0,
            success: item.sum_change >= 0
          }
        }, [_vm._v("(" + _vm._s(item.sum_change > 0 ? '+' : '') + _vm._s(+item.sum_change.toFixed(3)) + ")")]) : _vm._e()];
      }
    }, {
      key: "cell(nds)",
      fn: function (_ref9) {
        var _item$nds$name, _item$nds;

        var item = _ref9.item;
        return [_vm._v(" " + _vm._s((_item$nds$name = (_item$nds = item.nds) === null || _item$nds === void 0 ? void 0 : _item$nds.name) !== null && _item$nds$name !== void 0 ? _item$nds$name : '-') + " ")];
      }
    }, {
      key: "cell(nds_sum)",
      fn: function (_ref10) {
        var _item$taxRate$value, _item$taxRate;

        var item = _ref10.item;
        return [_vm._v(" " + _vm._s(_vm.formatPrice(item.sum * ((_item$taxRate$value = (_item$taxRate = item.taxRate) === null || _item$taxRate === void 0 ? void 0 : _item$taxRate.value) !== null && _item$taxRate$value !== void 0 ? _item$taxRate$value : 0))) + " ")];
      }
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }