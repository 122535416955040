var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "modal-spec-move",
      "centered": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function () {
        return [_c('h5', [_vm._v("Изменение порядка позиции")]), _c('b-button', {
          staticClass: "close",
          on: {
            "click": _vm.hideModal
          }
        }, [_c('img', {
          attrs: {
            "src": "/img/icons/modal_close.svg",
            "alt": ""
          }
        })])];
      },
      proxy: true
    }, {
      key: "modal-footer",
      fn: function () {
        return [_c('div', {
          staticClass: "d-flex"
        }, [_c('b-button', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": _vm.submitPriceUp
          }
        }, [_vm._v(" Сохранить ")]), _c('b-button', {
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": _vm.hideModal
          }
        }, [_vm._v(" Отмена ")])], 1)];
      },
      proxy: true
    }])
  }, [_c('b-form', {
    on: {
      "submit": function ($event) {
        $event.stopPropagation();
        $event.preventDefault();
        return _vm.submitPriceUp.apply(null, arguments);
      }
    }
  }, [_c('div', [_c('div', {
    staticClass: "item-row"
  }, [_c('div', [_vm._v("Переместить на позицию")]), _c('b-form-input', {
    attrs: {
      "id": "spec-move",
      "type": "number"
    },
    model: {
      value: _vm.value,
      callback: function ($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  })], 1)])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }