<template>
  <div class="edo-tab-list">
    <div
      v-for="(tab, index) of tabs"
      :key="index"
      class="edo-tab"
      :class="{ selected: tab_source === tab.source }"
      @click="setTabSource(tab.source)"
    >
      <ext-system-logo
        class="system-logo"
        :ext_system="{ type: tab.source }"
        :fill="tab_source !== tab.source ? '#CACACA' : '#FFFFFF'"
      />
      {{ tab_mapping[tab.source] }}
      <div
        v-if="tab.ext_head"
        class="ext-head"
      >
        <div class="delimiter"></div>
        {{ tab.ext_head }}
      </div>

      <div
        v-if="tab.count"
        class="counter"
      >
        {{ tab.count }}
      </div>
    </div>
  </div>
</template>
<script>
  import ExtSystemLogo from '@/views/operational-processes/components/incoming/ExtSystemLogo.vue'
  export default {
    name: 'EdoTabs',
    components: {
      ExtSystemLogo
    },
    props: {
      tabs: Array
    },
    data() {
      return {
        source: 'main',
        tab_mapping: {
          main: 'База',
          egais: 'ЕГАИС',
          manual: 'Ручной'
        }
      }
    },
    computed: {
      tab_source() {
        return this.$route.query.source || this.source
      }
    },
    methods: {
      setTabSource(source) {
        let query = { ...this.$route.query }
        query.source = source
        this.$router.replace({ query: query }).catch((er) => er)

        // this.$emit('setTabSource', source)
      }
    }
  }
</script>
<style scoped lang="scss">
  .edo-tab-list {
    overflow-x: auto;
    white-space: nowrap;
    display: flex;
  }
  .edo-tab {
    padding: 5px 10px 5px 10px;
    border-radius: 100px;
    margin-right: 15px;
    cursor: pointer;
    color: #313131;
    border: 1px solid #e1e1e1;
    display: flex;
    align-items: center;
    justify-content: center;
    .system-logo {
      width: 16px;
      height: 16px;
      margin-right: 6px;
    }
    .ext-head {
      display: flex;
      align-items: center;
      .delimiter {
        border-radius: 14px;
        background: #e1e1e1;
        width: 1px;
        height: 14px;
        margin-right: 6px;
        margin-left: 6px;
      }
    }
    &.selected {
      border: 1px solid #00a3ff;
      background: #00a3ff;
      color: #f8f8f9;

      .counter {
        background: white;
        color: #00a3ff;
      }
    }

    .counter {
      color: white;
      margin-left: 6px;
      border-radius: 100px;
      background: #00a3ff;
      min-width: 21px;
      padding: 0px 5px 0px 5px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  ::v-deep() {
    .pixel-tooltip.tooltip.b-tooltip {
      top: 20px !important;
    }
  }
</style>
<!-- <style>
  .pixel-tooltip.tooltip.b-tooltip {
    top: 20px !important;
  }
</style> -->
