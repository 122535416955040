var render = function render(){
  var _vm$document$edo, _vm$document$edo2, _vm$document$edo3, _vm$document, _vm$document$edo4, _vm$document$edo5, _vm$document2, _vm$document2$edo, _vm$document3, _vm$document$edo6, _vm$document4, _vm$document4$edo, _vm$document5, _vm$document$edo7;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "b-table__filter d-flex justify-content-between"
  }, [_c('div', {
    staticClass: "b-toggle-button"
  }, [_c('b-button', {
    staticClass: "btn-filters cursor",
    on: {
      "click": _vm.changeVisibleParams
    }
  }, [_vm._v(" Параметры документа "), _c('img', {
    staticClass: "ml-3",
    class: {
      rotate: _vm.visibleParams
    },
    attrs: {
      "src": "/img/icons/arrow_up.svg",
      "alt": ""
    }
  })]), ((_vm$document$edo = _vm.document.edo) === null || _vm$document$edo === void 0 ? void 0 : _vm$document$edo.type) === 'crptech' && ['awaiting_clarification', 'clarified'].includes((_vm$document$edo2 = _vm.document.edo) === null || _vm$document$edo2 === void 0 ? void 0 : _vm$document$edo2.status) ? _c('b-button', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.ukd-modal",
      modifiers: {
        "ukd-modal": true
      }
    }],
    staticClass: "ukd-button ml-3"
  }, [_c('img', {
    attrs: {
      "src": "/img/integrations/crptech-white.svg",
      "alt": ""
    }
  }), _c('span', {
    staticClass: "ml-2"
  }, [_vm._v("УКД")])]) : _vm._e()], 1), _c('div', {
    staticClass: "d-flex"
  }, [_c('e-button', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.document.fixed,
      expression: "!document.fixed"
    }],
    attrs: {
      "size": "m",
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.back
    }
  }, [_vm._v(" Сохранить черновик ")]), _c('e-button', {
    staticClass: "ml-3",
    style: ['mercury', 'crptech', '-sbis', 'egais'].includes((_vm$document$edo3 = _vm.document.edo) === null || _vm$document$edo3 === void 0 ? void 0 : _vm$document$edo3.type) && _vm.document.edo.action !== 'make_used' && !_vm.document.fixed ? {
      background: '#D4A600',
      border: 'none'
    } : {},
    attrs: {
      "size": "m",
      "variant": "primary",
      "disabled": ((_vm$document = _vm.document) === null || _vm$document === void 0 ? void 0 : (_vm$document$edo4 = _vm$document.edo) === null || _vm$document$edo4 === void 0 ? void 0 : _vm$document$edo4.action) === 'incorrect' || _vm.isLoading || _vm.is_request_update,
      "loading": _vm.isLoading || _vm.is_request_update
    },
    on: {
      "click": function ($event) {
        return _vm.updateDocument(_vm.document.fixed);
      }
    }
  }, [['mercury', 'crptech', '-sbis', 'egais'].includes((_vm$document$edo5 = _vm.document.edo) === null || _vm$document$edo5 === void 0 ? void 0 : _vm$document$edo5.type) && ((_vm$document2 = _vm.document) === null || _vm$document2 === void 0 ? void 0 : (_vm$document2$edo = _vm$document2.edo) === null || _vm$document2$edo === void 0 ? void 0 : _vm$document2$edo.action) === 'make_used' && !((_vm$document3 = _vm.document) !== null && _vm$document3 !== void 0 && _vm$document3.fixed) || !['mercury', 'crptech', '-sbis', 'egais'].includes((_vm$document$edo6 = _vm.document.edo) === null || _vm$document$edo6 === void 0 ? void 0 : _vm$document$edo6.type) && !_vm.document.fixed ? [_vm._v(" Провести ")] : (((_vm$document4 = _vm.document) === null || _vm$document4 === void 0 ? void 0 : (_vm$document4$edo = _vm$document4.edo) === null || _vm$document4$edo === void 0 ? void 0 : _vm$document4$edo.action) === 'make_used_with_diff' || 'incorrect') && !((_vm$document5 = _vm.document) !== null && _vm$document5 !== void 0 && _vm$document5.fixed) && ['mercury', 'crptech', '-sbis', 'egais'].includes((_vm$document$edo7 = _vm.document.edo) === null || _vm$document$edo7 === void 0 ? void 0 : _vm$document$edo7.type) ? [_vm._v(" Провести с расхождением ")] : _vm.document.fixed ? [_vm._v("Редактировать")] : _vm._e()], 2)], 1)]), _c('e-collapse', {
    attrs: {
      "show-button": false
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        var _vm$document6;

        return [_c('filters-incoming', {
          ref: "header_filters_incoming",
          attrs: {
            "fixed": (_vm$document6 = _vm.document) === null || _vm$document6 === void 0 ? void 0 : _vm$document6.fixed,
            "entity_list": _vm.entity_list,
            "filter_params": _vm.document,
            "is_supplier_error": _vm.isSupplierError,
            "is_storage_error": _vm.isStorageError,
            "is_entity_error": _vm.isEntityError
          },
          on: {
            "change": _vm.change,
            "updateData": _vm.updateData
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.visibleParams,
      callback: function ($$v) {
        _vm.visibleParams = $$v;
      },
      expression: "visibleParams"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }