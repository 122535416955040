<template>
  <div class="b-table__wrapper">
    <modal-costs-add @costs_add="totalUpdateCosts" />
    <modal-price-up
      :operation="document?.operation?.type"
      @price_up="totalUpdateMarkup"
    />
    <product-movement-modal :product="getProduct" />
    <modal-spec-move @spec_move="specMove" />
    <package-modal
      :ean="ean"
      @add_package="addPackage"
    />
    <mass-add-product @add_products="createSpec" />
    <add-document-in-contract-modal
      :document="document"
      :selected="selected"
      :all_selected="isAllSelected"
    />
    <div
      class="b-table__content mt-3"
      style="position: relative"
    >
      <incoming-navbar
        style="position: absolute; bottom: 10px; right: 0; left: 0"
        :items="isAllSelected ? document?.paginationSpecifications?.total : selected?.length ?? 0"
        :clear_selected="clearSelected"
        :operation="document?.operation?.type"
        @edit_product="edit_product"
        @remove_items="removeSpecs"
        @print_price_tags="printPriceTags"
        @open_add_product_in_contract_modal="openAddProductInContractModal"
      />
      <div class="table-filter">
        <b-form-input
          type="text"
          class="filter-search"
          placeholder="Поиск"
          :is-keyup="true"
          @input="search"
        />
        <b-button
          class="btn-more ml-3"
          @click="openSortModal"
        >
          <img
            src="/img/icons/settings.svg"
            alt=""
          />
        </b-button>
      </div>
      <div class="table-inner">
        <b-table-simple
          ref="incoming_table"
          hover
          class="table-docs table-fixed"
          fixed
          sticky-header
          no-border-collapse
          table-class="incoming-table"
        >
          <b-thead>
            <b-tr>
              <b-th
                v-for="(td, index) in formatFields.filter((el) => el.checked)"
                :key="index"
                :class="td.class + ' field-column unselectable text-center'"
                :style="{
                  'min-width': td.width + 'px',
                  width: td.width + 'px',
                  ...getIcons(td.key)
                }"
                @click="sortChange(td.key)"
              >
                {{ td.label }}
                <e-checkbox
                  v-if="td.key === 'id'"
                  :checked="isAllSelected"
                  @click="changeSelectAll"
                />
                <div
                  class="resize-field"
                  @mousedown="(el) => mouseDown(el, td)"
                >
                  &nbsp;
                </div>
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody class="incoming-table-body">
            <template v-if="busy">
              <b-tr>
                <td :colspan="fields.length">
                  <div class="text-center">
                    <b-spinner
                      variant="primary"
                      label="Загрузка..."
                    />
                    <div class="mt-2"><strong>Загрузка...</strong></div>
                  </div>
                </td>
              </b-tr>
            </template>
            <template v-else>
              <b-tr
                v-for="tr in items"
                :key="tr.id"
              >
                <template v-if="tr.id !== 'loading'">
                  <b-td
                    v-for="(field, index) of formatFields.filter((el) => el.checked)"
                    :key="index"
                    style="overflow: hidden"
                  >
                    <template v-if="field.key === 'id'">
                      <b-form-checkbox
                        :checked="selected?.some((el) => el === tr?.id)"
                        position="center"
                        @change="(val) => addSelected(val, tr?.id)"
                      />
                    </template>

                    <template v-if="field.key === 'order'">
                      {{ tr.order }}
                    </template>

                    <template v-if="field.key === 'product'">
                      <product-spec-info :product="tr.product" />
                    </template>

                    <template v-if="field.key === 'delta'">
                      <table-row-input
                        :ref="tr.id + 'delta'"
                        :value="tr.delta"
                        :fixed="document.fixed"
                        :input_id="tr.id + 'delta'"
                        :digits="3"
                        @handle-input="(val) => setDelta(val, tr, true)"
                        @input="(val) => setDelta(val, tr)"
                        @on-focus="centeredScroll"
                      />
                    </template>

                    <template v-if="field.key === 'measurement'">
                      <span v-if="tr.packages_list?.length < 1">{{ tr.product.measurement.name || '–' }}</span>
                      <span v-else>
                        <table-select-input
                          :product="tr?.product"
                          :tr="tr"
                          :option_list="tr.packages_list"
                          :value="tr?.packages?.id"
                          :fixed="document.fixed"
                          :input_id="tr?.id + 'mes'"
                          add_button
                          @handle-input="(val) => setMes(val, tr)"
                          @on-focus="centeredScroll"
                          @click-bottom="clickAddMess"
                        />
                      </span>
                    </template>

                    <template v-if="field.key === 'price'">
                      <table-row-input
                        :value="tr.price"
                        :fixed="document.fixed"
                        symbol="₽"
                        :input_id="tr.id + 'price'"
                        @handle-input="(val) => setPrice(val, tr, true)"
                        @input="(val) => setPrice(val, tr)"
                        @on-focus="centeredScroll"
                      />
                    </template>

                    <template v-if="field.key === 'sum'">
                      <table-row-input
                        :value="tr.sum"
                        :fixed="document.fixed"
                        symbol="₽"
                        :input_id="tr.id + 'sum'"
                        @handle-input="(val) => setSum(val, tr, true)"
                        @input="(val) => setSum(val, tr)"
                        @on-focus="centeredScroll"
                      />
                    </template>

                    <template v-if="field.key === 'markup'">
                      <table-row-input
                        :value="tr.markup"
                        :fixed="document.fixed"
                        symbol="%"
                        :input_id="tr.id + 'markup'"
                        @handle-input="(val) => setMarkup(val, tr, true)"
                        @input="(val) => setMarkup(val, tr)"
                        @on-focus="centeredScroll"
                      />
                    </template>

                    <template v-if="field.key === 'retailPrice'">
                      <table-row-input
                        :value="tr.retailPrice"
                        :fixed="document.fixed"
                        symbol="₽"
                        :input_id="tr.id + 'retailPrice'"
                        @handle-input="(val) => setRetailPrice(val, tr, true)"
                        @input="(val) => setRetailPrice(val, tr)"
                        @on-focus="centeredScroll"
                      />
                    </template>

                    <template v-if="field.key === 'currentPrice'">
                      {{ formatPrice(tr.product?.retailPrice ?? 0) }} ₽
                    </template>

                    <template v-if="field.key === 'nds'">
                      <table-select-input
                        :option_list="taxRateList"
                        :value="tr?.nds?.id"
                        :fixed="document.fixed"
                        symbol="₽"
                        :input_id="tr.id + 'nds'"
                        @handle-input="(val) => setNds(val, tr)"
                        @on-focus="centeredScroll"
                      />
                    </template>

                    <template v-if="field.key === 'taxRateSum'"> {{ tr.taxRateSum }} ₽ </template>

                    <template v-if="field.key === 'remainders'">
                      {{ getRemainder(tr.product?.remainders) }}
                    </template>

                    <template v-if="field.key === 'cost'">
                      {{ +tr.cost?.toFixed(2) || 0 }}
                    </template>
                    <template v-if="field.key === 'production_date'">
                      <b-input
                        :value="formatProdDate(tr.production_date)"
                        class="date-input"
                        :disabled="document.fixed"
                        type="date"
                        @change="(value) => setProductionDate(value, tr)"
                      />
                    </template>
                  </b-td>
                </template>
                <template v-else>
                  <b-td
                    v-for="(field, index) of formatFields.filter((el) => el.checked)"
                    :key="index"
                    style="overflow: hidden"
                  >
                    <div
                      style="height: 45px"
                      class="skeleton-box"
                    >
                      <vue-skeleton-loader
                        class="skeleton"
                        type="rect"
                        :height="15"
                        animation="fade"
                        rounded
                        radius="2"
                      />
                    </div>
                  </b-td>
                </template>
              </b-tr>
            </template>
            <b-tr v-if="items?.length === 0 && !busy">
              <td :colspan="formatFields.length">
                <p class="center mt-3">Нет товаров</p>
              </td>
            </b-tr>
            <add-incoming-spec
              v-if="handler_product"
              :spec="specToCreate"
              @add_product="createSpec"
              @remove_prod="removeProduct"
              @on-focus="centeredScroll"
            />

            <b-tr v-if="!document.fixed && !scrollExist">
              <b-td :colspan="fields.filter((el) => el.checked).length">
                <div class="d-flex">
                  <product-search
                    v-if="!document.fixed && !scrollExist"
                    :supplier="document?.supplier?.id ?? null"
                    :document_head="document.id"
                    :document="document"
                    :document_type="'incoming'"
                    :show_add_button="!busy"
                    @select_product="select_product"
                  />
                </div>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </div>

      <div class="table-footer incoming-table-footer align-middle d-flex align-items-center p-3">
        <div class="mr-auto d-flex">
          <product-search
            v-if="!document.fixed && scrollExist"
            :supplier="document?.supplier?.id ?? null"
            style="width: 250px"
            class="ml-2"
            :document_head="document.id"
            :document="document"
            @select_product="select_product"
          />
          <e-button
            v-if="!document.fixed && scrollExist"
            class="ml-2"
          >
            <img
              src="/img/icons/mass-add.svg"
              alt="addProducts"
              @click="$bvModal.show('mass_add_product')"
            />
          </e-button>
        </div>

        <div class="footer-data">
          Итого:
          {{ document.paginationSpecifications?.total }}
        </div>

        <div class="footer-data">
          Количество:
          <span>
            {{ +document.paginationSpecifications?.factTotal?.toFixed(2) || 0 }}
          </span>
        </div>

        <div class="footer-data">
          Сумма:
          <span>
            {{ formatPrice(document.paginationSpecifications.sumTotal) }}
            ₽
          </span>
        </div>

        <div class="footer-data">
          Сумма в рознице:
          <span>
            {{ formatPrice(document.retail_sum) }}
            ₽
          </span>
        </div>

        <div class="footer-data">
          Наценка:
          <span>
            {{ +(document.paginationSpecifications?.avgMarkup * 100).toFixed(2) || 0 }}
            %
          </span>
        </div>

        <div class="footer-data">
          Сумма НДС:
          {{ formatPrice(document.paginationSpecifications?.taxRateSum) }}
          ₽
        </div>
      </div>
    </div>
    <sort-table-modal
      :fields="copyFields"
      :default_fields="default_fields"
      @change="changeFields"
      @set-default="setDefault"
    />
  </div>
</template>

<script>
  import AddDocumentInContractModal from './modal/AddDocumentInContractModal.vue'
  import ModalPriceUp from '@/views/operational-processes/components/overhead/ModalPriceUp'
  import IncomingNavbar from '@/views/operational-processes/components/incoming/IncomingNavbar'
  // import { bus } from '@/main'
  import { mapActions, mapGetters } from 'vuex'
  import ModalCostsAdd from '@/views/operational-processes/components/overhead/ModalCostsAdd'
  import TableRowInput from '@/views/operational-processes/components/TableRowInput'
  import { TaxRateModel } from '@/models/tax-rate.model'
  import TableSelectInput from '@/views/operational-processes/components/TableSelectInput'
  import SortTableModal from '@/views/operational-processes/components/SortTableModal'
  import { IncomingSpecificationModel } from '@/models/incoming-spec.model'
  import AddIncomingSpec from '@/views/operational-processes/components/incoming/AddIncomingSpec'
  import { IncomingHeadModel } from '@/views/operational-processes/models/incoming-head.model'
  import ProductSearch from '@/views/operational-processes/components/ProductSearch'
  import ProductMovementModal from '@/views/products/modal/ProductMovementModal.vue'
  import ProductSpecInfo from '@/views/operational-processes/components/ProductSpecInfo.vue'
  import ModalSpecMove from '@/views/operational-processes/components/overhead/ModalSpecMove.vue'
  import MassAddProduct from '@/views/operational-processes/components/incoming/modal/MassAddProduct'
  import PackageModal from '@/views/products/modal/PackageModal'
  import VueSkeletonLoader from 'skeleton-loader-vue'
  export default {
    components: {
      AddDocumentInContractModal,
      VueSkeletonLoader,
      PackageModal,
      MassAddProduct,
      ModalSpecMove,
      ProductSpecInfo,
      ProductMovementModal,
      ProductSearch,
      AddIncomingSpec,
      SortTableModal,
      TableSelectInput,
      TableRowInput,
      ModalPriceUp,
      ModalCostsAdd,
      IncomingNavbar
    },
    props: {
      document: {
        type: IncomingHeadModel
      },
      busy: {
        type: Boolean
      },
      items: {
        type: [IncomingSpecificationModel]
      }
    },
    data() {
      return {
        tr: {},
        ean: 'piece',
        product_id: null,
        input_keys: ['delta', 'price', 'markup', 'retailPrice', 'sum'],
        isUpdateFilters: false,
        searchString: '',
        search_result: false,
        fields: [],
        printProducts: [],
        specToCreate: new IncomingSpecificationModel(),
        handler_product: false,
        default_fields: [
          {
            key: 'id',
            label: '',
            thStyle: 'min-width: 45px',
            checked: true,
            width: 45
          },
          {
            key: 'order',
            label: '№',
            checked: true,
            sortable: true,
            width: 54
          },
          {
            key: 'product',
            label: 'Наименование товара',
            thStyle: 'width: 250px ;min-width: 250px;',
            checked: true,
            sortable: true,
            width: 250
          },
          {
            key: 'delta',
            label: 'Общее количество',
            thStyle: 'min-width: 100px;width: 100px',
            checked: true,
            sortable: true,
            width: 120
          },
          {
            key: 'measurement',
            label: 'Единица измерения',
            thStyle: 'min-width: 54px;width: 54px; overflow: visible',
            class: 'measurement',
            checked: true,
            width: 120,
            formatter: (value, key, item) => item.product?.measurement?.name ?? '-'
          },
          {
            key: 'price',
            label: 'Закупочная цена',
            thStyle: 'min-width: 150px;width: 150px',
            checked: true,
            sortable: true,
            width: 150
          },
          {
            key: 'sum',
            label: 'Сумма по позиции',
            thStyle: 'min-width: 150px;width: 150px',
            checked: true,
            sortable: true,
            width: 150
          },
          {
            key: 'markup',
            label: this.document.operation.type === 'commission' ? 'Комиссия' : 'Наценка',
            thStyle: 'min-width: 120px;width: 120px',
            checked: true,
            sortable: true,
            width: 120
          },
          {
            key: 'retailPrice',
            label: 'Розница новая',
            thStyle: 'min-width: 120px;width: 120px',
            checked: true,
            sortable: true,
            width: 150
          },
          {
            key: 'currentPrice',
            label: 'Розница текущая',
            thStyle: 'min-width: 120px;width: 120px',
            width: 140,
            checked: true,
            formatter: (value, key, item) => item.product?.retailPrice ?? 0
          },
          {
            key: 'nds',
            label: 'Ставка НДС',
            thStyle: 'min-width: 150px;width: 150px',
            width: 150,
            checked: true,
            sortable: true
          },
          {
            key: 'taxRateSum',
            label: 'Сумма НДС',
            checked: true,
            thStyle: 'min-width: 120px;width: 120px',
            width: 120
          },
          {
            key: 'remainders',
            label: 'Остаток',
            formatter: (value, key, item) => this.getRemainder(item.product.remainders),
            thStyle: 'min-width: 100px;width: 100px',
            checked: true,
            sortable: true,
            width: 100
          },
          {
            key: 'cost',
            label: 'Издержки',
            thStyle: 'min-width: 100px;width: 100px',
            checked: true,
            sortable: true,
            width: 100
          },
          {
            key: 'production_date',
            label: 'Дата производства',
            thStyle: 'min-width: 150px;width: 150px',
            checked: true,
            sortable: true,
            width: 150
          }
        ],
        selected: [],
        isAllSelected: false,
        taxRateList: [],
        thElm: 0,
        startOffset: 0,
        field: null,
        scrollExist: true,
        isModalOpened: false,
        timer: null,
        sort: {
          sortBy: '',
          sortDesc: null
        }
      }
    },

    apollo: {
      TaxRates: {
        query: require('../../gql/getTaxRates.gql'),
        result({ data }) {
          this.taxRateList = data?.TaxRates?.map((el) => new TaxRateModel(el)) ?? []
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      }
    },

    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch',
        getQuantityProducts: 'products/getQuantityProducts'
      }),
      copyFields() {
        return JSON.parse(JSON.stringify(this.fields))
      },
      getProduct() {
        return this.items?.find((el) => el.id === this.selected[0])?.product
      },
      formatFields() {
        return this.default_fields
          .map((el, index) => {
            const fieldIndex = this.fields.findIndex((f) => f.key === el.key)
            const field = this.fields[fieldIndex]
            let name = el.label
            if (field?.key === 'markup') name = this.document.operation.type === 'commission' ? 'Комиссия' : 'Наценка'
            return { ...el, ...field, label: name, index: fieldIndex === -1 ? index : fieldIndex }
          })
          .sort((a, b) => (a.index > b.index ? 1 : -1))
      }
    },

    watch: {
      items(newItems) {
        let currentSelectedAfterPagination = newItems.filter((item) => {
          return this.selected.some((el) => item.id === el.id)
        })
        if (this.items.length === 0) {
          this.isAllSelected = false
        } else if (currentSelectedAfterPagination.length === this.items.length) {
          this.isAllSelected = true
        } else {
          this.isAllSelected = false
        }
      }
    },

    mounted() {
      this.$nextTick(() => this.tableResize())
      const srtFields = localStorage.getItem('incoming_fields')
      this.fields = srtFields ? JSON.parse(srtFields) : JSON.parse(JSON.stringify(this.default_fields))
      document.addEventListener(
        'click',
        (e) => {
          this.listenerClick(e)
        },
        false
      )
      this.loadFields()
      this.$nextTick(() => this.scrollListener())
    },
    beforeDestroy() {},
    destroyed() {
      this.isUpdatedStorage = false
      document.removeEventListener('click', () => console.info('removeListener'))
    },

    methods: {
      ...mapActions({
        setSpecByParam: 'operprocess/setSpecByParam'
      }),
      clickAddMess({ product, tr }) {
        this.product_id = product.id
        this.tr = tr
        this.ean = product?.measurement?.type
        this.$bvModal.show('package-modal')
      },

      changeSelectAll() {
        this.isAllSelected = !this.isAllSelected
        if (!this.isAllSelected) {
          this.items.forEach((obj) => (this.selected = this.selected.filter((item) => obj.id !== item)))
          return
        }

        let arrayCurrenSelected = []
        this.items.forEach((item) => {
          const id = item.id
          if (!this.selected.some((obj) => obj === id)) {
            arrayCurrenSelected.push(item.id)
          }
        })
        this.selected = [...this.selected, ...arrayCurrenSelected]
      },

      addSelected(val, idItem) {
        if (val) this.selected = [...this.selected, idItem]
        else {
          this.selected = this.selected?.filter((el) => el !== idItem)
        }
        let currentSeleted = this.items?.filter((item) => {
          return this.selected.some((el) => item.id === el)
        })
        if (currentSeleted.length < this.items.length) this.isAllSelected = false
        if (currentSeleted.length === this.items.length) this.isAllSelected = true
      },


      async addPackage(el) {
        try {
          let { data } = await this.$apollo.mutate({
            mutation: require('../../../products/gql/CreatePackage.gql'),
            variables: {
              product: this.product_id,
              barcode: el?.barcode ?? null,
              value: +el.value,
              length: +el.length,
              width: +el.width,
              height: +el.height
            }
          })
          if (data?.CreatePackage) {
            this.$bvModal.hide('package-modal')
            const find = this.items.find((obj) => obj.id === this.tr.id)
            find.setPackage({
              ...el,
              id: data?.CreatePackage?.id
            })
            this.updateSpec(find)
            this.$noty.show('Упаковка создана')
          }
          this.product_id = null
          this.ean = 'piece'
          this.tr = {}
          setTimeout(async () => await this.$emit('update-data'), 200)
        } catch (e) {
          this.$noty.error('При создании упаковки произошла ошибка')
          this.product_id = null
          this.tr = {}
        }
      },
      openAddProductInContractModal() {
        this.$bvModal.show('add-document-in-contract-modal')
      },
      edit_product() {
        this.$router.push({
          name: 'products.edit',
          params: { name: this.getProduct.name, id: this.getProduct.id }
        })
      },
      fieldSortable(id) {
        return this.default_fields.find((el) => el.key === id)?.sortable
      },
      async specMove(number) {
        await this.$apollo.query({
          query: require('../../gql/IncMoveSpec.graphql'),
          fetchPolicy: 'no-cache',
          notifyOnNetworkStatusChange: true,
          variables: {
            input: {
              head: this.$route.query.id ? this.$route.query.id : this.$route.params.id,
              position: +number,
              spec: this.selected[0]
            }
          }
        })
        this.selected = []
        this.$emit('update-data')
        this.$bvModal.hide('modal-spec-move')
      },
      getIcons(id) {
        if (!this.default_fields.find((el) => el.key === id)?.sortable) return
        let icon = '/img/icons/table/sort_up.svg'
        if (this.sort.sortBy !== id) icon = '/img/icons/table/sort_none.svg'
        else if (!this.sort.sortDesc) icon = '/img/icons/table/sort_down.svg'
        return {
          background: `white url(${icon}) no-repeat calc(100% - 7px) 50% !important`,
          cursor: 'pointer'
        }
      },
      sortChange(key) {
        if (!this.default_fields.find((el) => el.key === key)?.sortable) return
        this.sort.sortBy = key
        this.sort.sortDesc = !this.sort.sortDesc
        this.$emit('sort_change', {
          sortBy: key,
          sortDesc: this.sort.sortDesc
        })
      },
      formatProdDate(dt) {
        let yourDate = new Date(dt)

        return yourDate.toISOString().split('T')[0]
      },
      scrollDown(event) {
        this.$emit('scroll-change', event.target.scrollTop)
        let bottomOfWindow = event.target.scrollTop + 100 + event.target.clientHeight >= event.target.scrollHeight
        if (event.target.scrollTop === 0) {
          this.$emit('scroll-up', bottomOfWindow)
        } else if (bottomOfWindow) {
          this.$emit('scroll-down')
        }
      },
      async loadFields() {
        const { data } = await this.$apollo.query({
          fetchPolicy: 'no-cache',
          query: require('../../gql/ReadUserTable.graphql')
        })
        const table = data?.ReadUserTable?.tables?.find((el) => el.table_name === 'incoming')
        if (table?.fields) this.fields = table.fields
      },
      scrollListener() {
        const resizeObserver = new ResizeObserver(() => {
          const table = document.querySelector('.table-docs')
          this.scrollExist =
            table.offsetHeight <= document.querySelector('.incoming-table')?.clientHeight ||
            (table.offsetHeight <= document.querySelector('.incoming-table')?.clientHeight + 155 && this.scrollExist)
        })

        resizeObserver.observe(document.querySelector('.incoming-table'), {
          childList: true,
          subtree: true
        })
        document.querySelector('.table-docs').addEventListener('scroll', this.scrollDown)
        // window.removeEventListener('scroll', this.handleScroll)
      },
      centeredScroll({ offset }) {
        const clientWidth = document.querySelector('.table-docs').clientWidth
        document.querySelector('.table-docs').scrollTo({ left: offset - clientWidth / 2, behavior: 'smooth' })
      },
      mouseDown(e, field) {
        this.thElm = e.target.parentNode
        this.startOffset = this.thElm.offsetWidth - e.pageX
        this.field = field
      },
      tableResize() {
        document.addEventListener('mousemove', (e) => {
          if (this.thElm && this.startOffset + e.pageX > 0) {
            this.thElm.style.minWidth = this.startOffset + e.pageX + 'px'
            this.thElm.style.width = this.startOffset + e.pageX + 'px'
            this.field.width = this.startOffset + e.pageX
            const field = this.fields.find((el) => el.key === this.field.key)
            field.width = this.field.width
          }
        })

        document.addEventListener('mouseup', () => {
          if (!this.thElm) return
          this.field.thStyle = this.thElm.style.cssText
          this.saveFields()
          this.startOffset = 0
          this.thElm = undefined
        })
      },
      setDefault() {
        this.fields = JSON.parse(JSON.stringify(this.default_fields))
        this.saveFields()
      },
      findNext(id, current) {
        const fields = this.fields.filter((el) => el.checked)
        const index = fields.findIndex((el) => el.key === current)
        const field = fields.slice(index + 1, fields.length).find((el) => this.input_keys.includes(el.key))
        if (field) {
          this.$nextTick(() => document.getElementById(id + field.key)?.click())
        } else {
          setTimeout(() => document.getElementById('productSearchInput').focus(), 700)
        }
      },
      saveFields() {
        localStorage.setItem('incoming_fields', JSON.stringify(this.fields))
        this.$apollo.mutate({
          mutation: require('../../gql/saveFields.graphql'),
          variables: {
            input: {
              table_name: 'incoming',
              fields: this.fields.map((el) => {
                return {
                  key: el.key,
                  checked: el.checked,
                  width: el.width,
                  label: el.label
                }
              })
            }
          }
        })
      },
      openSortModal() {
        this.$bvModal.show('sort-table-modal')
      },
      getRemainder(item) {
        const storage_id = this.document.storage.id

        if (Array.isArray(item)) {
          const num = item.filter((el) => el?.storage?.id === storage_id).reduce((sum, el) => sum + el.value, 0) || 0
          return num % 1 === 0 ? num : num?.toFixed(3)
        }

        return 0
      },

      setDelta(val, item, isEnter) {
        this.setSpecByParam({ spec_id: item.id, key: 'delta', value: val })
        item.setDelta(val)
        if (isEnter) this.findNext(item.id, 'delta')
        this.updateSpec(item)
      },

      setMes(val, item) {
        item.setPackage(val)
        this.setSpecByParam({ spec_id: item.id, key: 'package', value: val })
        this.updateSpec(item)
      },

      setPrice(val, item, isEnter) {
        item.setPrice(val)
        this.setSpecByParam({ spec_id: item.id, key: 'price', value: val })
        if (isEnter) this.findNext(item.id, 'price')
        if (this.document.operation.type !== 'commission')
          if (item.product.markup) {
            item.setMarkup(item.product.markup)
            this.setSpecByParam({ spec_id: item.id, key: 'markup', value: item.product.markup })
          } else if (!item.product.markup && item.product.category?.markup?.value) {
            this.setSpecByParam({
              spec_id: item.id,
              key: 'markup',
              value: item.product.category?.markup.value,
              round_to: item.product.category?.markup?.round_to
            })
            item.setMarkup(item.product.category?.markup?.value, item.product.category?.markup?.round_to)
          }
        this.updateSpec(item)
      },

      setSum(val, item, isEnter) {
        item.setSum(val)
        this.setSpecByParam({ spec_id: item.id, key: 'sum', value: val })
        if (isEnter) this.findNext(item.id, 'sum')

        this.updateSpec(item)
      },

      setMarkup(val, item, isEnter) {
        item.setMarkup(val)
        this.setSpecByParam({ spec_id: item.id, key: 'markup', value: val })
        if (isEnter) this.findNext(item.id, 'markup')
        this.updateSpec(item)
      },

      setRetailPrice(val, item, isEnter) {
        item.setRetailPrice(val)
        this.setSpecByParam({ spec_id: item.id, key: 'retailPrice', value: val })
        if (isEnter) this.findNext(item.id, 'retailPrice')
        if (this.document.operation.type === 'commission')
          if (item.product.markup) {
            item.setMarkup(item.product.markup)
            this.setSpecByParam({ spec_id: item.id, key: 'markup', value: item.product.markup })
          } else if (!item.product.markup && item.product.category?.markup?.value) {
            this.setSpecByParam({
              spec_id: item.id,
              key: 'markup',
              value: item.product.category?.markup.value,
              round_to: item.product.category?.markup?.round_to
            })
            item.setMarkup(item.product.category?.markup?.value, item.product.category?.markup?.round_to)
          }
        this.updateSpec(item)
      },

      setNds(val, item) {
        item.setNds(val)
        this.setSpecByParam({ spec_id: item.id, key: 'nds', value: val })
        this.updateSpec(item)
      },
      setProductionDate(val, item) {
        this.setSpecByParam({ spec_id: item.id, key: 'productionDate', value: val })
        item.setProductionDate(val)
        this.updateSpec(item)
      },

      formatPrice(summ = 0) {
        const val = summ === null ? 0 : summ

        const formatter = new Intl.NumberFormat('ru-RU', {
          style: 'decimal',
          currency: 'RUB',
          maximumFractionDigits: 2,
          minimumFractionDigits: 0
          // These options are needed to round to whole numbers if that's what you want.
          //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
          // maximumFractionDigits: 0 // (causes 2500.99 to be printed as $2,501)
        })

        return formatter.format(val)
      },

      searchProductResult(data) {
        const result = data.ProductSearch?.products ?? []

        if (result.length === 1) {
          this.search_result = false
          this.select_product(result[0])
        }

        return data
      },
      scroll_to(item, show_input = true) {
        const spec = this.items.find((el) => el.id === item.specification.id)
        if (item.found_by_barcode && item.brutto) {
          spec.setDelta(spec.delta + item.brutto)
          this.updateSpec(spec)
          return
        }
        setTimeout(() => {
          if (show_input) this.$refs[item.specification.id + 'delta'][0].showInput()
          this.$nextTick(() => {
            this.$refs[item.specification.id + 'delta'][0].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
              inline: 'end'
            })
          })
        }, 100)
      },

      select_product(item) {
        if (item.specification) {
          if (!this.$refs[item.specification.id + 'delta']) {
            console.log(this.items[this.items.length - 1])
            this.scroll_to({ specification: { id: this.items[this.items.length - 1].id } }, false)
            this.$emit('get-last', () => this.scroll_to(item), item.specification.cursor)
          } else {
            this.scroll_to(item)
          }
          return
        }
        const packageCount = item.package ? item.package[0]?.value : 0
        let delta = 0
        if (item.found_by_barcode && item.specification)
          delta = item.specification?.delta ? (item.specification?.delta || 0) + (packageCount || 1) : packageCount
        if (item.brutto && item.found_by_barcode) delta = item.brutto
        const new_spec = new IncomingSpecificationModel({
          ...item.specification,
          delta: item.specification?.delta ? (item.specification?.delta || 0) + (packageCount || 1) : packageCount,
          product: item,
          document_head: this.document,
          nds: item.taxRate
        })

        new_spec.setDelta(delta)
        if (new_spec.product.purchasePrice) {
          new_spec.setPrice(new_spec.product.purchasePrice)
          if (new_spec.product.markup) new_spec.setMarkup(+new_spec.product.markup)
          else if (!new_spec.product.markup && new_spec.product.retailPrice) {
            new_spec.setRetailPrice(new_spec.product.retailPrice)
          } else if (!new_spec.product.markup && !new_spec.product.retailPrice && new_spec?.product?.category?.markup) {
            new_spec.setMarkup(
              new_spec?.product?.category?.markup?.value,
              new_spec?.product?.category?.markup?.round_to ?? 'none'
            )
          }
        }
        this.createSpec(new_spec.input, item.found_by_barcode)
        // this.handler_product = true
      },
      async createSpec(spec, byBarcode) {
        this.sort.sortBy = null
        const { data } = await this.$apollo.mutate({
          mutation: require(`../../gql/CreateIncomingSpecification.gql`),
          variables: {
            productChange: Array.isArray(spec)
              ? spec.map((obj) => {
                  const quantityProduct = this.getQuantityProducts.find((el) => el.idProduct === obj.id)?.quantity
                  return {
                    id: null,
                    product: obj.id,
                    price: obj.purchasePrice,
                    retailPrice: obj.retailPrice,
                    delta: quantityProduct === '' ? 0 : quantityProduct
                  }
                })
              : [spec],
            document_head: this.document.id
          }
        })
        this.$emit(
          'get-last',
          () => {
            setTimeout(() => {
              document.querySelector('.table-docs').scrollTo({
                top: document.querySelector('.table-docs').scrollHeight,
                left: 0
              })
            }, 500)

            this.$nextTick(() => {
              if (byBarcode) {
                document.getElementById('productSearchInput').focus()
              } else {
                this.$refs[data.CreateIncomingSpecification[0].id + 'delta'][0].showInput()
              }
            })
          },
          data.CreateIncomingSpecification[0].cursor
        )
        this.$noty.show('Добавлено')
        this.removeProduct()
        document.querySelector('.table-docs').scrollTo({ left: 0, behavior: 'smooth' })
      },

      updateSpec(spec) {
        clearTimeout(this.timer)
        this.timer = setTimeout(async () => {
          await this.$apollo.mutate({
            mutation: require(`../../gql/CreateIncomingSpecification.gql`),
            variables: {
              productChange: [spec.input],
              document_head: this.document.id
            }
          })
          this.$emit('update-total')
        }, 200)
      },

      async removeSpecs() {
        const { data } = await this.$apollo.mutate({
          mutation: require('../../gql/RemoveIncomingSpecification.gql'),
          variables: {
            document_head: this.document.id,
            ids: this.selected,
            isAllSelected: this.isAllSelected
          }
        })
        if (data?.RemoveIncomingSpecification?.status) {
          this.isAllSelected = false
          this.selected = []
          this.$emit('update-data')
        }
      },

      removeProduct() {
        this.specToCreate = {}
        this.handler_product = false
      },

      setSupplierError(status = false) {
        this.isSupplierError = status
      },
      counterSeconds() {
        this.time = this.time + 0.1
      },

      resetTimer() {
        this.time = 0
      },

      clearSelected() {
        this.isAllSelected = false
        this.selected = []
      },

      // Action's
      back() {
        this.filter_params = this.filtersData()
        this.$nextTick(() => this.updateDocument())
      },
      listenerClick(e) {
        const target = e.target.classList.value

        if (
          target === 'cursor' ||
          target === 'span-count' ||
          target === 'span-count error' ||
          target === 'edit' ||
          target === 'ivu-select-placeholder' ||
          target === 'ivu-icon ivu-icon-ios-arrow-down ivu-select-arrow' ||
          target === 'form-control'
        )
          return true
      },

      async printPriceTags() {
        let products = []
        const delta = []
        for (const prod of this.selected) {
          const found = this.items.find((el) => el.id === prod)
          if (found) {
            products.push(found.product.id)
            delta.push({ id: found.product.id, delta: found.delta })
          }
        }
        this.$emit('printPrice', {
          document_head: this.document.id,
          branch: this.document.branch.id,
          product: products,
          delta,
          isPrintAll: this.isAllSelected
        })
        // const { data } = await this.$apollo.query({
        //   query: require('../../gql/ReadPriceTagsFromDocument.graphql'),
        //   fetchPolicy: 'no-cache',
        //   variables: {
        //     input: {
        //       document_head: this.document.id,
        //       branch: this.document.branch.id,
        //       product: products,
        //       isPrintAll: this.isAllSelected
        //     }
        //   }
        // })
        // this.printProducts = data?.ReadPriceTagsFromDocument?.map(
        //   (el) => new ProductModel(el.Product)
        // )
        // this.$bvModal.show('modal-print-tags')
      },
      changeFields(val) {
        this.fields = val
        this.saveFields()
      },

      showPriceUp() {
        this.$bvModal.show('modal-price-up')
      },

      async totalUpdateMarkup(priceup) {
        const { data } = await this.$apollo.mutate({
          mutation: require('../../gql/AddMarkup.graphql'),
          variables: {
            input: {
              document_head: this.document.id,
              ids: this.selected,
              isAllSelected: this.isAllSelected,
              markup: +priceup.value,
              roundTo: priceup.priceup
            }
          }
        })
        if (data?.AddMarkup?.status) {
          this.$bvModal.hide('modal-price-up')
          this.isAllSelected = false
          this.selected = []
          this.$emit('update-data')
        }
      },
      async totalUpdateCosts(costsadd) {
        const { data } = await this.$apollo.mutate({
          mutation: require('../../gql/AddCosts.graphql'),
          variables: {
            input: {
              document_head: this.document.id,
              ids: this.selected,
              isAllSelected: this.isAllSelected,
              costs: +costsadd.value,
              addParams: costsadd.addParams
            }
          }
        })
        if (data?.AddCosts?.status) {
          this.$bvModal.hide('modal-costs-add')
          this.isAllSelected = false
          this.selected = []
          this.$emit('update-data')
        }
      },
      search(value) {
        this.$emit('search', value)
      }
    }
  }
</script>
<style scoped lang="scss">
  ::v-deep() {
    table {
      tr {
        td {
          overflow: visible !important;
        }
      }
    }

    table tr th {
      padding-right: 20px !important;
    }

    .table-hover tbody tr:hover th {
      background-color: white !important;
    }

    .table-hover tbody tr:hover th {
      background-color: white !important;
    }

    .table-hover tbody tr:hover th {
      background-color: #f8f8f9 !important;
      transition: 1000ms ease;
    }

    .table-docs thead th {
      border-bottom: 1px solid #e1e1e1;
      border-right: 1px solid #e1e1e1;
      border-top: 1px solid #e1e1e1 !important;
    }

    .table-footer th {
      vertical-align: middle !important;
    }
    .skeleton.animation--fade {
      width: 100% !important;
    }
    .skeleton-box {
      height: 45px;
      display: flex;
      align-items: center;
    }
  }

  .table-hover tbody tr:hover td,
  .table-hover tbody tr:hover td,
  .table-hover tbody tr:hover th {
    & .count {
      background-color: transparent;
      transition: none ease-in;
    }
  }

  .resize-field {
    top: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    cursor: col-resize;
  }

  .field-column {
    position: relative;
  }
  .incoming-table {
    width: auto !important;
    table-layout: fixed;
  }
  table.fixed td {
    overflow: hidden;
  }
  .unselectable {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
    -khtml-user-select: none; /* Konqueror */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  not supported by any browser */
  }

  .incoming-table {
    border: none !important;
  }

  .footer-data {
    color: #313131;
    margin-left: 32px;
  }
  .incoming-table-footer {
    border-top: 1px solid #e1e1e1 !important;
  }

  .date-input {
    border: none;
    cursor: pointer;
    border-bottom: 1px dotted #313131;

    &:focus {
      border: 1px solid #80d1ff;
    }
  }
</style>
