var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "add-document-in-contract-modal",
      "centered": "",
      "hide-footer": "",
      "size": "sm"
    },
    on: {
      "shown": _vm.getContractList
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function () {
        return [_c('div', {
          staticClass: "header-container"
        }, [_c('div', {
          staticClass: "header-container__block-title"
        }, [_c('h5', {
          staticClass: "title"
        }, [_vm._v("Внесение товаров в контракт")])])])];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "modal-body"
  }, [_c('div', {
    staticClass: "modal-content"
  }, [_c('p', {
    staticClass: "modal-content__text"
  }, [_vm._v("Выберите контракт, в который хотите добавить товары")]), _c('div', {
    staticClass: "modal-content__select-block"
  }, [_c('e-select', {
    staticClass: "e-select-el",
    attrs: {
      "options": _vm.contractList,
      "serchable": "",
      "placeholder": "Выберите",
      "select_name": "contract_select"
    },
    scopedSlots: _vm._u([{
      key: "custom_name",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('div', [_c('div', {
          staticClass: "e-select-el__option-name"
        }, [_vm._v(_vm._s(item.name))]), _c('div', {
          staticClass: "select-options-description"
        }, [item !== null && item !== void 0 && item.number ? _c('div', {
          staticClass: "select-options-description-text"
        }, [_vm._v(" № " + _vm._s(item === null || item === void 0 ? void 0 : item.number) + " ")]) : _vm._e(), item !== null && item !== void 0 && item.date ? _c('div', {
          staticClass: "select-options-description-text"
        }, [_vm._v(" от " + _vm._s(_vm.convertDate(item === null || item === void 0 ? void 0 : item.date)) + " ")]) : _vm._e()])])];
      }
    }]),
    model: {
      value: _vm.contract,
      callback: function ($$v) {
        _vm.contract = $$v;
      },
      expression: "contract"
    }
  }), _c('div', {
    staticClass: "modal-content__switch-block"
  }, [_c('b-form-checkbox', {
    attrs: {
      "checked": _vm.isSwitch,
      "name": "check-button",
      "switch": ""
    },
    on: {
      "change": _vm.cangeIsSwitchActive
    }
  }), _c('div', {
    staticClass: "modal-content__label-switch"
  }, [_c('div', [_vm._v("Обновить цены")]), _c('img', {
    attrs: {
      "src": "/img/icons/contract/info.svg",
      "alt": "search"
    }
  })])], 1)], 1), _c('div', {
    staticClass: "modal-content__buttons-block"
  }, [_c('div', {
    staticClass: "b-action-buttons"
  }, [_c('b-button', {
    staticClass: "cursor",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.createContractSpecsFromAnotherSpecs
    }
  }, [_vm._v(" Сохранить ")]), _c('b-button', {
    staticClass: "cursor ml-3",
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v(" Отмена ")])], 1)])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }