<template>
  <b-modal
    id="add-document-in-contract-modal"
    centered
    hide-footer
    size="sm"
    @shown="getContractList"
  >
    <template #modal-header>
      <div class="header-container">
        <div class="header-container__block-title">
          <h5 class="title">Внесение товаров в контракт</h5>
        </div>
      </div>
    </template>

    <div class="modal-body">
      <div class="modal-content">
        <p class="modal-content__text">Выберите контракт, в который хотите добавить товары</p>

        <div class="modal-content__select-block">
          <e-select
            v-model="contract"
            class="e-select-el"
            :options="contractList"
            serchable
            placeholder="Выберите"
            select_name="contract_select"
          >
            <template #custom_name="{ item }">
              <div>
                <div class="e-select-el__option-name">{{ item.name }}</div>
                <div class="select-options-description">
                  <div
                    v-if="item?.number"
                    class="select-options-description-text"
                  >
                    № {{ item?.number }}
                  </div>
                  <div
                    v-if="item?.date"
                    class="select-options-description-text"
                  >
                    от {{ convertDate(item?.date) }}
                  </div>
                </div>
              </div>
            </template>
          </e-select>

          <div class="modal-content__switch-block">
            <b-form-checkbox
              :checked="isSwitch"
              name="check-button"
              switch
              @change="cangeIsSwitchActive"
            />
            <div class="modal-content__label-switch">
              <div>Обновить цены</div>
              <img
                src="/img/icons/contract/info.svg"
                alt="search"
              />
            </div>
          </div>
        </div>

        <div class="modal-content__buttons-block">
          <div class="b-action-buttons">
            <b-button
              variant="primary"
              class="cursor"
              @click="createContractSpecsFromAnotherSpecs"
            >
              Сохранить
            </b-button>
            <b-button
              variant="outline-primary"
              class="cursor ml-3"
              @click="close"
            >
              Отмена
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
  import formatDate from '@/utils/formatDate'
  export default {
    name: 'AddDocumentInContractModal',

    props: {
      document: {
        type: Object,
        default: () => {}
      },
      selected: {
        type: Array,
        default: () => []
      },
      all_selected: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        contract: [],
        contractList: [],
        isSwitch: false
      }
    },

    methods: {
      close() {
        this.$bvModal.hide('add-document-in-contract-modal')
      },

      convertDate(date) {
        if (date) return formatDate(new Date(date), 'numeric')
      },

      cangeIsSwitchActive() {},

      setContractList(list) {
        list?.forEach((el) => {
          const item = { id: el.id, name: el.name, number: el.number, date: el.date }
          this.contractList.push(item)
        })
      },

      async createContractSpecsFromAnotherSpecs() {
        if (!this.contract?.[0]?.id) {
          this.$noty.error('Выберите контракт')
          return
        }

        try {
          await this.$apollo.mutate({
            mutation: require('../../../gql/CreateContractSpecsFromAnotherSpecs.gql'),
            variables: {
              contract_id: this.contract?.[0].id,
              doc_id: this.document?.id,
              specs: this.selected,
              isAllSelected: this.all_selected,
              update_prices: this.isSwitch
            }
          })
          this.$noty.show(`Успешно!`)
        } catch (err) {
          this.$noty.error('Ошибка')
          console.log(err)
        }
      },

      async getContractList() {
        try {
          const { data } = await this.$apollo.mutate({
            mutation: require('../../../gql/ContractHeadList.gql'),
            variables: {
              input: {
                pagination: {
                  skip: 0,
                  take: 20,
                  supplier: this.document?.supplier?.id
                }
              }
            }
          })
          this.setContractList(data?.ContractHeadList?.list)
        } catch (err) {
          this.$noty.show(`Что-то пошло не так!`)
          console.log(err)
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  :deep .modal-header {
    margin-bottom: 0;
    margin-top: 22px;
  }

  :deep .modal-dialog {
    max-width: 420px;
  }

  :deep .modal-body {
    padding-left: 11px !important;
    padding-right: 11px !important;
  }

  :deep .modal-content {
    padding-top: 0px !important;
  }
  .header-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .title {
    color: var(--text-primary-gray-700, #313131);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 18px;
  }

  .modal-content {
    width: 100%;

    &__text {
      color: var(--text-primary-gray-700, #313131);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 0;
    }

    &__buttons-block {
      margin-top: 18px;
      margin-bottom: 22px;
    }

    &__select-block {
      margin-top: 16px;
    }

    &__switch-block {
      display: flex;
      align-items: center;
      margin-top: 16px;
    }

    &__label-switch {
      display: flex;
      align-items: center;
      gap: 7px;
      color: var(--text-primary-gray-700, #313131);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }
  }

  .select-options-description {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .select-options-description-text {
    color: var(--text-secondary-gray-400, #888);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
</style>
