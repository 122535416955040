var render = function render(){
  var _vm$document, _vm$document$operatio, _vm$document2, _vm$document2$paginat, _vm$selected$length, _vm$selected, _vm$document3, _vm$document3$operati, _vm$items, _vm$document$supplier, _vm$document4, _vm$document4$supplie, _vm$document$supplier2, _vm$document5, _vm$document5$supplie, _vm$document$paginati, _vm$document$paginati2, _vm$document$paginati3, _vm$document$paginati4, _vm$document$paginati5;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "b-table__wrapper"
  }, [_c('modal-costs-add', {
    on: {
      "costs_add": _vm.totalUpdateCosts
    }
  }), _c('modal-price-up', {
    attrs: {
      "operation": (_vm$document = _vm.document) === null || _vm$document === void 0 ? void 0 : (_vm$document$operatio = _vm$document.operation) === null || _vm$document$operatio === void 0 ? void 0 : _vm$document$operatio.type
    },
    on: {
      "price_up": _vm.totalUpdateMarkup
    }
  }), _c('product-movement-modal', {
    attrs: {
      "product": _vm.getProduct
    }
  }), _c('modal-spec-move', {
    on: {
      "spec_move": _vm.specMove
    }
  }), _c('package-modal', {
    attrs: {
      "ean": _vm.ean
    },
    on: {
      "add_package": _vm.addPackage
    }
  }), _c('mass-add-product', {
    on: {
      "add_products": _vm.createSpec
    }
  }), _c('add-document-in-contract-modal', {
    attrs: {
      "document": _vm.document,
      "selected": _vm.selected,
      "all_selected": _vm.isAllSelected
    }
  }), _c('div', {
    staticClass: "b-table__content mt-3",
    staticStyle: {
      "position": "relative"
    }
  }, [_c('incoming-navbar', {
    staticStyle: {
      "position": "absolute",
      "bottom": "10px",
      "right": "0",
      "left": "0"
    },
    attrs: {
      "items": _vm.isAllSelected ? (_vm$document2 = _vm.document) === null || _vm$document2 === void 0 ? void 0 : (_vm$document2$paginat = _vm$document2.paginationSpecifications) === null || _vm$document2$paginat === void 0 ? void 0 : _vm$document2$paginat.total : (_vm$selected$length = (_vm$selected = _vm.selected) === null || _vm$selected === void 0 ? void 0 : _vm$selected.length) !== null && _vm$selected$length !== void 0 ? _vm$selected$length : 0,
      "clear_selected": _vm.clearSelected,
      "operation": (_vm$document3 = _vm.document) === null || _vm$document3 === void 0 ? void 0 : (_vm$document3$operati = _vm$document3.operation) === null || _vm$document3$operati === void 0 ? void 0 : _vm$document3$operati.type
    },
    on: {
      "edit_product": _vm.edit_product,
      "remove_items": _vm.removeSpecs,
      "print_price_tags": _vm.printPriceTags,
      "open_add_product_in_contract_modal": _vm.openAddProductInContractModal
    }
  }), _c('div', {
    staticClass: "table-filter"
  }, [_c('b-form-input', {
    staticClass: "filter-search",
    attrs: {
      "type": "text",
      "placeholder": "Поиск",
      "is-keyup": true
    },
    on: {
      "input": _vm.search
    }
  }), _c('b-button', {
    staticClass: "btn-more ml-3",
    on: {
      "click": _vm.openSortModal
    }
  }, [_c('img', {
    attrs: {
      "src": "/img/icons/settings.svg",
      "alt": ""
    }
  })])], 1), _c('div', {
    staticClass: "table-inner"
  }, [_c('b-table-simple', {
    ref: "incoming_table",
    staticClass: "table-docs table-fixed",
    attrs: {
      "hover": "",
      "fixed": "",
      "sticky-header": "",
      "no-border-collapse": "",
      "table-class": "incoming-table"
    }
  }, [_c('b-thead', [_c('b-tr', _vm._l(_vm.formatFields.filter(function (el) {
    return el.checked;
  }), function (td, index) {
    return _c('b-th', {
      key: index,
      class: td.class + ' field-column unselectable text-center',
      style: Object.assign({
        'min-width': td.width + 'px',
        width: td.width + 'px'
      }, _vm.getIcons(td.key)),
      on: {
        "click": function ($event) {
          return _vm.sortChange(td.key);
        }
      }
    }, [_vm._v(" " + _vm._s(td.label) + " "), td.key === 'id' ? _c('e-checkbox', {
      attrs: {
        "checked": _vm.isAllSelected
      },
      on: {
        "click": _vm.changeSelectAll
      }
    }) : _vm._e(), _c('div', {
      staticClass: "resize-field",
      on: {
        "mousedown": function (el) {
          return _vm.mouseDown(el, td);
        }
      }
    }, [_vm._v("   ")])], 1);
  }), 1)], 1), _c('b-tbody', {
    staticClass: "incoming-table-body"
  }, [_vm.busy ? [_c('b-tr', [_c('td', {
    attrs: {
      "colspan": _vm.fields.length
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('b-spinner', {
    attrs: {
      "variant": "primary",
      "label": "Загрузка..."
    }
  }), _c('div', {
    staticClass: "mt-2"
  }, [_c('strong', [_vm._v("Загрузка...")])])], 1)])])] : _vm._l(_vm.items, function (tr) {
    return _c('b-tr', {
      key: tr.id
    }, [tr.id !== 'loading' ? _vm._l(_vm.formatFields.filter(function (el) {
      return el.checked;
    }), function (field, index) {
      var _vm$selected2, _tr$packages_list, _tr$packages, _tr$product$retailPri, _tr$product, _tr$nds, _tr$product2, _tr$cost;

      return _c('b-td', {
        key: index,
        staticStyle: {
          "overflow": "hidden"
        }
      }, [field.key === 'id' ? [_c('b-form-checkbox', {
        attrs: {
          "checked": (_vm$selected2 = _vm.selected) === null || _vm$selected2 === void 0 ? void 0 : _vm$selected2.some(function (el) {
            return el === (tr === null || tr === void 0 ? void 0 : tr.id);
          }),
          "position": "center"
        },
        on: {
          "change": function (val) {
            return _vm.addSelected(val, tr === null || tr === void 0 ? void 0 : tr.id);
          }
        }
      })] : _vm._e(), field.key === 'order' ? [_vm._v(" " + _vm._s(tr.order) + " ")] : _vm._e(), field.key === 'product' ? [_c('product-spec-info', {
        attrs: {
          "product": tr.product
        }
      })] : _vm._e(), field.key === 'delta' ? [_c('table-row-input', {
        ref: tr.id + 'delta',
        refInFor: true,
        attrs: {
          "value": tr.delta,
          "fixed": _vm.document.fixed,
          "input_id": tr.id + 'delta',
          "digits": 3
        },
        on: {
          "handle-input": function (val) {
            return _vm.setDelta(val, tr, true);
          },
          "input": function (val) {
            return _vm.setDelta(val, tr);
          },
          "on-focus": _vm.centeredScroll
        }
      })] : _vm._e(), field.key === 'measurement' ? [((_tr$packages_list = tr.packages_list) === null || _tr$packages_list === void 0 ? void 0 : _tr$packages_list.length) < 1 ? _c('span', [_vm._v(_vm._s(tr.product.measurement.name || '–'))]) : _c('span', [_c('table-select-input', {
        attrs: {
          "product": tr === null || tr === void 0 ? void 0 : tr.product,
          "tr": tr,
          "option_list": tr.packages_list,
          "value": tr === null || tr === void 0 ? void 0 : (_tr$packages = tr.packages) === null || _tr$packages === void 0 ? void 0 : _tr$packages.id,
          "fixed": _vm.document.fixed,
          "input_id": (tr === null || tr === void 0 ? void 0 : tr.id) + 'mes',
          "add_button": ""
        },
        on: {
          "handle-input": function (val) {
            return _vm.setMes(val, tr);
          },
          "on-focus": _vm.centeredScroll,
          "click-bottom": _vm.clickAddMess
        }
      })], 1)] : _vm._e(), field.key === 'price' ? [_c('table-row-input', {
        attrs: {
          "value": tr.price,
          "fixed": _vm.document.fixed,
          "symbol": "₽",
          "input_id": tr.id + 'price'
        },
        on: {
          "handle-input": function (val) {
            return _vm.setPrice(val, tr, true);
          },
          "input": function (val) {
            return _vm.setPrice(val, tr);
          },
          "on-focus": _vm.centeredScroll
        }
      })] : _vm._e(), field.key === 'sum' ? [_c('table-row-input', {
        attrs: {
          "value": tr.sum,
          "fixed": _vm.document.fixed,
          "symbol": "₽",
          "input_id": tr.id + 'sum'
        },
        on: {
          "handle-input": function (val) {
            return _vm.setSum(val, tr, true);
          },
          "input": function (val) {
            return _vm.setSum(val, tr);
          },
          "on-focus": _vm.centeredScroll
        }
      })] : _vm._e(), field.key === 'markup' ? [_c('table-row-input', {
        attrs: {
          "value": tr.markup,
          "fixed": _vm.document.fixed,
          "symbol": "%",
          "input_id": tr.id + 'markup'
        },
        on: {
          "handle-input": function (val) {
            return _vm.setMarkup(val, tr, true);
          },
          "input": function (val) {
            return _vm.setMarkup(val, tr);
          },
          "on-focus": _vm.centeredScroll
        }
      })] : _vm._e(), field.key === 'retailPrice' ? [_c('table-row-input', {
        attrs: {
          "value": tr.retailPrice,
          "fixed": _vm.document.fixed,
          "symbol": "₽",
          "input_id": tr.id + 'retailPrice'
        },
        on: {
          "handle-input": function (val) {
            return _vm.setRetailPrice(val, tr, true);
          },
          "input": function (val) {
            return _vm.setRetailPrice(val, tr);
          },
          "on-focus": _vm.centeredScroll
        }
      })] : _vm._e(), field.key === 'currentPrice' ? [_vm._v(" " + _vm._s(_vm.formatPrice((_tr$product$retailPri = (_tr$product = tr.product) === null || _tr$product === void 0 ? void 0 : _tr$product.retailPrice) !== null && _tr$product$retailPri !== void 0 ? _tr$product$retailPri : 0)) + " ₽ ")] : _vm._e(), field.key === 'nds' ? [_c('table-select-input', {
        attrs: {
          "option_list": _vm.taxRateList,
          "value": tr === null || tr === void 0 ? void 0 : (_tr$nds = tr.nds) === null || _tr$nds === void 0 ? void 0 : _tr$nds.id,
          "fixed": _vm.document.fixed,
          "symbol": "₽",
          "input_id": tr.id + 'nds'
        },
        on: {
          "handle-input": function (val) {
            return _vm.setNds(val, tr);
          },
          "on-focus": _vm.centeredScroll
        }
      })] : _vm._e(), field.key === 'taxRateSum' ? [_vm._v(" " + _vm._s(tr.taxRateSum) + " ₽ ")] : _vm._e(), field.key === 'remainders' ? [_vm._v(" " + _vm._s(_vm.getRemainder((_tr$product2 = tr.product) === null || _tr$product2 === void 0 ? void 0 : _tr$product2.remainders)) + " ")] : _vm._e(), field.key === 'cost' ? [_vm._v(" " + _vm._s(+((_tr$cost = tr.cost) === null || _tr$cost === void 0 ? void 0 : _tr$cost.toFixed(2)) || 0) + " ")] : _vm._e(), field.key === 'production_date' ? [_c('b-input', {
        staticClass: "date-input",
        attrs: {
          "value": _vm.formatProdDate(tr.production_date),
          "disabled": _vm.document.fixed,
          "type": "date"
        },
        on: {
          "change": function (value) {
            return _vm.setProductionDate(value, tr);
          }
        }
      })] : _vm._e()], 2);
    }) : _vm._l(_vm.formatFields.filter(function (el) {
      return el.checked;
    }), function (field, index) {
      return _c('b-td', {
        key: index,
        staticStyle: {
          "overflow": "hidden"
        }
      }, [_c('div', {
        staticClass: "skeleton-box",
        staticStyle: {
          "height": "45px"
        }
      }, [_c('vue-skeleton-loader', {
        staticClass: "skeleton",
        attrs: {
          "type": "rect",
          "height": 15,
          "animation": "fade",
          "rounded": "",
          "radius": "2"
        }
      })], 1)]);
    })], 2);
  }), ((_vm$items = _vm.items) === null || _vm$items === void 0 ? void 0 : _vm$items.length) === 0 && !_vm.busy ? _c('b-tr', [_c('td', {
    attrs: {
      "colspan": _vm.formatFields.length
    }
  }, [_c('p', {
    staticClass: "center mt-3"
  }, [_vm._v("Нет товаров")])])]) : _vm._e(), _vm.handler_product ? _c('add-incoming-spec', {
    attrs: {
      "spec": _vm.specToCreate
    },
    on: {
      "add_product": _vm.createSpec,
      "remove_prod": _vm.removeProduct,
      "on-focus": _vm.centeredScroll
    }
  }) : _vm._e(), !_vm.document.fixed && !_vm.scrollExist ? _c('b-tr', [_c('b-td', {
    attrs: {
      "colspan": _vm.fields.filter(function (el) {
        return el.checked;
      }).length
    }
  }, [_c('div', {
    staticClass: "d-flex"
  }, [!_vm.document.fixed && !_vm.scrollExist ? _c('product-search', {
    attrs: {
      "supplier": (_vm$document$supplier = (_vm$document4 = _vm.document) === null || _vm$document4 === void 0 ? void 0 : (_vm$document4$supplie = _vm$document4.supplier) === null || _vm$document4$supplie === void 0 ? void 0 : _vm$document4$supplie.id) !== null && _vm$document$supplier !== void 0 ? _vm$document$supplier : null,
      "document_head": _vm.document.id,
      "document": _vm.document,
      "document_type": 'incoming',
      "show_add_button": !_vm.busy
    },
    on: {
      "select_product": _vm.select_product
    }
  }) : _vm._e()], 1)])], 1) : _vm._e()], 2)], 1)], 1), _c('div', {
    staticClass: "table-footer incoming-table-footer align-middle d-flex align-items-center p-3"
  }, [_c('div', {
    staticClass: "mr-auto d-flex"
  }, [!_vm.document.fixed && _vm.scrollExist ? _c('product-search', {
    staticClass: "ml-2",
    staticStyle: {
      "width": "250px"
    },
    attrs: {
      "supplier": (_vm$document$supplier2 = (_vm$document5 = _vm.document) === null || _vm$document5 === void 0 ? void 0 : (_vm$document5$supplie = _vm$document5.supplier) === null || _vm$document5$supplie === void 0 ? void 0 : _vm$document5$supplie.id) !== null && _vm$document$supplier2 !== void 0 ? _vm$document$supplier2 : null,
      "document_head": _vm.document.id,
      "document": _vm.document
    },
    on: {
      "select_product": _vm.select_product
    }
  }) : _vm._e(), !_vm.document.fixed && _vm.scrollExist ? _c('e-button', {
    staticClass: "ml-2"
  }, [_c('img', {
    attrs: {
      "src": "/img/icons/mass-add.svg",
      "alt": "addProducts"
    },
    on: {
      "click": function ($event) {
        return _vm.$bvModal.show('mass_add_product');
      }
    }
  })]) : _vm._e()], 1), _c('div', {
    staticClass: "footer-data"
  }, [_vm._v(" Итого: " + _vm._s((_vm$document$paginati = _vm.document.paginationSpecifications) === null || _vm$document$paginati === void 0 ? void 0 : _vm$document$paginati.total) + " ")]), _c('div', {
    staticClass: "footer-data"
  }, [_vm._v(" Количество: "), _c('span', [_vm._v(" " + _vm._s(+((_vm$document$paginati2 = _vm.document.paginationSpecifications) === null || _vm$document$paginati2 === void 0 ? void 0 : (_vm$document$paginati3 = _vm$document$paginati2.factTotal) === null || _vm$document$paginati3 === void 0 ? void 0 : _vm$document$paginati3.toFixed(2)) || 0) + " ")])]), _c('div', {
    staticClass: "footer-data"
  }, [_vm._v(" Сумма: "), _c('span', [_vm._v(" " + _vm._s(_vm.formatPrice(_vm.document.paginationSpecifications.sumTotal)) + " ₽ ")])]), _c('div', {
    staticClass: "footer-data"
  }, [_vm._v(" Сумма в рознице: "), _c('span', [_vm._v(" " + _vm._s(_vm.formatPrice(_vm.document.retail_sum)) + " ₽ ")])]), _c('div', {
    staticClass: "footer-data"
  }, [_vm._v(" Наценка: "), _c('span', [_vm._v(" " + _vm._s(+(((_vm$document$paginati4 = _vm.document.paginationSpecifications) === null || _vm$document$paginati4 === void 0 ? void 0 : _vm$document$paginati4.avgMarkup) * 100).toFixed(2) || 0) + " % ")])]), _c('div', {
    staticClass: "footer-data"
  }, [_vm._v(" Сумма НДС: " + _vm._s(_vm.formatPrice((_vm$document$paginati5 = _vm.document.paginationSpecifications) === null || _vm$document$paginati5 === void 0 ? void 0 : _vm$document$paginati5.taxRateSum)) + " ₽ ")])])], 1), _c('sort-table-modal', {
    attrs: {
      "fields": _vm.copyFields,
      "default_fields": _vm.default_fields
    },
    on: {
      "change": _vm.changeFields,
      "set-default": _vm.setDefault
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }